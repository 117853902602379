import { ErrorHandler, Injectable } from '@angular/core';

import * as platform from 'platform';

import { AirLogsService } from 'src/app/shared/services/airLogs.service';
import { DataService } from 'src/app/shared/services/data.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(
    private _airLogsService: AirLogsService,
    private _dataService: DataService
  ) {}

  handleError(error: Error) {
    const deviceInfo = {
      name: platform.name,
      version: platform.version,
      layout: platform.layout,
      os: platform.os,
      description: platform.description,
      product: platform.product,
      manufacturer: platform.manufacturer,
    };

    let userName = 'Nothing',
      userSTCId = 'Nothing',
      userId = null,
      roomToken = 'Nothing';

    if (this._dataService.getUserData()) {
      userName = this._dataService.getUserData().username;
      userSTCId = String(this._dataService.getUserData().id);
    }

    const meetingInfo = this._dataService.getMeetingInfo();
    if (meetingInfo) {
      userId = meetingInfo.userId;
      roomToken = meetingInfo.roomToken;
      userName = meetingInfo.userName;
    }

    const metadata = {
      roomToken,
      userId,
      userName,
      userSTCId,
      appVersion: '0.2.3',
      errorTarget: 'Global catcher',
    };

    this._airLogsService
      .sendLog(error.message, error.stack, deviceInfo, metadata)
      .subscribe(() => console.log('Error report sent'));

    console.error('Error from global error handler', error);
  }
}
