<div
  *ngIf="!waitingScreen"
  class="meeting-screen-container"
  [ngClass]="{
    'with-background':
      viewOptions.background ||
      viewOptions.backgroundColor ||
      viewOptions.backgroundImg,
    'fit-screen': sideCollapsed
  }"
  [ngStyle]="{ 'background-color': viewOptions.backgroundColorVal }"
>
  <div *ngIf="viewOptions.backgroundImg" class="meeting-background-img">
    <img [src]="viewOptions.backgroundImgVal" />
  </div>

  <!-- start default meeting screens view (grid view)  and bottom bar view-->
  <div
    *ngIf="
      !sharingScreen &&
      viewOptions.layoutType != 'active' &&
      viewOptions.layoutType != 'leftThumbnail'
    "
    id="meetingScreenContainer"
    class="custom-scroll screens-{{
      meetingMembers
        .slice((config.currentPage - 1) * config.itemsPerPage)
        .slice(0, config.itemsPerPage).length
    }} layout-{{ viewOptions.layoutType }}"
    [ngClass]="{ 'hide-no-video-member': viewOptions.dontShowSuspendedVideos }"
  >
    <ng-container
      *ngFor="
        let media of meetingMembers | paginate : config;
        let f = first;
        let l = last;
        let i = index
      "
    >
      <div
        class="meeting-screen"
        *ngIf="media.src || !viewOptions.dontShowSuspendedVideos"
        [ngClass]="{ 'active-speaker': media.activeSpeaker }"
      >
        <!--  for test
                <img src="assets/images/landing-img.jpg" alt=""> -->
        <!-- video src will be the source of video or sharing screen or viewing screen [muted]="media.muted"-->
        <video
          [id]="media.id"
          [srcObject]="media.src"
          [autoplay]="media.autoplay"
          [muted]="true"
          *ngIf="
            media.src &&
            media.src.getVideoTracks() &&
            media.src.getVideoTracks().hasOwnProperty('0')
          "
        ></video>

        <div
          class="no-media"
          *ngIf="
            !media.src ||
            (media.src && !media.src.getVideoTracks().hasOwnProperty('0'))
          "
        >
          <figure
            class="user-img-container"
            [ngClass]="{ 'is-host': media.host }"
          >
            <img
              [src]="
                media.img &&
                (!media.src ||
                  (media.src &&
                    !media.src.getVideoTracks().hasOwnProperty('0'))) &&
                media.img
              "
              ployfillImg
              [alt]="media.name"
            />
          </figure>
          <h5 *ngIf="meetingMembers.length == 1">
            {{ "meeting-room.invite" | translate }}
          </h5>
        </div>

        <div class="user-name-container" *ngIf="meetingMembers.length > 1">
          <!-- (click)="pinParticipant(i)" -->
          <div class="pin-icon" *ngIf="media?.isPinned!">
            <img src="assets/images/pin.svg" alt="pin" />
          </div>
          <!-- (click)="unpinParticipant(i)" -->

          <div class="user-name">
            <p>{{ media.name | splitName }}</p>

            <!-- <mat-icon *ngIf="!media.audio" class="mic-off-icon">mic_off</mat-icon> -->

            <!-- <mat-icon
              *ngIf="media.audio && !media.activeSpeaker"
              class="mic-on-icon"
              >mic_none</mat-icon
            > -->

            <!-- <mat-icon

              *ngIf="media.audio && media.activeSpeaker"
              class="active-speaker-icon"
              >mic</mat-icon
            > -->

            <img
              *ngIf="!media.audio"
              src="../../../assets/images/icons/mic_off.svg"
              alt="'meeting-room.mute-audio' | translate"
            />

            <img
              *ngIf="media.audio && !media.activeSpeaker"
              src="../../../assets/images/icons/keyboard_voice.svg"
              alt="'meeting-room.mute-audio' | translate"
            />
          </div>
        </div>
      </div>
    </ng-container>
  </div>
  <!-- end default meeting screens view (grid view)  and bottom bar view-->

  <!-- start  meeting screens  active speaker view -->
  <div
    *ngIf="!sharingScreen && viewOptions.layoutType == 'active'"
    id="meetingScreenContainer"
    class="custom-scroll screens-{{
      meetingMembers
        .slice((config.currentPage - 1) * config.itemsPerPage)
        .slice(0, config.itemsPerPage).length
    }} layout-{{ viewOptions.layoutType }}"
    [ngClass]="{ 'hide-no-video-member': viewOptions.dontShowSuspendedVideos }"
  >
    <ng-container
      *ngFor="let media of meetingMembers; let f = first; let l = last"
    >
      <div
        class="meeting-screen"
        *ngIf="media.src || !viewOptions.dontShowSuspendedVideos"
        [ngClass]="{ 'active-speaker': media.activeSpeaker }"
      >
        <!--  for test
                    <img src="assets/images/landing-img.jpg" alt=""> -->

        <!-- video src will be the source of video or sharing screen or viewing screen [muted]="media.muted"-->
        <video
          [id]="media.id"
          [srcObject]="media.src"
          [autoplay]="media.autoplay"
          [muted]="true"
          *ngIf="
            media.src &&
            media.src.getVideoTracks() &&
            media.src.getVideoTracks().hasOwnProperty('0')
          "
        ></video>

        <div class="no-media">
          <figure class="user-profile-outer-container">
            <!-- to fix responsive circled image problem  with css aspect ratio-->
            <div class="profile-img-inner-container">
              <img
                [src]="
                  media.img &&
                  (!media.src ||
                    (media.src &&
                      !media.src.getVideoTracks().hasOwnProperty('0'))) &&
                  media.img
                "
                ployfillImg
                class="img"
                [alt]="media.name"
              />
            </div>
          </figure>
          <p>
            {{ media.name }}

            <mat-icon *ngIf="!media.audio" class="mic-off-icon"
              >mic_off</mat-icon
            >

            <mat-icon
              *ngIf="media.audio && !media.activeSpeaker"
              class="mic-on-icon"
              >mic_none</mat-icon
            >

            <mat-icon
              *ngIf="media.audio && media.activeSpeaker"
              class="active-speaker-icon"
              >mic</mat-icon
            >
          </p>
        </div>
      </div>
    </ng-container>
  </div>
  <!-- end  meeting screens  active speaker view -->

  <!-- start  meeting screens  left side view -->
  <div
    *ngIf="!sharingScreen && viewOptions.layoutType == 'leftThumbnail'"
    id="meetingScreenContainer"
    class="custom-scroll screens-{{
      meetingMembers
        .slice((config.currentPage - 1) * config.itemsPerPage)
        .slice(0, config.itemsPerPage).length
    }} layout-{{ viewOptions.layoutType }}"
    [ngClass]="{ 'hide-no-video-member': viewOptions.dontShowSuspendedVideos }"
  >
    <ng-container
      *ngFor="let media of meetingMembers; let f = first; let l = last"
    >
      <div
        class="meeting-screen"
        *ngIf="media.src || !viewOptions.dontShowSuspendedVideos"
        [ngClass]="{ 'active-speaker': media.audio && media.activeSpeaker }"
      >
        <video
          [id]="media.id"
          [srcObject]="media.src"
          [autoplay]="media.autoplay"
          [muted]="true"
          *ngIf="
            media.src &&
            media.src.getVideoTracks() &&
            media.src.getVideoTracks().hasOwnProperty('0')
          "
        ></video>

        <div class="no-media">
          <figure class="user-profile-outer-container">
            <!-- to fix responsive circled image problem  with css aspect ratio-->
            <div class="profile-img-inner-container">
              <img
                [src]="
                  media.img &&
                  (!media.src ||
                    (media.src &&
                      !media.src.getVideoTracks().hasOwnProperty('0'))) &&
                  media.img
                "
                ployfillImg
                class="img"
                [alt]="media.name"
              />
            </div>
          </figure>
        </div>
      </div>

      <p
        class="caption left-thumbnail-caption"
        [ngClass]="{ 'active-speaker': media.audio && media.activeSpeaker }"
      >
        <mat-icon *ngIf="!media.audio" class="mic-off-icon">mic_off</mat-icon>

        <mat-icon
          *ngIf="media.audio && !media.activeSpeaker"
          class="mic-on-icon"
          >mic_none</mat-icon
        >

        <mat-icon
          *ngIf="media.audio && media.activeSpeaker"
          class="active-speaker-icon"
          >mic</mat-icon
        >

        {{ media.name }}
      </p>
    </ng-container>
  </div>
  <!-- end  meeting screens  left side view -->

  <!-- screen turn to landscpe automaticlly when another participants shares screen -->
  <div
    id="view-screen"
    *ngIf="sharingScreen || viewingScreen.on"
    [ngClass]="{ active: viewingScreen.activePage }"
    class="share-container sharing-screen"
  >
    <!-- Shared screen for host user -->
    <div *ngIf="viewingScreen.isMe" class="host-screen">
      <div class="share-screen-info">
        <p class="subtitle2">
          {{ "meeting-room.you-are-sharing-the-screen" | translate }}
        </p>

        <button
          class="turning-off-btn"
          mat-flat-button
          color="primary"
          (click)="stopSharingScreen()"
        >
          {{ "meeting-room.turning-off" | translate }}
        </button>
      </div>

      <p class="subtitle2 preview-title">
        {{ "meeting-room.preview" | translate }}
      </p>

      <div class="video-container">
        <video
          [id]="viewingScreen.id"
          [srcObject]="viewingScreen.video"
          [autoplay]="viewingScreen.autoplay"
          [muted]="viewingScreen.muted"
        ></video>
      </div>
    </div>

    <!-- Shared screen for guest user -->
    <div *ngIf="!viewingScreen.isMe" class="guest-screen">
      <video
        [id]="viewingScreen.id"
        [srcObject]="viewingScreen.video"
        [autoplay]="viewingScreen.autoplay"
        [muted]="viewingScreen.muted"
      ></video>

      <div class="viewer-top-bar bodytext2">
        <a
          *ngIf="!viewingScreen.fullScreen"
          (click)="fullScreen('view-screen')"
        >
          <mat-icon>fullscreen</mat-icon>
        </a>
        <a *ngIf="viewingScreen.fullScreen" (click)="exitFullSCreen()">
          <mat-icon>fullscreen_exit</mat-icon>
        </a>

        <p *ngIf="lang.currentLang == 'en'">
          {{ viewingScreen.sharingMemeber }}
        </p>
        <p *ngIf="lang.currentLang == 'ar'">
          {{ viewingScreen.sharingMemeberAr }}
        </p>
      </div>
    </div>
  </div>
</div>

<!-- Show this if the meeting has more than maximum number (defualt 9) members -->
<!-- The number of navigation links according to the number of members divided by maximum number -->

<pagination-template
  #p="paginationApi"
  [id]="config.id"
  (pageChange)="config.currentPage = $event"
  [maxSize]="3"
  class="screens-navigation"
  [ngClass]="{
    'bottom-layout-pagination': viewOptions.layoutType == 'bottomThumbnail'
  }"
  (pageBoundsCorrection)="fixCurrentPage($event)"
  *ngIf="
    !sharingScreen && !viewingScreen.on && viewOptions.layoutType != 'active'
  "
>
  <div class="custom-pagination" *ngIf="p.pages.length > 1">
    <div class="pagination-previous" [ngClass]="{ disabled: p.isFirstPage() }">
      <span (click)="p.previous()">
        <!-- *ngIf="!p.isFirstPage()" -->
        <mat-icon>chevron_left</mat-icon>
      </span>
    </div>
    <div class="page-number">
      <div [ngClass]="{ current: p.getCurrent() != p.getLastPage() }">
        {{ p.getCurrent() }}
      </div>
      <div>/</div>
      <div [ngClass]="{ current: p.getCurrent() == p.getLastPage() }">
        {{ p.getLastPage() }}
      </div>
    </div>
    <div class="pagination-next" [ngClass]="{ disabled: p.isLastPage() }">
      <span (click)="p.next()">
        <!-- *ngIf="!p.isLastPage()" -->
        <mat-icon>chevron_right</mat-icon>
      </span>
    </div>
  </div>
</pagination-template>

<!-- Add and remove users buttons for test -->
<!-- <button
  style="position: fixed; bottom: 15%; left: 5%; z-index: 9999"
  (click)="addMemberToMeeting()"
>
  add user
</button>

<button
  style="position: fixed; bottom: 20%; left: 5%; z-index: 9999"
  (click)="removeMemberFromMeeting()"
>
  remove user
</button> -->

<!-- start waiting room screen -->
<div class="waiting-screen-container" *ngIf="waitingScreen">
  <div class="waiting-screen-text" *ngIf="waitingRoomDelivered">
    <div class="waitting-screen-img">
      <img
        src="assets/images/waiting.png"
        alt="waiting room"
        *ngIf="meetingInfo && !waitingScreenData.started"
      />
      <img
        src="assets/images/wait-meeting.png"
        alt="waiting room"
        *ngIf="meetingInfo && waitingScreenData.started"
      />
      <app-spinner
        class="spinner"
        *ngIf="meetingInfo && waitingScreenData.started"
      ></app-spinner>
    </div>
    <label class="headline3" *ngIf="!waitingScreenData.started">{{
      "meeting-room.meeting-not-start-yet" | translate
    }}</label>
    <label class="headline3" *ngIf="waitingScreenData.started">{{
      "meeting-room.please-wait" | translate
    }}</label>

    <p *ngIf="!waitingScreenData.started" class="subtitle2">
      {{ "meeting-room.personal-room-doesnot-start" | translate }}
    </p>

    <p *ngIf="waitingScreenData.started" class="subtitle2">
      {{ "meeting-room.waiting-room-text" | translate }}
    </p>

    <div
      class="meeting-info"
      *ngIf="
        meetingInfo &&
        !waitingScreenData.started &&
        meetingInfo.startDate &&
        meetingInfo.expectedEndDate
      "
    >
      <h3>{{ roomTitle }}</h3>
      <div class="time">
        <p>اليوم: {{ formatDate(meetingInfo.startDate) }}</p>
        <p>الساعة: {{ formatTime(meetingInfo.startDate, lang.currentLang) }}</p>
      </div>
    </div>

    <button
      mat-flat-button
      color="primary"
      class="main-btn subtitle1 leave-button"
      (click)="goToHome()"
      *ngIf="waitingScreenData.started"
    >
      {{ "meeting-room.leave-waiting-room" | translate }}
    </button>
    <button
      mat-flat-button
      color="primary"
      class="main-btn subtitle1"
      (click)="goToHome()"
      *ngIf="!waitingScreenData.started"
    >
      {{ "meeting-room.back-home" | translate }}
    </button>
  </div>
</div>
<!-- end waiting room screen -->
