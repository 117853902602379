<mat-accordion multi="true">
  <mat-nav-list class="top-of-menu ml-0" dense>
    <ng-container *ngFor="let menu of menuData | async; let i = index">
      <!--if menu not have child-->
      <mat-list-item
        (click)="toggleRightSidenav()"
        class="items-padding-list"
        routerLink="{{ menu.url }}"
        [routerLinkActive]="['is-active']"
        *ngIf="!menu.child"
      >
        <mat-icon *ngIf="menu.icon" aria-hidden="false">{{
          menu.icon
        }}</mat-icon
        ><a>{{ "sideMenu." + menu.title | translate }}</a>
      </mat-list-item>
      <!--if has child-->
      <mat-expansion-panel class="no-shadow" *ngIf="menu.child">
        <mat-expansion-panel-header class="items-padding-panel" fxLayout="row">
          <mat-panel-title
            ><mat-icon *ngIf="menu.icon" aria-hidden="false">{{
              menu.icon
            }}</mat-icon
            ><a>{{
              "sideMenu." + menu.title + "Menu" | translate
            }}</a></mat-panel-title
          >
        </mat-expansion-panel-header>
        <mat-nav-list class="ml-0" dense>
          <mat-list-item
            (click)="toggleRightSidenav()"
            routerLink="{{ subMenu.url }}"
            [routerLinkActive]="['is-active']"
            *ngFor="let subMenu of menu.child; let subIndex = index"
          >
            <mat-icon *ngIf="subMenu.icon" aria-hidden="false">{{
              subMenu.icon
            }}</mat-icon
            ><a>{{
              "sideMenu." + menu.title + "." + subMenu.title | translate
            }}</a>
          </mat-list-item>
        </mat-nav-list>
      </mat-expansion-panel>
      <!--end of child-->
    </ng-container>
  </mat-nav-list>
</mat-accordion>
