import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

@Directive({ selector: '[capsLock]' })
export class TrackCapsDirective {
  @Output('capsLock') capsLock = new EventEmitter<Boolean>();

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    //  this.capsLock.emit(event.getModifierState && event.getModifierState('CapsLock'));
    this.capsLock.emit(this.capS(event));
  }
  @HostListener('window:keyup', ['$event'])
  onKeyUp(event: KeyboardEvent): void {
    this.capsLock.emit(this.capS(event));
    // this.capsLock.emit(event.getModifierState && event.getModifierState('CapsLock'));
  }

  capS(e) {
    if (!e.which) return;
    var s = String.fromCharCode(e.which);
    if (
      (s.toUpperCase() === s && s.toLowerCase() !== s && e.shiftKey) || //caps is on
      (s.toUpperCase() !== s && s.toLowerCase() === s && e.shiftKey) ||
      e.getModifierState('CapsLock')
    ) {
      return true;
    }
    return false;
  }
}
