<div class="dialog-body">
  <div class="header">
    <a class="close-icon" (click)="close()">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <figure>
    <img
      src="../../../assets/images/icons/mic-cam-off.svg"
      [alt]="'setting.' + data.title | translate"
    />
  </figure>
  <h5>
    {{ "setting." + data.title | translate }}
  </h5>
  <p>{{ "setting." + data.msg | translate }}</p>
</div>
