import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-record-dialog',
  templateUrl: './record-dialog.component.html',
  styleUrls: ['./record-dialog.component.scss'],
})
export class RecordDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<RecordDialogComponent>) {}

  ngOnInit(): void {}

  startRecord() {
    this.dialogRef.close(true);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
