<div class="dialog-header">
  <a (click)="onNoClick()" class="bodytext2">
    <mat-icon>close</mat-icon>
  </a>

  <h4 class="headline7">
    {{ "meeting-side.invite-members" | translate }}
  </h4>

  <div class="person-icon">
    <mat-icon>person_add</mat-icon>
  </div>
</div>

<div class="dialog-body">
  <!-- NOTE: DONNOT remove (UI without logic) -->
  <!-- <mat-form-field
    class="bodytext-normal full-width"
    appearance="outline"
    color="primary"
  >
    <mat-label>
      {{
        "meeting-side.write-an-email-to-send-an-invitation-to-participate"
          | translate
      }}
    </mat-label>
    <input
      autocomplete="false"
      dir="auto"
      type="email"
      [(ngModel)]="email"
      [ngModelOptions]="{ standalone: true }"
      matInput
    />

    <button
      class="send-suffix"
      mat-icon-button
      matSuffix
      (click)="sendInvitation()"
      aria-label="email the invitation link"
    >
      <mat-icon color="primary" class="arrow-dir">send</mat-icon>
    </button>
  </mat-form-field> -->

  <button
    class="main-btn block icon-inline-end"
    mat-flat-button
    color="primary"
    (click)="sendInvitation()"
  >
    <span>
      {{ "meeting-room.copy-link" | translate }}
    </span>
    <mat-icon>link</mat-icon>
  </button>
</div>
