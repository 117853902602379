// module
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { CodeInputModule } from 'angular-code-input';

// service
import { UsersService } from 'src/app/auth/services/users.service';

// component
import { LoginComponent } from 'src/app/auth/login/login.component';
import { LogoutComponent } from 'src/app/auth/logout/logout.component';

@NgModule({
  imports: [
    CodeInputModule,
    SharedModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatSelectModule,
    MatCheckboxModule,
    TranslateModule,
    FlexLayoutModule,
    RouterModule,
    FormsModule,
  ],
  declarations: [LoginComponent, LogoutComponent],
  exports: [LogoutComponent],
  providers: [UsersService],
})
export class AuthModule {}
