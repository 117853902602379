<section class="empty_state_wrapper">
  <div class="empty_state_slideShow">
    <a class="prev" (click)="plusSlides(-1)">
      <mat-icon>
        {{ "empty_state.arrow_forward_ios" | translate }}
      </mat-icon>
    </a>

    <div class="slideshow-container">
      <div class="mySlides fade">
        <figure>
          <img
            src="../../../../assets/images/home/schedule_meetig_1.png"
            [alt]="'empty_state.schedule_meetings_for_later' | translate"
          />
        </figure>
        <div class="text">
          <h3>{{ "empty_state.schedule_meetings_for_later" | translate }}</h3>
          <p>
            {{ "empty_state.schedule_meetings_for_laterP" | translate }}
          </p>
        </div>
      </div>

      <div class="mySlides fade">
        <figure>
          <img
            src="../../../../assets/images/home/schedule_meetig_2.png"
            [alt]="'empty_state.share_link' | translate"
          />
        </figure>
        <div class="text">
          <h3>{{ "empty_state.share_link" | translate }}</h3>
          <p>
            {{ "empty_state.share_linkP" | translate }}
          </p>
        </div>
      </div>

      <div class="mySlides fade">
        <figure>
          <img
            src="../../../../assets/images/home/schedule_meetig_3.png"
            [alt]="'empty_state.meet_privacy' | translate"
          />
        </figure>
        <div class="text">
          <h3>{{ "empty_state.meet_privacy" | translate }}</h3>
          <p>
            {{ "empty_state.meet_privacyP" | translate }}
          </p>
        </div>
      </div>
    </div>

    <a class="next" (click)="plusSlides(1)">
      <mat-icon> {{ "empty_state.arrow_forward_ios" | translate }} </mat-icon>
    </a>
  </div>

  <div class="dots">
    <span class="dot" (click)="currentSlide(1)"></span>
    <span class="dot" (click)="currentSlide(2)"></span>
    <span class="dot" (click)="currentSlide(3)"></span>
  </div>
</section>
