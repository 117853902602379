import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  AfterViewInit,
  Input,
} from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { DataService } from 'src/app/shared/services/data.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { LangService } from 'src/app/shared/services/lang.service';
import { LogoutDialogService } from 'src/app/shared/services/logout-dialog.service';
import { Direction } from '@angular/cdk/bidi';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss'],
})
export class NavbarComponent implements OnInit, AfterViewInit, OnDestroy {
  mediaSub: Subscription;
  routSub$: Subscription;
  home: boolean = false;
  isDarkTheme = this._theme.isDarkTheme;
  direction: Direction;
  @Input('class') panelClass: string;
  public screenWidth: any = window.innerWidth;
  navRes: boolean = false;
  navResPages: boolean = false;
  activeRoute;
  // swithTheme = this._theme.switchTheme.bind(this._theme);

  constructor(
    public mediaObserver: MediaObserver,
    public dialog: MatDialog,
    public cdRef: ChangeDetectorRef,
    public dataService: DataService,
    public lang: LangService,
    private _router: Router,
    private _theme: ThemeService,
    private _route: ActivatedRoute,
    private _logoutDialogService: LogoutDialogService
  ) {
    this.routSub$ = this._router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        this.activeRoute = dataService.url;
        this.closeNav();
      });
  }

  ngOnInit() {
    this.direction = this.lang.direction;

    this.mediaSub = this.mediaObserver.media$.subscribe(
      (change: MediaChange) => {}
    );

    if (this._router.url == '/home') {
      this.home = true;
    } else {
      this.home = false;
    }
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  // close navbar in responsive
  closeNav() {
    this.navRes = false;
    this.navResPages = false;
  }

  // logout
  logout() {
    this._logoutDialogService.logout();
  }

  ngOnDestroy() {
    if (this.mediaSub) this.mediaSub.unsubscribe();
    if (this.routSub$) this.routSub$.unsubscribe();
    this.home = false;
  }
}
