import { Component, Inject } from '@angular/core';
import {
  MatSnackBarRef,
  MAT_SNACK_BAR_DATA,
} from '@angular/material/snack-bar';
import { KortobaaMeetService } from '@kortobaa-front/ng-kmeet-sdk';
import { TranslateService } from '@ngx-translate/core';
import { replaceTemplate } from 'src/app/shared/helpers/customFunctions';
import { LangService } from 'src/app/shared/services/lang.service';

@Component({
  selector: 'app-top-snackbar',
  templateUrl: './top-snackbar.component.html',
  styleUrls: ['./top-snackbar.component.scss'],
})
export class TopSnackbarComponent {
  message: string;
  constructor(
    private _translate: TranslateService,
    public kortobaaPkg: KortobaaMeetService,
    public _lang: LangService,
    public dialogRef: MatSnackBarRef<TopSnackbarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ) {
    if (data.type == 'host') {
      this.kortobaaPkg.participants.filter((user: any) => {
        if (user.host) {
          this.message = replaceTemplate(
            this._translate.instant('meeting-room.assign-new-host'),
            { USER_NAME: user.name }
          );
        }
      });
    }
  }

  ngOnInit() {}

  closeSnackBar(withAction: boolean = false) {
    if (withAction) {
      this.dialogRef.dismissWithAction();
    } else {
      this.dialogRef.dismiss();
    }
  }
}
