import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-action',
  templateUrl: './loading-action.component.html',
  styleUrls: ['./loading-action.component.scss'],
})
export class LoadingActionComponent implements OnInit {
  @Input() scale: number;
  constructor() {}

  ngOnInit(): void {}
}
