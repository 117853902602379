import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, ElementRef, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

import { DataService } from 'src/app/shared/services/data.service';
import { LangService } from '../../services/lang.service';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss'],
})
export class PagesComponent {
  htmlData: any = '';
  loading = true;
  pageLoaded = false;
  pageId: string;
  activeLink = '';
  isDown = false;
  startX!: any;
  scrollLeft!: any;
  @ViewChild('tabs') tabs!: ElementRef;
  public screenWidth: any = window.innerWidth;
  panelOpenState: boolean = false;
  panelOpenStateID!: any;

  constructor(
    public _DataService: DataService,
    private _http: HttpClient,
    private _route: ActivatedRoute,
    public _lang: LangService
  ) {}

  ngOnInit() {
    this._route.params.subscribe((params) => {
      this.pageId = params['id'];
      this.activeLink = this.pageId;
      this.loading = true;

      // scroll to tab in responsive
      if (document.getElementById(this.pageId) as HTMLElement) {
        let tabId = document.getElementById(this.pageId) as HTMLElement;
        setTimeout(() => {
          this.gotoTab(tabId);
        });
      }
      // fetch data from api
      this.getPagesApi();
    });
  }

  // scroll to tabs
  gotoTab(pageId) {
    if (pageId) {
      pageId.scrollIntoView({
        behavior: 'smooth',
        block: 'end',
        inline: 'nearest',
      });
    }
  }

  // get pages api
  async getPagesApi() {
    const headers = new HttpHeaders();

    try {
      const htmlData = await this._http
        .get(
          `${this._DataService.apiHost}${
            this.pageId == 'FAQ' ? 'faqs' : 'pages/' + this.pageId
          }`,
          {
            headers,
            responseType: 'text',
          }
        )
        .toPromise();
      this.htmlData = this.pageId == 'FAQ' ? JSON.parse(htmlData) : htmlData;
      this.pageLoaded = true;
      this.loading = false;
      // console.log(this.htmlData);
    } catch (e) {
      this.loading = false;
    }
  }

  //scroll by mouse in responsive
  mouseDown(e: MouseEvent) {
    this.isDown = true;
    this.startX = e.pageX - this.tabs.nativeElement.offsetLeft;
    this.scrollLeft = this.tabs.nativeElement.scrollLeft;
  }

  mouseleave() {
    this.isDown = false;
  }

  mouseup() {
    this.isDown = false;
  }

  mousemove(e: MouseEvent) {
    if (!this.isDown) return;
    e.preventDefault();
    const x = e.pageX - this.tabs.nativeElement.offsetLeft;
    const walk = (x - this.startX) * 3; //scroll-fast
    this.tabs.nativeElement.scrollLeft = this.scrollLeft - walk;
  }

  // open zenDesk
  openZenDesk() {
    window.open('', '_blank');
  }

  // toggle expanded
  toggle(id: any) {
    this.panelOpenStateID = id;
    this.panelOpenState = this.panelOpenState == true ? false : true;
  }
}
