import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KortobaaMeetService } from '@kortobaa-front/ng-kmeet-sdk';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-leave-meeting-dialog',
  templateUrl: './leave-meeting-dialog.component.html',
  styleUrls: ['./leave-meeting-dialog.component.scss'],
})
export class LeaveMeetingDialogComponent implements OnInit {
  closeMeetingAudio = new Audio('../../../assets/audio/closed meeting.mp3');

  constructor(
    private _router: Router,
    public kortobaaPkg: KortobaaMeetService,
    public dialogRef: MatDialogRef<LeaveMeetingDialogComponent>
  ) {}

  ngOnInit(): void {}

  backToHome() {
    // Lets destroy all connections from component before leaving
    this.kortobaaPkg.resetMeetingConnections();
    this._router.navigate(['/home']);
  }

  leaveMeeting() {
    this.backToHome();
    this.kortobaaPkg.reconnecting = false;
    this.dialogRef.close();
    this.closeMeetingAudio.play();
  }

  endMeeting() {
    // end meeting function for meeting host
    this.kortobaaPkg.endMeeting();
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
