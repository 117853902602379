<form (ngSubmit)="saveTemplate($event)">
  <mat-form-field
    class="bodytext-normal full-width"
    block
    appearance="fill"
    color="primary"
  >
    <mat-label>{{ "meeting-side.template-name" | translate }}</mat-label>
    <input
      type="text"
      name="templateName"
      [(ngModel)]="templateName"
      matInput
      autocomplete="off"
    />
  </mat-form-field>

  <button
    type="submit"
    mat-flat-button
    class="sumbit-btn"
    [disabled]="!templateName"
  >
    {{ "account.save" | translate }}
  </button>
</form>
