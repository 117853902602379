import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class scrollRequired {
  constructor() {}
  public screenWidth: any = window.innerWidth;

  // scroll to field is invalid
  scrollToFirstInvalidControl(formId: any, form) {
    let formElement = document.getElementById(`${formId}`); // <-- your formID
    let firstInvalidControl =
      formElement?.getElementsByClassName('ng-invalid')[0];

    (firstInvalidControl as HTMLElement)?.scrollIntoView({
      block: 'center',
      inline: 'nearest',
    });

    (firstInvalidControl as HTMLElement)?.focus();
    (firstInvalidControl as HTMLElement)?.classList.add('mat-focused');
    (firstInvalidControl as HTMLElement)?.classList.add('ng-touched');
    (firstInvalidControl as HTMLElement)?.classList.add('ng-dirty');
    // (firstInvalidControl as HTMLElement)?.classList.add('invalid');
    for (let element in form) {
      if (form[element].status == 'INVALID') {
        form[element].touched = true;
        return;
      }
    }
  }
}
