import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment-timezone';

@Pipe({
  name: 'datePipe',
})
export class DatePipePipe implements PipeTransform {
  transform(
    value: any,
    format: string,
    lang: string,
    add?: boolean,
    ...args: unknown[]
  ): unknown {
    moment.locale(lang);
    if (add) {
      if (
        moment(new Date(value)).format('YY ') ==
          moment(new Date()).format('YY ') &&
        moment(new Date(value)).format('MMM ') ==
          moment(new Date()).format('MMM ')
      ) {
        if (new Date(value).getDate() == new Date().getDate()) {
          return moment().calendar().split(' ')[0];
        }
        if (new Date(value).getDate() - new Date().getDate() == 1) {
          return moment().add(1, 'days').calendar().split(' ')[0];
        }
        if (new Date().getDate() - new Date(value).getDate() == 1) {
          return moment().add(-1, 'days').calendar().split(' ')[0];
        }
      }
    }
    return moment(new Date(value)).format(format);
  }
}
