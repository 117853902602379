<div class="leave-meeting">
  <div class="header">
    <h2>{{ "meeting-side.remove-from-meeting" | translate }}</h2>
    <a class="close-icon" (click)="onNoClick()">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <p>
    {{ "meeting-side.remove-user-start" | translate }}
    {{ data.user.name }}
    {{ "meeting-side.remove-user-end" | translate }}
  </p>

  <!--<form #form="ngForm" (change)="select(form)">
    <div class="input-field">
      <label for="remove">{{
        "meeting-side.temporary-kick" | translate
      }}</label>
      <input
        ngModel
        type="radio"
        name="removeUser"
        value="kick"
        id="remove"
        selected
      />
    </div>
    <div class="input-field">
      <label for="kick">{{ "meeting-side.permanent-kick" | translate }}</label>
      <input ngModel type="radio" name="removeUser" value="ban" id="kick" />
    </div>
  </form>-->
  <mat-radio-group [(ngModel)]="selectedValue" (change)="select($event)">
    <mat-radio-button color="accent" class="input-field" value="kick">{{
      "meeting-side.temporary-kick" | translate
    }}</mat-radio-button>
    <mat-radio-button color="accent" class="input-field" value="ban">{{
      "meeting-side.permanent-kick" | translate
    }}</mat-radio-button>
  </mat-radio-group>
  <div class="leave-action">
    <button
      *ngIf="kortobaaPkg.isHost"
      mat-flat-button
      class="end-btn"
      block
      (click)="onNoClick()"
    >
      {{ "meeting-side.cancel" | translate }}
    </button>
    <button class="leave-btn" mat-flat-button block (click)="remove()">
      {{ "account.delete" | translate }}
    </button>
  </div>
</div>
