import { ModuleWithProviders } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { LoginComponent } from 'src/app/auth/login/login.component';
import { LogoutComponent } from 'src/app/auth/logout/logout.component';
import { AuthGuard } from 'src/app/core/auth.guard';
import { DisabelLogin } from 'src/app/core/disabelLogin.guard';
import { PageNotFoundComponent } from 'src/app/core/page-not-found/page-not-found.component';
import { HomeComponent } from 'src/app/home/pages/home/home.component';
import { MeetingComponent } from 'src/app/meeting/meeting-ui/meeting.component';
import { ProfileComponent } from 'src/app/profile/profile/profile.component';
import { ScheduleNewMeetingComponent } from 'src/app/profile/schedule-new-meeting/schedule-new-meeting.component';
import { PagesComponent } from 'src/app/shared/components/privacy/pages.component';
import { SettingPageComponent } from '../shared/components/setting-page/setting-page.component';
import { CanDeactivateGuard } from '../shared/guard/can-deactivate.guard';

const coreRoutes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: 'login', component: LoginComponent, canActivate: [DisabelLogin] },
  { path: 'signup', component: LoginComponent, canActivate: [DisabelLogin] },
  { path: 'logout', component: LogoutComponent },
  { path: 'home', component: HomeComponent }, //, canActivate: [AuthGuard]},
  { path: 'meeting/:token', component: MeetingComponent },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'setting',
    component: SettingPageComponent,
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'schedule-new-meeting',
    component: ScheduleNewMeetingComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  {
    path: 'schedule-new-meeting/:meeting',
    component: ScheduleNewMeetingComponent,
    canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard],
  },
  { path: 'join-meeting', component: HomeComponent },
  { path: 'pages/:id', component: PagesComponent },
  { path: '**', component: PageNotFoundComponent },
];

export const CoreRouting: ModuleWithProviders<any> =
  RouterModule.forChild(coreRoutes);
