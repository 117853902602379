import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { DataService } from 'src/app/shared/services/data.service';
import { roles } from 'src/app/shared/models/interfaces';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  menuData$ = new BehaviorSubject(null);
  menuData = [
    {
      title: 'Home',
      url: '/home',
      icon: 'home',
      permission: 'home',
      child: false,
    },
    {
      title: 'Settings',
      url: '',
      icon: 'build',
      permission: '',
      child: [
        {
          title: 'Test submenu',
          url: '/test',
          icon: false,
          permission: 'test',
          child: false,
        },
      ],
    },
  ];
  emptyArrayForCheck: string[] = [];
  finalMenu = [];

  constructor(private _dataService: DataService) {
    if (this._dataService.currentUser) {
      this.updateMenuData();
    }
  }

  updateMenuData() {
    //check if user has admin role
    if (
      this._dataService.currentUser &&
      (this._dataService.currentUser.roles.includes(roles.admin) ||
        this._dataService.currentUser.roles.includes(roles.superAdmin))
    ) {
      //lets give finalPermission menuData items
      this.finalMenu = this.menuData;
      //and now lets get menu without company menus
      const companyMenus = this.finalMenu.filter(
        (com) => com.permission != roles.company
      );
      this.menuData$.next(companyMenus);
    } else {
      //lets get permissions menu
      this.getMenuFromPermissions();
    }
  }

  getMenuFromPermissions() {
    const permissions: string[] = this._dataService.currentUser.roles;
    //lets push home permission as default permission
    permissions.push('home');
    //final menu to set it as menuData
    this.finalMenu = [];
    this.menuData.forEach((menuItem: any) => {
      //if this menu is header of subMenus
      if (menuItem.child) {
        //lets loop on it to check if it in permissions
        menuItem.child.forEach((subMenuItem) => {
          let findMenuinPermissions = permissions.find(
            (menuData: any) =>
              menuData.toLowerCase() == subMenuItem.permission.toLowerCase()
          );
          //if menuData found in permissions
          if (findMenuinPermissions) {
            //check if parent menu is already in finalMenu
            let checkIfParentMenuInFinalMenu = this.finalMenu.findIndex(
              (final) =>
                final.title.toLowerCase() == menuItem.title.toLowerCase()
            );
            //if parent is not exist
            if (checkIfParentMenuInFinalMenu == -1) {
              //create new Menu with parent props but change child to first child
              let newMenu = {
                title: menuItem.title,
                url: menuItem.url,
                icon: menuItem.icon,
                permission: menuItem.permission,
                child: [subMenuItem], //set first child
              };
              this.finalMenu.push(newMenu);
            } else {
              //if parent already exist lets push submenu in parent child
              this.finalMenu[checkIfParentMenuInFinalMenu].child.push(
                subMenuItem
              );
            }
          }
        });
      } else {
        //lets check if this menu in permissins
        let findMenuinPermissions = permissions.find(
          (menuData: any) =>
            menuData.toLowerCase() == menuItem.permission.toLowerCase()
        );
        //if yes lets put it in finalMenu array
        if (findMenuinPermissions) {
          this.finalMenu.push(menuItem);
        }
      }
    });
    this.menuData$.next(this.finalMenu);
  }

  readTranlationFromMenu() {
    this.menuData.forEach((data: any) => {
      let translate = 'sideMenu.';
      if (data.child != false) {
        translate += data.title;
        this.emptyArrayForCheck.push(translate + 'Menu');
        translate += '.';
        data.child.forEach((element) => {
          const childTitle = translate + element.title;
          this.emptyArrayForCheck.push(childTitle);
        });
      } else {
        translate += data.title;
        this.emptyArrayForCheck.push(translate);
      }
    });
  }
}
