<!-- open dialog open app if join from mobile or tablet -->
<ng-container *ngIf="this.openAppUi">
  <div *ngIf="content.status && content.type == 'openApp'" class="open-app">
    <div class="dialog-content">
      <div class="dialog-header">
        <div class="header-title">
          <h4>
            {{
              "meeting-room.please-continue-through-the-app-title" | translate
            }}
          </h4>
        </div>
      </div>
      <p class="subtitle1">
        {{ "meeting-room.use-app-info" | translate }}
      </p>

      <div class="have-app">
        <button mat-flat-button color="primary" (click)="openApp()">
          {{ "meeting-room.use-app-button" | translate }}
        </button>
        <p (click)="close()">
          {{ "meeting-room.later" | translate }}
        </p>
      </div>
    </div>
  </div>

  <div
    *ngIf="content.status && content.type == 'openMeet'"
    class="open-meeting"
  >
    <div class="dialog-content">
      <div class="dialog-header">
        <div class="header-title">
          <h4>
            {{
              "meeting-room.please-continue-through-the-app-title" | translate
            }}
          </h4>
        </div>
      </div>
      <p class="subtitle1">
        {{ "meeting-room.please-continue-through-the-app-info" | translate }}
      </p>
      <button
        mat-flat-button
        color="primary"
        (click)="downloadApp()"
        class="download-app"
      >
        {{ "meeting-room.download" | translate }}
      </button>

      <div class="have-app">
        <p>
          {{ "meeting-room.have-app" | translate }}
        </p>
        <button mat-stroked-button color="primary" (click)="openMeeting()">
          {{ "meeting-room.open" | translate }}
        </button>
      </div>
    </div>
  </div>
</ng-container>
