import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-host-options-dialog',
  templateUrl: './host-options-dialog.component.html',
  styleUrls: ['./host-options-dialog.component.scss'],
})
export class HostOptionsDialogComponent implements OnInit {
  meetingSettings = {
    locked: false,
    isWaiting: true,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<HostOptionsDialogComponent>
  ) {}

  ngOnInit(): void {
    this.meetingSettings.locked = this.data.meetingSettings.locked;
    this.meetingSettings.isWaiting = this.data.meetingSettings.isWaiting;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  changeMeetingSettings() {
    this.dialogRef.close({
      locked: this.meetingSettings.locked,
      isWaiting: this.meetingSettings.isWaiting,
    });
  }
}
