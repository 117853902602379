import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { UsersService } from 'src/app/auth/services/users.service';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-delete-account-dialog',
  templateUrl: './delete-account-dialog.component.html',
  styleUrls: ['./delete-account-dialog.component.scss'],
})
export class DeleteAccountDialogComponent {
  deleteSub$!: Subscription;
  constructor(
    public dialogRef: MatDialogRef<DeleteAccountDialogComponent>,
    private _router: Router,
    public userService: UsersService,
    private _translate: TranslateService,
    public dataService: DataService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  // delete account
  deleteAccount() {
    this.deleteSub$ = this.userService.deleteAccount().subscribe({
      next: (data) => {
        let successMessage = this._translate.instant(
          'account.delete-account-dialog.success'
        );
        this.dataService.notification(successMessage);
        this.dialogRef.close();
        this._router.navigate(['/logout']);
      },
      error: (error) => {
        this.dialogRef.close();
      },
    });
  }

  ngOnDestroy() {
    if (this.deleteSub$) {
      this.deleteSub$.unsubscribe();
    }
  }
}
