import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import * as moment from 'moment';

@Pipe({
  name: 'timeago',
})
export class TimeagoPipe implements PipeTransform {
  constructor(private _translate: TranslateService) {}

  transform(timePassedBySeconds: number) {
    moment.locale(this._translate.currentLang);
    const passedDate = moment();

    passedDate.subtract(timePassedBySeconds, 'seconds');

    return passedDate.fromNow();
  }
}
