import { Directive, HostBinding, Input } from '@angular/core';
import { Subscription } from 'rxjs';

import { ThemeService } from 'src/app/shared/services/theme.service';

@Directive({
  selector: 'img[ployfillImg]',
  host: {
    '(error)': 'updateUrl()',
    '(load)': 'load()',
    '[src]': 'src',
  },
})
export class ImagePreloadDirective {
  @Input() src: string;
  @Input() ployfillImg: string | null;
  @HostBinding('class') className;
  themeSub: Subscription;

  constructor(private _theme: ThemeService) {}

  updateUrl() {
    // If the img polyfill src is specifed render it
    if (this.ployfillImg) return (this.src = this.ployfillImg);

    // Else render the default user image
    this.themeSub = this._theme.isDarkSubject$.subscribe((isDarkTheme) => {
      this.src = isDarkTheme
        ? '../../../assets/images/Profile pic.png' // For the dark theme
        : 'assets/images/no-img-light.svg'; // For the light theme
    });
  }

  load() {
    this.className = 'image-loaded';
  }

  ngOnDestroy() {
    if (this.themeSub) this.themeSub.unsubscribe();
  }
}
