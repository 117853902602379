<div class="leave-meeting">
  <div class="header">
    <h2 *ngIf="!isMember">{{ "meeting-side.mute-all" | translate }}</h2>
    <h2 *ngIf="isMember">{{ "meeting-side.mute-btn" | translate }}</h2>
    <a class="close-icon" (click)="onNoClick()">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <p *ngIf="!isMember">
    {{ "meeting-side.mute-question" | translate }}
  </p>
  <p *ngIf="isMember">
    {{ "meeting-side.mute-member-start" | translate }}
    {{ data.user.name }}
    {{ "meeting-side.mute-member-end" | translate }}
  </p>
  <div class="leave-action">
    <button
      *ngIf="kortobaaPkg.isHost"
      mat-flat-button
      class="end-btn"
      block
      (click)="onNoClick()"
    >
      {{ "meeting-side.cancel" | translate }}
    </button>
    <button class="leave-btn" mat-flat-button block (click)="mute()">
      {{ "meeting-side.mute-btn" | translate }}
    </button>
  </div>
</div>
