import {
  Component,
  OnInit,
  ChangeDetectorRef,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { UserService } from '@kortobaa-front/authentication';
import { UserMediaService } from '@kortobaa-front/ng-kmeet-sdk';

import { DataService } from 'src/app/shared/services/data.service';
import { MeetingsService } from 'src/app/meeting/services/meetings.service';
import { LangService } from 'src/app/shared/services/lang.service';
import { MemberRemovedDialogComponent } from 'src/app/home/dialog/member-removed-dialog/member-removed-dialog.component';
import { environment } from 'src/environments/environment';
import { OpenAppDialogComponent } from 'src/app/shared/components/open-app-dialog/open-app-dialog.component';
import { Platform } from '@angular/cdk/platform';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, AfterViewInit, OnDestroy {
  personalRoomURL = '';
  loading = {
    personal: true,
  };
  private _checkUserValidSub: Subscription;

  userData = {
    name: '',
    phone: '',
    jobTitle: '',
    country: null,
    state: null,
    company: null,
  };

  personalRooom = {
    meetingToken: '',
    hasPassword: false,
    password: '',
    excludePassword: false,
  };
  openAppUi = false;

  constructor(
    public cdRef: ChangeDetectorRef,
    public dataService: DataService,
    public dialog: MatDialog,
    public kortobaaPkgUserService: UserService,
    public _userMediaService: UserMediaService,
    private _router: Router,
    private _meetingService: MeetingsService,
    public _lang: LangService,
    private platform: Platform,
    private translate: TranslateService
  ) {}

  async ngOnInit() {
    this.dataService.clearMeetingInfo();

    if (
      this.platform.ANDROID ||
      (this.platform.IOS &&
        (this.dataService.url == '/home' || this.dataService.url == '/'))
    ) {
      this.openAppUi = true;
      this.openAppDialog('openApp');
    }

    // End the config stream if the user click back button meanwhile meeting initaing
    setTimeout(() => {
      if (this._userMediaService.stream?.active) {
        this._userMediaService.stopStream();
        this._userMediaService.stopStream('video');
        this._userMediaService.stopStream('audio');
      }
    }, 1000);

    this.kortobaaPkgUserService.checkUserValid().then((observable) => {
      this._checkUserValidSub = observable.subscribe(async (isUserValid) => {
        if (isUserValid) {
          //this.setScheduledMeetingCount();
          await this.getPersonalRoomData();

          const { username, phone, additionalData } =
            this.dataService.getUserData();
          this.userData.name = username;
          this.userData.jobTitle = additionalData?.jobTitle;
          this.userData.company = additionalData?.company;
          this.userData.country = additionalData?.country;
          this.userData.state = additionalData?.state;
          this.userData.phone = phone;
        }
      });
    });

    //lets check if any data passed to home shared data
    const routeData = this.dataService.homeStatus.type;
    //if user kicked
    if (routeData != null) {
      //just see warning dialog
      this.dialog.open(MemberRemovedDialogComponent, {
        width: '26rem',
        direction: this._lang.direction,
        data: {
          status: routeData,
        },
      });
    }
    //lets reset data to null
    if (routeData) this.dataService.homeStatus.type = null;
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  async getPersonalRoomData() {
    this.loading.personal = true;
    try {
      const myRoomData = await this._meetingService
        .getPersonalRoom()
        .toPromise();
      this.personalRooom.meetingToken = myRoomData.token;
      if (myRoomData.password && myRoomData.password?.length > 0) {
        this.personalRooom.hasPassword = true;
        this.personalRooom.password = myRoomData.password;
      }
      this.loading.personal = false;
    } catch (error) {
      if (this.dataService.getUserData()) {
        setTimeout(async () => {
          this.translate.instant('reconnect-in-home');
          await this.getPersonalRoomData();
        }, 10000);
      } else {
        this.loading.personal = false;
      }
    }
  }

  personalMeeting() {
    if (this.openAppUi) {
      this.openAppDialog(
        'openMeet',
        `${this.personalRooom.meetingToken}${
          this.personalRooom.hasPassword && !this.personalRooom.excludePassword
            ? `?p=` + this.personalRooom.password
            : ''
        }`
      );
      return;
    }
    this._router.navigate(
      ['meeting/' + this.personalRooom.meetingToken],
      this.personalRooom.hasPassword && !this.personalRooom.excludePassword
        ? { queryParams: { p: this.personalRooom.password } }
        : {}
    );
  }

  openPlayStore() {
    window.open(environment.PLAY_STORE_URL);
  }

  openAppStore() {
    window.open(environment.APP_STORE_URL);
  }

  openAppDialog(value: string, token?: any) {
    this.dialog.open(OpenAppDialogComponent, {
      width: 'auto',
      minHeight: 'auto',
      direction: this._lang.direction,
      autoFocus: false,
      panelClass: ['openApp'],
      data: {
        type: value,
        status: this.openAppUi,
        token: token,
      },
    });
  }

  ngOnDestroy() {
    if (this._checkUserValidSub) this._checkUserValidSub.unsubscribe();
  }
}
