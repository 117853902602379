import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Inject, Injectable, Injector } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Observable, throwError, NEVER } from 'rxjs';
import { catchError, finalize, tap, retryWhen, delay } from 'rxjs/operators';
import { Unauthorized } from 'src/app/shared/errors';
import { DataService } from 'src/app/shared/services/data.service';
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private _translate: TranslateService,
    @Inject(Injector) private readonly injector: Injector,
    private _router: Router
  ) {
    // Listen to route changing
    this._router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Remove error toast if exist
        if (this._currentRoute != event.urlAfterRedirects) {
          this._errorToastIds.forEach((id) =>
            this._dataService.clearNotification(id)
          );
        }
        this._currentRoute = event.urlAfterRedirects;
      }
    });
  }
  // Need to get DataService from injector rather than constructor injection to avoid cyclic dependency error
  private get _dataService(): DataService {
    return this.injector.get(DataService);
  }

  private _showErrorToast(message: string) {
    const toast = this._dataService.notification(message, true);
    this._errorToastIds.push(toast.toastId);
  }

  private _currentRoute = '';
  private _errorToastIds = [];

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const reqRoute = this._currentRoute;

    return next.handle(request).pipe(
      retryWhen((errors) =>
        errors.pipe(
          tap((error) => {
            let message = '';
            if (reqRoute != this._currentRoute) {
              throw new Error('routed');
            } else if (error instanceof HttpErrorResponse) {
              // Error due to client (e.g network)
              if (error.error instanceof ErrorEvent) {
                message = this._translate.instant(
                  'errors.please-check-your-network'
                );
                this._showErrorToast(message);
                // Error due to server
              } else {
                switch (error.status) {
                  case 429:
                    message = this._translate.instant('please-wait');
                    this._showErrorToast(message);
                  // TODO: Find way to retry if user is unauthorized with new token
                  case 401:
                    // If error comes from login requset
                    if (request.url.endsWith('login'))
                      throw new Error(
                        this._translate.instant('errors.invalid-email-or-pass')
                      );
                    throw new Unauthorized(
                      this._translate.instant('errors.unauthorized')
                    );
                  case 400:
                  case 422:
                    // If user enters invalid meeting id or name
                    if (
                      error.error.error.message == 'Cannot find the meeting.'
                    ) {
                      throw new Error(
                        this._translate.instant(
                          'validation.meeting-id-not-valid'
                        )
                      );
                    }

                  case 404:
                    // If user enters past date or time
                    if (error.error.error.message == 'Invalid date') {
                      throw new Error(
                        this._translate.instant('errors.invalid-date')
                      );
                    }
                    throw new Error(
                      this._translate.instant(error.error.error.message)
                    );
                  default:
                    throw new Error(
                      this._translate.instant('errors.some-thing-went-wrong')
                    );
                }
              }
            } else {
              throw error;
            }
          }),
          delay(10000)
        )
      ),

      catchError((error) => {
        // Return nothing if route changes
        if (error.message == 'routed') {
          return NEVER;
        }

        // return error not toast message when request by validate token
        if (request.url.includes('validate-token')) {
          return throwError(error);
        }

        if (!request.url.endsWith('login') && !request.url.includes('my-meetings')) {
          this._showErrorToast(error.message);
        }

        // Return error handling to service or component
        return throwError(error);
      }),
      finalize(() => {})
    ) as Observable<HttpEvent<any>>;
  }
}
