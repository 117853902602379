<div class="setting-page">
  <div class="bg-img">
    <img src="assets/images/bg-settings.webp" alt="" />
  </div>

  <a class="brand">
    <figure routerLink="/">
      <img src="assets/images/logo.svg" />
    </figure>
  </a>

  <div class="setting-page-content" *ngIf="!dataService.getUserData()">
    <div class="form-input">
      <mat-label>{{ "settings.theme" | translate }}</mat-label>
      <div class="theme_container">
        <div class="theme disabled">
          <figure>
            <img
              src="../../../../assets/images/default_system.svg"
              [alt]="'settings.default-system' | translate"
            />
          </figure>
          <h4>
            {{ "settings.default-system" | translate }}
          </h4>
        </div>

        <div
          class="theme"
          (click)="switchTheme('dark')"
          [ngClass]="{ active: themeSelected == 'dark' }"
        >
          <figure>
            <img
              src="../../../../assets/images/dark.svg"
              [alt]="'settings.dark-theme' | translate"
            />
          </figure>
          <h4>
            {{ "settings.dark-theme" | translate }}
          </h4>
        </div>

        <div class="theme disabled">
          <figure>
            <img
              src="../../../../assets/images/light.svg"
              [alt]="'settings.light-theme' | translate"
            />
          </figure>
          <h4>
            {{ "settings.light-theme" | translate }}
          </h4>
        </div>
      </div>
    </div>

    <!-- <mat-divider></mat-divider> -->
    <!-- change language -->
    <div class="form-input">
      <mat-label>{{ "account.language" | translate }}</mat-label>

      <mat-form-field
        class="bodytext-normal"
        block
        appearance="fill"
        color="primary"
      >
        <mat-select
          [value]="this._lang.currentLang == 'en' ? 'en' : 'ar'"
          [panelClass]="
            _lang.direction == 'ltr' ? 'dirLtrClass' : 'dirRtlClass'
          "
        >
          <mat-option value="ar" (click)="this._lang.useLanguage('ar')">
            اللغة العربية
          </mat-option>
          <mat-option value="en" (click)="this._lang.useLanguage('en')">
            English
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>

  <div class="setting-page-content" *ngIf="dataService.getUserData()">
    <nav
      class="tabs"
      #tabs
      (mousedown)="mouseDown($event)"
      (mouseleave)="mouseleave()"
      (mouseup)="mouseup()"
      (mousemove)="mousemove($event)"
    >
      <a
        [class.active]="activeLink == 'general'"
        (click)="activeLink = 'general'; changeTab('general')"
        id="general"
      >
        {{ "setting.general-setting" | translate }}
      </a>
      <a
        (click)="activeLink = 'room'; changeTab('room')"
        [class.active]="activeLink == 'room'"
        id="room"
      >
        {{ "setting.personal-room" | translate }}
      </a>
      <a
        (click)="activeLink = 'media'; changeTab('media')"
        [class.active]="activeLink == 'media'"
        id="media"
        *ngIf="!dataService.isMobile"
      >
        {{ "setting.media-setting" | translate }}
      </a>

      <div class="actions" *ngIf="activeLink == 'room'">
        <button
          type="submit"
          mat-flat-button
          class="main-btn button18 action-btn"
          (click)="change()"
          [disabled]="
            (hasPassword && this.personalRoom.password.length == 0) ||
            personalRoom.title.length == 0 ||
            personalRoom.token.length == 0 ||
            loading ||
            !formChanged
          "
        >
          <app-loading-action *ngIf="loading"></app-loading-action>
          {{ "account.save" | translate }}
        </button>

        <button
          mat-flat-button
          class="main-btn button18 action-btn cancel"
          [disabled]="!formChanged"
          (click)="cancelEdit()"
        >
          {{ "account.cancel" | translate }}
        </button>
      </div>

      <div class="actions" *ngIf="activeLink == 'media'">
        <button
          type="submit"
          mat-flat-button
          class="main-btn button18 action-btn"
          (click)="changed()"
          [disabled]="
            (hasPassword && this.personalRoom.password.length == 0) ||
            personalRoom.title.length == 0 ||
            personalRoom.token.length == 0 ||
            loading ||
            !mediaChanged
          "
        >
          <app-loading-action *ngIf="loading"></app-loading-action>
          {{ "account.save" | translate }}
        </button>

        <button
          mat-flat-button
          class="main-btn button18 action-btn cancel"
          [disabled]="!mediaChanged"
          (click)="cancelEdit()"
        >
          {{ "account.cancel" | translate }}
        </button>
      </div>
    </nav>
    <div class="general" *ngIf="activeLink === 'general'">
      <div class="form-input">
        <mat-label>{{ "settings.theme" | translate }}</mat-label>
        <div class="theme_container">
          <div class="theme disabled">
            <figure>
              <img
                src="../../../../assets/images/default_system.svg"
                [alt]="'settings.default-system' | translate"
              />
            </figure>
            <h4>
              {{ "settings.default-system" | translate }}
            </h4>
          </div>

          <div
            class="theme"
            (click)="switchTheme('dark')"
            [ngClass]="{ active: themeSelected == 'dark' }"
          >
            <figure>
              <img
                src="../../../../assets/images/dark.svg"
                [alt]="'settings.dark-theme' | translate"
              />
            </figure>
            <h4>
              {{ "settings.dark-theme" | translate }}
            </h4>
          </div>

          <div class="theme disabled">
            <figure>
              <img
                src="../../../../assets/images/light.svg"
                [alt]="'settings.light-theme' | translate"
              />
            </figure>
            <h4>
              {{ "settings.light-theme" | translate }}
            </h4>
          </div>
        </div>
      </div>
      <!-- change language -->
      <div class="form-input">
        <mat-label>{{ "account.language" | translate }}</mat-label>

        <mat-form-field
          class="bodytext-normal"
          block
          appearance="fill"
          color="primary"
        >
          <mat-select
            [value]="this._lang.currentLang == 'en' ? 'en' : 'ar'"
            [panelClass]="
              _lang.direction == 'ltr' ? 'dirLtrClass' : 'dirRtlClass'
            "
          >
            <mat-option value="ar" (click)="this._lang.useLanguage('ar')">
              اللغة العربية
            </mat-option>
            <mat-option value="en" (click)="this._lang.useLanguage('en')">
              English
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class="room" *ngIf="activeLink === 'room'">
      <!-- add password to personal room -->
      <div class="input-container">
        <mat-slide-toggle
          color="primary"
          class="custom-slide-toggle"
          [(ngModel)]="hasPassword"
          (toggleChange)="toggleChange()"
        >
          <!-- (change)="change('pass')" -->
          <label class="select-label">{{
            "account.password" | translate
          }}</label>
        </mat-slide-toggle>

        <div class="form-input" *ngIf="hasPassword">
          <mat-label>
            {{ "account.pass" | translate }}
            <div class="tooltip">
              <mat-icon
                (click)="wrongPassCriteria = !wrongPassCriteria"
                class="material-icons-outlined info"
              >
                info</mat-icon
              >
              <span
                class="tooltiptext"
                [ngStyle]="{
                  visibility: wrongPassCriteria ? 'visible' : ''
                }"
              >
                <span class="custom-tooltip">
                  {{ "tooltip.password-criteria" | translate }}
                </span>
                <br />
                <mat-icon
                  class="close-icon"
                  *ngIf="personalRoom.password.length == 0"
                  >close</mat-icon
                >
                <mat-icon
                  class="success-icon"
                  *ngIf="personalRoom.password.length > 0"
                  >check</mat-icon
                >
                {{ "validation.meeting-password" | translate }}

                <br />
                <mat-icon
                  class="close-icon"
                  *ngIf="!passIncludeSpace || personalRoom.password.length == 0"
                  >close</mat-icon
                >
                <mat-icon
                  class="success-icon"
                  *ngIf="passIncludeSpace && personalRoom.password.length > 0"
                  >check</mat-icon
                >
                {{ "validation.meeting-password-space" | translate }}
              </span>
            </div>
          </mat-label>
          <mat-form-field
            class="bodytext-normal room-password"
            block
            appearance="fill"
            color="primary"
            [ngClass]="{
              invalid:
                passwordWritten &&
                hasPassword &&
                personalRoom.password.length == 0
            }"
          >
            <input
              matInput
              [type]="isMeetingPasswordHide ? 'password' : 'text'"
              class="display-none"
            />
            <input
              matInput
              [type]="isMeetingPasswordHide ? 'password' : 'text'"
              [(ngModel)]="personalRoom.password"
              [disabled]="!hasPassword"
              [placeholder]="'account.password' | translate"
              [required]="hasPassword && personalRoom.password.length == 0"
              minlength="1"
              maxlength="50"
              (input)="passwordWritten = true"
              autocomplete="new-testtestst"
              name="set-meeting-password"
              id="set-meeting-password"
              (capsLock)="capsLock = $event"
              (input)="passwordInput($event)"
            />
            <!-- (change)="change('pass')" -->

            <button
              class="toggle-pass bodytext2"
              type="button"
              mat-icon-button
              matSuffix
              (click)="toggleDisplayPassword($event, 'meetingPasswordHide')"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="isMeetingPasswordHide"
            >
              <mat-icon>{{
                isMeetingPasswordHide ? "visibility_off" : "visibility"
              }}</mat-icon>
            </button>
          </mat-form-field>
          <!-- <mat-error
            *ngIf="
              passwordWritten &&
              hasPassword &&
              personalRoom.password.length == 0
            "
          >
            {{ "validation.pass-required" | translate }}
          </mat-error> -->
          <small *ngIf="capsLock" class="caps-on">
            <mat-icon class="material-icons-outlined">info</mat-icon>
            {{ "validation.caps-lock" | translate }}</small
          >
        </div>
      </div>

      <!-- toggle waiting room -->
      <mat-slide-toggle
        color="primary"
        class="custom-slide-toggle"
        [(ngModel)]="personalRoom.isWaiting"
        (change)="waitingRoomChange($event)"
      >
        <!-- (change)="change()" -->
        <label class="select-label">{{
          "account.enable-waiting-room" | translate
        }}</label>
      </mat-slide-toggle>
      <!-- personal room title -->
      <div class="form-input room-title">
        <mat-label
          >{{ "setting.room-title" | translate }}
          <div class="tooltip">
            <mat-icon class="material-icons-outlined info"> info</mat-icon>
            <span
              class="tooltiptext"
              [ngStyle]="{
                visibility:
                  (!roomTitle.valid ||
                    roomTitle.value == '' ||
                    roomTitle.value == ' ') &&
                  showTip
                    ? 'visible'
                    : ''
              }"
            >
              <span class="custom-tooltip">
                {{ "tooltip.room-title" | translate }}
              </span>
              <br />
              <mat-icon
                class="close-icon"
                *ngIf="
                  !roomTitle.valid ||
                  roomTitle.value == ' ' ||
                  roomTitle.value == ''
                "
                >close</mat-icon
              >
              <mat-icon
                class="success-icon"
                *ngIf="
                  roomTitle.valid &&
                  roomTitle.value != ' ' &&
                  roomTitle.value != ''
                "
                >check</mat-icon
              >
              {{ "tooltip.room-title-info1" | translate }}
              <br />
              <mat-icon
                class="close-icon"
                *ngIf="
                  !roomTitle.valid ||
                  roomTitle.value == ' ' ||
                  roomTitle.value == ''
                "
                >close</mat-icon
              >
              <mat-icon
                class="success-icon"
                *ngIf="
                  roomTitle.valid &&
                  roomTitle.value != ' ' &&
                  roomTitle.value != ''
                "
                >check</mat-icon
              >
              {{ "tooltip.room-title-info2" | translate }}
            </span>
          </div>
        </mat-label>

        <mat-form-field
          class="bodytext-normal"
          block
          appearance="fill"
          color="primary"
          [ngClass]="{
            invalid: showTip && personalRoom.title.length == 0
          }"
        >
          <input
            type="text"
            name="roomTitle"
            #roomTitle="ngModel"
            [placeholder]="'setting.room-title' | translate"
            [(ngModel)]="personalRoom.title"
            matInput
            [pattern]="personalTitlePattern"
            minlength="1"
            maxlength="50"
            autocomplete="off"
            (input)="enableTooltip()"
          />
          <!-- (change)="roomTitle.errors ? '' : change()" -->

          <mat-error>
            {{ "setting.room-title-validation" | translate }}</mat-error
          >
        </mat-form-field>
      </div>
      <!-- personal room token -->
      <div class="form-input">
        <mat-label
          >{{ "setting.link-room-title" | translate }}
          <div class="tooltip">
            <mat-icon class="material-icons-outlined info"> info</mat-icon>
            <span
              class="tooltiptext"
              [ngStyle]="{
                visibility:
                  !tokenError.length ||
                  !tokenError.unique ||
                  !tokenError.english
                    ? 'visible'
                    : ''
              }"
            >
              <span class="custom-tooltip">
                {{ "tooltip.link-room-title" | translate }}
              </span>
              <br />
              <mat-icon class="close-icon" *ngIf="!tokenError.length"
                >close</mat-icon
              >
              <mat-icon class="success-icon" *ngIf="tokenError.length"
                >check</mat-icon
              >
              {{ "tooltip.link-room-info3" | translate }}
              <br />
              <mat-icon class="close-icon" *ngIf="!tokenError.english"
                >close</mat-icon
              >
              <mat-icon class="success-icon" *ngIf="tokenError.english"
                >check</mat-icon
              >
              {{ "tooltip.link-room-info1" | translate }}
              <br />
              <mat-icon class="close-icon" *ngIf="!tokenError.unique"
                >close</mat-icon
              >
              <mat-icon class="success-icon" *ngIf="tokenError.unique"
                >check</mat-icon
              >
              {{ "tooltip.link-room-info2" | translate }}
            </span>
          </div>
        </mat-label>

        <div
          class="meeting-link__wrapper"
          [ngClass]="{ dirLtr: _lang.direction == 'ltr' }"
        >
          <mat-form-field
            class="bodytext-normal token-field"
            block
            appearance="fill"
            color="primary"
            [ngClass]="{
              invalid: validateToken || roomToken.errors || emptyToken
            }"
          >
            <!-- (blur)="!roomToken.errors ? submitToken() : ''" -->
            <input
              type="text"
              name="roomToken"
              #roomToken="ngModel"
              [placeholder]="'setting.link-room-title' | translate"
              [(ngModel)]="personalRoom.token"
              matInput
              pattern="^[0-9a-zA-Z\b]{1,50}$"
              onblur="this.value=this.value.trim()"
              minlength="1"
              maxlength="50"
              (keyup)="emptyToken = false"
              autocomplete="off"
              (input)="checkPattern(personalRoom.token)"
            />
          </mat-form-field>

          <span class="meeting-link">
            {{ _hrefLocation.urlHost + "/meeting/" | slice : 0 : 20 }}...
          </span>
          <span class="copy-icon" [ngClass]="{ 'active-copy': copied == true }">
            <figure (click)="copyLink()">
              <img
                class="copy"
                src="../../../../assets/images/icons/copy-icon.svg"
                [alt]="'meeting-side.meeting-link' | translate"
              />
              <!-- <img
                class="hover"
                src="../../../../assets/images/icons/copy-hover-icon.svg"
                [alt]="'meeting-side.meeting-link' | translate"
              /> -->
              <img
                class="copied"
                src="../../../../assets/images/icons/copied-icon.svg"
                [alt]="'meeting-side.meeting-link' | translate"
              />
            </figure>
            <!--
            <mat-icon class="material-icons-outlined info" (click)="copyLink()"
              >copy_content</mat-icon
            > -->
          </span>
        </div>
        <!-- <small class="mat-error error" *ngIf="validateToken">
          {{ "setting.link-room-token-validation" | translate }}</small
        >
        <small class="mat-error error" *ngIf="emptyToken">
          {{ "setting.link-room-token-validation-empty" | translate }}</small
        >
        <small class="mat-error error" *ngIf="roomToken.errors">
          {{ "tooltip.link-room-info1" | translate }}
        </small> -->
      </div>
    </div>
    <div class="media" *ngIf="activeLink === 'media'">
      <div class="media-audio">
        <div class="media-options form-input">
          <mat-label>{{ "settings.mic" | translate }}</mat-label>

          <mat-form-field
            class="bodytext-normal select-option"
            color="primary"
            appearance="fill"
            [ngClass]="{ 'if-disabled': userMediaService?.isInputDisabled }"
          >
            <img
              src="../../../../assets/images/icons/permission-info.svg"
              alt=""
              *ngIf="userMediaService?.isInputDisabled"
            />
            <mat-select
              [value]="'0'"
              *ngIf="
                !userMediaService?.audioDevicesList?.length ||
                userMediaService?.isInputDisabled
              "
              [disabled]="userMediaService?.isInputDisabled"
            >
              <mat-option
                *ngIf="!userMediaService?.isInputDisabled"
                class="bodytext-normal"
                value="0"
                selected="true"
              >
                {{ "settings.default-input" | translate }}
              </mat-option>
              <mat-option
                *ngIf="userMediaService?.isInputDisabled"
                class="bodytext-normal"
                value="0"
                selected="true"
              >
                {{ "settings.input-disabled" | translate }}
              </mat-option>
            </mat-select>
            <!-- (selectionChange)="changed('audio')" -->
            <mat-select
              value="default-mic"
              [(ngModel)]="audio"
              [panelClass]="'dirRtlClass'"
              [disabled]="!userMediaService.audioStream"
              (click)="!userMediaService.audioStream ? checkPermission() : ''"
              (selectionChange)="permissionChanged($event, 'audio')"
              *ngIf="
                userMediaService?.audioDevicesList?.length &&
                !userMediaService?.isInputDisabled
              "
            >
              <ng-container
                *ngFor="let device of userMediaService.audioDevicesList"
              >
                <mat-option class="bodytext-normal" [(value)]="device.deviceId">
                  {{ device.label }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>

        <div
          class="audio-level-container form-input"
          [ngStyle]="{
            visibility: userMediaService.audioStream ? 'visible' : 'hidden'
          }"
        >
          <mat-label class="bodytext1">{{
            "settings.input-level" | translate
          }}</mat-label>
          <div class="audio-level">
            <ng-container *ngFor="let i of inputLevels">
              <span
                class="sound-unit"
                [ngClass]="{ on: inputLevel > i }"
              ></span>
            </ng-container>
          </div>
        </div>
        <div class="media-options form-input">
          <label>{{ "settings.speaker" | translate }}</label>

          <mat-form-field
            class="bodytext-normal select-option"
            color="primary"
            appearance="fill"
            [ngClass]="{ 'if-disabled': userMediaService?.isOuputDisabled }"
          >
            <img
              src="../../../../assets/images/icons/permission-info.svg"
              alt=""
              *ngIf="userMediaService?.isOuputDisabled"
            />
            <mat-select
              [value]="'0'"
              *ngIf="
                !userMediaService?.outputDevicesList?.length ||
                userMediaService?.isOuputDisabled
              "
              [disabled]="userMediaService?.isOuputDisabled"
            >
              <mat-option
                *ngIf="!userMediaService?.isOuputDisabled"
                class="bodytext-normal"
                value="0"
                selected="true"
              >
                {{ "settings.default-speaker" | translate }}
              </mat-option>
              <mat-option
                *ngIf="userMediaService?.isOuputDisabled"
                class="bodytext-normal"
                value="0"
                selected="true"
              >
                {{ "settings.speaker-disabled" | translate }}
              </mat-option>
            </mat-select>
            <!-- (selectionChange)="changed('output')" -->
            <mat-select
              value="default-mic"
              [(ngModel)]="output"
              [panelClass]="'dirRtlClass'"
              [disabled]="
                !userMediaService.audioStream ||
                !userMediaService?.outputDevicesList?.length
              "
              (click)="!userMediaService.audioStream ? checkPermission() : ''"
              (selectionChange)="permissionChanged($event, 'output')"
              *ngIf="
                userMediaService?.outputDevicesList?.length &&
                !userMediaService?.isOuputDisabled
              "
            >
              <ng-container
                *ngFor="let device of userMediaService.outputDevicesList"
              >
                <mat-option class="bodytext-normal" [(value)]="device.deviceId">
                  {{ device.label }}
                </mat-option>
              </ng-container>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="media-video form-input">
        <mat-label>{{ "settings.camera" | translate }}</mat-label>

        <mat-form-field
          class="bodytext-normal select-option"
          color="primary"
          appearance="fill"
          [ngClass]="{ 'if-disabled': userMediaService?.isVideoDisabled }"
        >
          <img
            src="../../../../assets/images/icons/permission-info.svg"
            alt=""
            *ngIf="userMediaService?.isVideoDisabled"
          />
          <mat-select
            [value]="'0'"
            *ngIf="
              !userMediaService?.videoDevicesList?.length ||
              userMediaService?.isVideoDisabled
            "
            [disabled]="userMediaService?.isVideoDisabled"
          >
            <mat-option
              *ngIf="!userMediaService?.isVideoDisabled"
              class="bodytext-normal"
              value="0"
              selected="true"
            >
              {{ "settings.default-video" | translate }}
            </mat-option>
            <mat-option
              *ngIf="userMediaService?.isVideoDisabled"
              class="bodytext-normal"
              value="0"
              selected="true"
            >
              {{ "settings.video-disabled" | translate }}
            </mat-option>
          </mat-select>
          <!-- (selectionChange)="changed('video')" -->
          <mat-select
            value="default-cam"
            [(ngModel)]="video"
            [panelClass]="'dirRtlClass'"
            [disabled]="!userMediaService.videoStream"
            (click)="!userMediaService.videoStream ? checkPermission() : ''"
            (selectionChange)="permissionChanged($event, 'video')"
            *ngIf="
              userMediaService?.videoDevicesList?.length &&
              !userMediaService?.isVideoDisabled
            "
          >
            <ng-container *ngFor="let cam of userMediaService.videoDevicesList">
              <mat-option
                *ngIf="cam.kind === 'videoinput'"
                [value]="cam.deviceId"
              >
                {{ cam.label }}
              </mat-option>
            </ng-container>
          </mat-select>
        </mat-form-field>

        <div class="video-preview">
          <div class="no-video" *ngIf="!userMediaService.videoStream">
            <mat-icon aria-hidden="false" aria-label="video off"
              >videocam_off</mat-icon
            >
            <div class="preview-text">
              <span>{{ "settings.private-preview" | translate }} </span>
              <span class="preview-note">{{
                "settings.no-one-but-you-can-see-it" | translate
              }}</span>
            </div>
          </div>

          <ng-container
            *ngIf="
              userMediaService.videoStream &&
              userMediaService.videoStream.active
            "
          >
            <video
              [srcObject]="userMediaService.videoStream"
              autoplay
              [muted]="true"
            ></video>

            <div class="preview-text">
              <span>{{ "settings.private-preview" | translate }} </span>
              <span class="preview-note">{{
                "settings.no-one-but-you-can-see-it" | translate
              }}</span>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
