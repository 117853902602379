export const getBrowserVersion = () => {
  var userAgent = navigator.userAgent,
    tem,
    matchTest =
      userAgent.match(
        /(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i
      ) || [];

  if (/trident/i.test(matchTest[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(userAgent) || [];

    return 'IE ' + (tem[1] || '');
  }

  if (matchTest[1] === 'Chrome') {
    tem = userAgent.match(/\b(OPR|Edge)\/(\d+)/);

    if (tem != null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
  }

  matchTest = matchTest[2]
    ? [matchTest[1], matchTest[2]]
    : [navigator.appName, navigator.appVersion, '-?'];

  if ((tem = userAgent.match(/version\/(\d+)/i)) != null)
    matchTest.splice(1, 1, tem[1]);

  //return matchTest.join(' ');
  return matchTest[1];
};

//here we will prepare name in arabic & english to take first & last name
export const prepareName = (name: string) => {
  //decalre var for first & last name
  let fullName = '';
  //let split name to check
  const splitedName = name.split(' ');
  //if name is longer than 2
  if (splitedName.length >= 3) {
    //lets check if first name is عبد to take second & first as one name but last name is not starting with عبد
    if (
      splitedName[0] === 'عبد' &&
      splitedName[splitedName.length - 2] !== 'عبد'
    ) {
      //lets get first name from first & second splited data
      fullName += `${splitedName[0]} ${splitedName[1]}`;
      if (splitedName[2] === 'عبد') {
        fullName += ` ${splitedName[2]} ${splitedName[3]}`;
      } else {
        fullName += ` ${splitedName[splitedName.length - 1]}`;
      }
      //lets check if first name is عبد to take second & first as one name but last name is starting with عبد
    } else if (
      splitedName[0] === 'عبد' &&
      splitedName[splitedName.length - 2] === 'عبد'
    ) {
      fullName += `${splitedName[0]} ${splitedName[1]}`;
      fullName += ` ${splitedName[splitedName.length - 2]} ${
        splitedName[splitedName.length - 1]
      }`;
      //if user is not starting with عبد
    } else {
      fullName += `${splitedName[0]}`;
      if (splitedName[splitedName.length - 2] === 'عبد') {
        fullName += ` ${splitedName[splitedName.length - 2]} ${
          splitedName[splitedName.length - 1]
        }`;
      } else {
        fullName += ` ${splitedName[splitedName.length - 1]}`;
      }
    }
  } else {
    fullName = name;
  }
  return fullName;
};

// Generate array of hours in format of "00:00","00:30","01:00",...
export const generateHoursArr = (lang: string = 'en'): string[] => {
  const HALF_HOUERS = 30;
  const QUARTER_HOURS = 15;
  const ranges = [];
  const date = new Date();
  let accpetedLang = lang == 'ar' ? 'ar-EG' : lang;

  for (let minutes = 0; minutes < 24 * 60; minutes = minutes + QUARTER_HOURS) {
    date.setHours(0);
    date.setMinutes(minutes);
    ranges.push(
      date.toLocaleTimeString(accpetedLang, {
        hour: 'numeric',
        minute: 'numeric',
      })
    );
  }

  return ranges;
};

export const validURL = (str: string) => {
  var pattern = new RegExp(
    '^(https?:\\/\\/)?' + // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
      '(\\#[-a-z\\d_]*)?$',
    'i'
  ); // fragment locator
  return !!pattern.test(str);
};

/**
 *
 * @param text the original text which contains templates
 * @param templates a collections of key-value pairs, where the key is template and the value is the substituted value
 * @example replaceTemplate('{{USER}} is {{STATUS}}',{USER:'john',  STATUS:'login'}) -> 'join is login'
 */
export const replaceTemplate = (
  text: string,
  templates: { [k: string]: string }
) => {
  return text.replace(/{{(\w*)}}/g, function (_, key) {
    return templates.hasOwnProperty(key) ? templates[key] : '';
  });
};
