import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import * as moment from 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { FormGroup, FormControl, FormBuilder, NgForm } from '@angular/forms';
import {
  MomentDateAdapter,
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
} from '@angular/material-moment-adapter';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import 'moment/locale/en-au';
import 'moment/locale/ar';
import { MeetingTemplate } from '@kortobaa-front/ng-kmeet-sdk';
import { LangService } from 'src/app/shared/services/lang.service';
import { MeetingsService } from 'src/app/meeting/services/meetings.service';
import { CountriesService } from 'src/app/shared/services/countries.service';
import { DataService } from 'src/app/shared/services/data.service';
import { generateHoursArr } from 'src/app/shared/helpers/customFunctions';
import {
  formatTime,
  paresArabicTime,
} from 'src/app/shared/helpers/convertDate';
import { TimeZone } from 'src/app/shared/models/interfaces';
import { MeetingURLService } from 'src/app/shared/services/meeting-url.service';
import { MatDialog } from '@angular/material/dialog';
import { RemoveMeetingDialogComponent } from 'src/app/meeting/remove-meeting-dialog/remove-meeting-dialog.component';
import { FormCanDeactivate } from 'src/app/shared/guard/form-can-deactivate';
import { ComponentCanDeactivate } from 'src/app/shared/guard/component-can-deactivate';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { UserService } from '@kortobaa-front/authentication';
export const MY_FORMATS = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'MMM Do ,YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};
@Component({
  selector: 'app-schedule-new-meeting',
  templateUrl: './schedule-new-meeting.component.html',
  styleUrls: ['./schedule-new-meeting.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'ar-AR' },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
// implements ComponentCanDeactivate to be able to use canDeactivate method the class to control canDeactivate guard
export class ScheduleNewMeetingComponent
  extends FormCanDeactivate
  implements OnInit, ComponentCanDeactivate
{
  @ViewChild('scheduleMeetingForm') public scheduleForm: NgForm;
  @ViewChild('scheduleMeetingForm') form: NgForm;

  loading = {
    schedule: false,
    settings: false,
  };

  startDatePicked: any;

  scheduleMeetingFormData: any = {
    meetingTitle: '',
    meetingDate: '',
    meetingEndDate: '', // TODO: Implement later
    meetingStartTime: '',
    meetingEndTime: '', //optional
    meetingTimezone: '',
    repeate: 'never', //never - never - every week - every 2 weeks - every month - every year
    personalRoom: true,
    meetingPassword: '', //optional
    muteMyMic: true,
    stopMyVideo: true,
    muteParticipants: true,
    recordMeeting: false,
    allowParticipantsToJoinBeforeHost: true,
    excludeLink: false,
    waitingRoom: true,
    inviationList: [],
    hour: '',
    min: '',
  };

  passwordError = false;
  passIncludeSpace = true;

  hasPassword = false;

  wrongPassCriteria = false;

  invitationsCtrl = new FormControl();
  separatorKeysCodes = [ENTER, COMMA];
  rulesForm: FormGroup;
  fb: FormBuilder = new FormBuilder();

  hide = true;

  filteredTimeZones: Observable<TimeZone[]>;
  timeZoneNames: TimeZone[] = [];

  filteredMeetingTemplates: Observable<MeetingTemplate[]>;
  meetingTemplates: MeetingTemplate[] = [];
  templateControl = new FormControl(null);

  templateName!: string;
  private _choosedTemplateName = '';

  //for edit old scheduled meeting data
  oldMeeting = null;

  // To assign to meetingStartTime and meetingEndTime
  hoursArr = generateHoursArr(this._translate.currentLang);
  public screenWidth: any = window.innerWidth;

  maxDate: Date = new Date();
  dateFrom: Date = this.maxDate;
  timeZoneSelect: any;

  timeCheck = false;

  editMode = false;

  // New UI

  minsArr = [0, 15, 30, 45];
  capsLock = false;

  disabledIndex = 0;

  constructor(
    private _bottomSheet: MatBottomSheet,
    private _meetingsService: MeetingsService,
    public _dataService: DataService,
    private _translate: TranslateService,
    private _router: Router,
    private _adapter: DateAdapter<any>,
    private _CountriesService: CountriesService,
    public _lang: LangService,
    private _meetingURL: MeetingURLService,
    public dialog: MatDialog,
    public kortobaaPkgUserService: UserService,
    @Inject(MAT_DATE_LOCALE) private _locale: string
  ) {
    super();

    if (this._router.getCurrentNavigation().extras.state) {
      this.oldMeeting =
        this._router.getCurrentNavigation().extras.state.meeting;

      this.scheduleMeetingFormData = {
        meetingTitle: this.oldMeeting.title,
        meetingDate: this.oldMeeting.startDate,
        meetingStartTime: formatTime(
          this.oldMeeting.startDate,
          this._translate.currentLang
        ),
        meetingEndTime: formatTime(
          this.oldMeeting.expectedEndDate,
          this._translate.currentLang
        ),
        meetingEndDate: this.oldMeeting.expectedEndDate, // TODO: Implement later
        meetingTimezone: this.oldMeeting.timeZone,
        repeate: 'never', //never - never - every week - every 2 weeks - every month - every year
        personalRoom: true,
        meetingPassword: this.oldMeeting.password, //optional
        muteMyMic: true,
        stopMyVideo: true,
        muteParticipants: true,
        recordMeeting: false,
        allowParticipantsToJoinBeforeHost: this.oldMeeting.joinBeforeHost,
        excludeLink: this.oldMeeting.excludeLink,
        waitingRoom: this.oldMeeting.isWaiting,
        inviationList: [],
      };
      // Transform time depending on language
      this._adjustMeetingStartTime();
      this._adjustMeetingEndTime();

      if (this.oldMeeting.inviteEmails) {
        for (let email of this.oldMeeting.inviteEmails) {
          this.scheduleMeetingFormData.inviationList.push({
            value: email,
            invalid: false,
          });
        }
      }
    } else {
      //this.scheduleMeetingFormData.meetingTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    }
  }

  ngOnInit() {
    this._initTimeZone();
    if (this.oldMeeting) {
      if (this.oldMeeting.password) {
        this.hasPassword = true;
      }

      const startTime = paresArabicTime(
        this.scheduleMeetingFormData.meetingStartTime,
        'en'
      );
      const endTime = paresArabicTime(
        this.scheduleMeetingFormData.meetingEndTime,
        'en'
      );
      const startAfter = this.transformTimeToNumber(startTime);
      const endAfter = this.transformTimeToNumber(endTime);
      this.scheduleMeetingFormData.hour = Math.floor(endAfter - startAfter);
      const mins = Math.round(((endAfter - startAfter) % 1) * 100);

      if (this.minsArr.includes(mins)) {
        this.scheduleMeetingFormData.min = mins;
      } else {
        this.scheduleMeetingFormData.min = mins - 40;
      }
    }

    this.rulesForm = this.fb.group({
      emails: this.fb.array([], [this._validateArrayNotEmpty]),
    });

    // Change data picker language to current language
    this.adjustDataPickerLang();

    this.onLangChangeHandler();

    this._initMeetingTemplate();
  }

  passwordInput(event: any) {
    if (event.target.value.length > 0) {
      this.passwordError = false;
    } else {
      this.passwordError = true;
    }

    if (event.target.value.includes(' ')) {
      this.passIncludeSpace = false;
    } else {
      this.passIncludeSpace = true;
    }
  }

  changeToggle() {
    this.hasPassword = !this.hasPassword;
    if (!this.hasPassword) {
      this.passwordError = false;
    }
  }

  private async _initMeetingTemplate() {
    this.meetingTemplates = await this._meetingsService
      .getAllMeetingTemplate()
      .toPromise();

    this.filteredMeetingTemplates = this.templateControl.valueChanges.pipe(
      startWith(''),
      map((value) => this.filter(value || ''))
    );
  }

  async setMeetingTemplate(templateName: string) {
    this.templateName = templateName;
    const template = this.meetingTemplates.find(
      (template) => templateName == template.templateName
    );

    if (!template) return;
    await this._setTimeZoneList(template);

    this._choosedTemplateName = template?.templateName;

    // this.scheduleMeetingFormData.meetingDate = template.startDate;
    // this.scheduleMeetingFormData.meetingEndDate = template.expectedEndDate;

    this.scheduleMeetingFormData.meetingPassword = template.password;
    // this.scheduleMeetingFormData.meetingTimezone = template.timeZone;
    this.scheduleMeetingFormData.meetingTitle = template.title;

    // Workaround due to backend returns (0,1) not boolen
    this.scheduleMeetingFormData.excludeLink = Boolean(template.excludeLink);
    this.scheduleMeetingFormData.waitingRoom = Boolean(template.isWaiting);
    this.scheduleMeetingFormData.allowParticipantsToJoinBeforeHost = Boolean(
      template.joinBeforeHost
    );

    // Get the time part from date
    // this.scheduleMeetingFormData.meetingStartTime = formatTime(
    //   template.startDate,
    //   this._translate.currentLang
    // );

    // this.scheduleMeetingFormData.meetingEndTime = formatTime(
    //   template.expectedEndDate,
    //   this._translate.currentLang
    // );

    // Transform time depending on language
    // this._adjustMeetingStartTime();
    // this._adjustMeetingEndTime();

    // Reset inviation List before add inviations from template
    this.scheduleMeetingFormData.inviationList = [];
    for (let email of template.inviteEmails) {
      this.scheduleMeetingFormData.inviationList.push({
        value: email,
        invalid: false,
      });
    }
  }

  // filter assignees
  filter(value: any): any[] {
    const filterValue = value.toLowerCase();
    return this.meetingTemplates.filter((option) =>
      option.templateName.toLowerCase().includes(filterValue)
    );
  }

  private async _setTimeZoneList(template?: any) {
    this.timeZoneNames = await this._CountriesService
      .getTimeZones()
      .toPromise();

    if ((this.oldMeeting || template) && this.timeZoneNames.length > 0) {
      let oldTimeZone = this.timeZoneNames?.find((timeZone) =>
        template
          ? template.timeZone == timeZone.tzCode
          : this.oldMeeting.timeZone == timeZone.tzCode
      );

      this.scheduleMeetingFormData.meetingTimezone = oldTimeZone.label;
      this.timeZoneSelect = oldTimeZone;
    } else {
      //get current ueser timezone
      const userTz =
        this.kortobaaPkgUserService?.currentUser?.additionalData?.timezone;
      let oldTimeZone = this.timeZoneNames?.find((timeZone) =>
        userTz
          ? userTz == timeZone.tzCode
          : Intl.DateTimeFormat().resolvedOptions().timeZone == timeZone.tzCode
      );
      this.scheduleMeetingFormData.meetingTimezone = oldTimeZone.label;
      this.timeZoneSelect = oldTimeZone;
    }
  }

  private async _initTimeZone() {
    await this._setTimeZoneList();
    // Delay assignment because form controls will not be registered on init of the component
    setTimeout(() => {
      this.filteredTimeZones =
        this.scheduleForm.controls.meetingTimezone.valueChanges.pipe(
          startWith(''),
          map((value) => this._filterTimeZone(value || ''))
        );
    }, 0);
  }

  private _filterTimeZone(value: string): TimeZone[] {
    const filteredValue = value.toLowerCase();

    return this.timeZoneNames.filter((timeZone) =>
      timeZone.label.toLowerCase().includes(filteredValue)
    );
  }

  createInvitationList() {
    let InvitationList = [];

    if (this.scheduleMeetingFormData.inviationList.length) {
      this.scheduleMeetingFormData.inviationList.forEach((email) => {
        if (!email.invalid) {
          InvitationList.push(email.value);
        }
      });
    }

    return InvitationList;
  }

  async saveCurrentSettings() {
    this._checkMeetingTimeZone();
    // if (!this.hasPassword) this.scheduleMeetingFormData.meetingPassword = '';

    let message: string;
    try {
      // this._checkMeetingTimeZone();
      const startDate = this._formatMeetingDate(
        this.scheduleMeetingFormData.meetingDate,
        this.scheduleMeetingFormData.meetingStartTime
      );
      const expectedEndDate = this._formatMeetingDate(
        this.scheduleMeetingFormData.meetingEndDate,
        this.scheduleMeetingFormData.meetingEndTime
      );
      this.loading.settings = true;

      const templateName =
        this.templateName.length > 0 ? this.templateName : undefined;

      // const templateName = await this._bottomSheet
      // .open(TemplateBottomSheetComponent, {
      //   data: this._choosedTemplateName,
      //   direction: this._lang.direction,
      // })
      // .afterDismissed()
      // .toPromise();

      if (!this.templateName) return (this.loading.settings = false);

      const scheduleForm = {
        templateName,
        title: this.scheduleMeetingFormData.meetingTitle,
        password: this.scheduleMeetingFormData.meetingPassword,
        // Workaround due to backend does not accept boolen but (0,1)
        isWaiting: Number(this.scheduleMeetingFormData.waitingRoom),
        joinBeforeHost: Number(
          this.scheduleMeetingFormData.allowParticipantsToJoinBeforeHost
        ),
        excludeLink: Number(this.scheduleMeetingFormData.excludeLink),
        startDate,
        expectedEndDate,
        inviteEmails: this.createInvitationList(),
        timeZone: this.timeZoneSelect.tzCode,
      };

      const template = this.meetingTemplates.find(
        (template) => templateName == template.templateName
      );

      // Update template if exist
      if (template) {
        await this._meetingsService
          .updateMeetingTemplate(template.id, scheduleForm)
          .toPromise();
        message = this._translate.instant(
          'account.update-meeting-template-successfully'
        );
      } else {
        await this._meetingsService
          .saveMeetingTemplate(scheduleForm)
          .toPromise();
        message = this._translate.instant(
          'account.save-meeting-template-successfully'
        );
      }

      // Update meeting template list UI
      this._initMeetingTemplate();
      this._dataService.notification(message);
      this.loading.settings = false;
      // setTimeout(() => {
      //   this.dialog.closeAll();
      // }, 1);

      this.editMode = true;

      this._router.navigate(['/home']);
    } catch (error) {
      console.log(error);

      if (error.status == 401) {
        message = this._translate.instant('errors.unauthorized');
      } else if (error.status == 400 || error.status == 422) {
        message = this._translate.instant(error.error.error.message);
      } else {
        message = this._translate.instant(error.message);
      }

      this._dataService.notification(message, true);
    }
    this.loading.settings = false;
    this._router.navigate(['/home']);
  }

  private _formatMeetingDate(date, time) {
    let formatedDate = moment(Date.parse(date))
      .locale('en')
      .format('YYYY-MM-DD');

    let formatedTime = this.toEnglishDigits(
      moment(time, 'hh:mm A', this._translate.currentLang).format('HH:mm:ss')
    );
    // safari doesn't support ("date time") format
    // instead it requires T before time to read it
    // ("dateTtime") and chrome and firefox also can read it
    let dateTimeZone = moment
      .tz(`${formatedDate}T${formatedTime}`, this.timeZoneSelect.tzCode)
      .locale('en')
      .format();

    return dateTimeZone;
  }

  // select end date automatically
  autoSelectEndDate(event: MatDatepickerInputEvent<Date>) {
    // disable passed time
    const selectedDate = new Date(event.value);
    const currentDate = new Date();
    const date = new Date().toLocaleTimeString([], {
      hour: 'numeric',
      minute: '2-digit',
    });

    if (currentDate.toLocaleDateString() == selectedDate.toLocaleDateString()) {
      const index = generateHoursArr().findIndex((e) => {
        if (this.transformTimeToNumber(e) > this.transformTimeToNumber(date)) {
          return e;
        }
      });
      this.disabledIndex = index;
      this.scheduleMeetingFormData.meetingStartTime = this.hoursArr[index];
    } else {
      this.disabledIndex = 0;
      this.scheduleMeetingFormData.meetingStartTime = this.hoursArr[0];
    }
    this.checkTimeValidity();

    this.startDatePicked = new Date(event.value.toISOString());

    if (
      this.scheduleMeetingFormData.hour == '' &&
      this.scheduleMeetingFormData.min == ''
    ) {
      this.scheduleMeetingFormData.hour = 1;
      this.scheduleMeetingFormData.min = 0;
    }

    this.scheduleMeetingFormData.meetingEndDate = moment(this.startDatePicked);
    if (
      this.scheduleMeetingFormData.meetingStartTime &&
      this.scheduleMeetingFormData.meetingEndTime
    ) {
      this.checkTimeValidity();
    }
  }

  // check time validity
  checkTimeValidity(fromDate = false) {
    let startTime = '';
    let endTime = '';
    let startAfter: number;
    let endAfter: number;

    if (!endTime && !fromDate) {
      const startTimeIndex = this.checkStartingTime();
      this.scheduleMeetingFormData.meetingEndTime = this.hoursArr[
        startTimeIndex + 4
      ]
        ? this.hoursArr[startTimeIndex + 4]
        : this.hoursArr[startTimeIndex - this.hoursArr.length + 4];
    }

    if (this._translate.currentLang == 'ar') {
      startTime = paresArabicTime(
        this.scheduleMeetingFormData.meetingStartTime,
        'en'
      );
      endTime = paresArabicTime(
        this.scheduleMeetingFormData.meetingEndTime,
        'en'
      );
    } else {
      startTime = this.scheduleMeetingFormData.meetingStartTime;
      endTime = this.scheduleMeetingFormData.meetingEndTime;
    }
    startAfter = this.transformTimeToNumber(startTime);
    endAfter = this.transformTimeToNumber(endTime);

    // if the user picked start time it will automatically select the end time to 1 hour after

    // this only happen if the user picked start time 11pm or more
    // and shall pick the next day
    // endAfter - startAfter = -23 only if user picked +11PM
    // to disable this change just set fromDate to true when call the method

    if (
      endAfter - startAfter == -23 &&
      this.scheduleMeetingFormData.meetingEndDate &&
      !fromDate
    ) {
      let nextDay = (this.scheduleMeetingFormData.meetingEndDate = moment(
        this.startDatePicked
      )).add(1, 'days');
      this.scheduleMeetingFormData.meetingEndDate = nextDay;
    }

    // check if user picked right start and end time
    if (startTime && endTime) {
      if (
        endAfter - startAfter < 0 &&
        new Date(this.scheduleMeetingFormData.meetingEndDate.toString()) <=
          new Date(this.scheduleMeetingFormData.meetingDate.toString())
      ) {
        this.timeCheck = true;
      } else {
        this.timeCheck = false;
      }
    }

    // if (this.scheduleMeetingFormData.meetingDate && startTime && endTime) {
    //   this.scheduleMeetingFormData.hour = 1;
    //   this.scheduleMeetingFormData.min = 0;
    // }
  }

  ConvertToArabicNumbers(num: any) {
    const arabicNumbers =
      '\u0660\u0661\u0662\u0663\u0664\u0665\u0666\u0667\u0668\u0669';
    return new String(num).replace(/[0123456789]/g, (d) => {
      return arabicNumbers[d];
    });
  }

  // transform time to number for calculations

  transformTimeToNumber(date: string) {
    let timeSet = date.slice(-2);
    let fN = +date.split(':')[0];
    let sN = +date.split(':')[1].slice(0, 2);
    let time = fN + sN / 100;
    if (timeSet == 'AM' && time >= 12 && time < 13) {
      time = time - 12;
    }
    if (timeSet == 'PM' && time < 12) {
      time = time + 12;
    }
    return time;
  }

  checkStartingTime() {
    let hourIndex = this.hoursArr.findIndex((e) => {
      return e == this.scheduleMeetingFormData.meetingStartTime;
    });
    return hourIndex;
  }

  private _checkMeetingDate(date: string) {
    const meetingDate = new Date(date).toLocaleDateString();
    const currentDate = new Date().toLocaleDateString();
    const meetingTimeInMilliseconds = new Date(date).getTime();
    const currentTimeInMilliseconds = new Date().getTime();
    let errorMessage: string;

    // If the meeting date is past
    if (Date.parse(meetingDate) < Date.parse(currentDate)) {
      errorMessage = this._translate.instant('errors.invalid-date');
      throw new Error(errorMessage);
    }
    // If the meeting date is today but meeting time is past
    else if (
      meetingDate == currentDate &&
      meetingTimeInMilliseconds <= currentTimeInMilliseconds
    ) {
      errorMessage = this._translate.instant('errors.invalid-time');
      throw new Error(errorMessage);
    }
  }
  private _checkMeetingTimeZone() {
    this.timeZoneSelect = this.timeZoneNames.find(
      (timeZone) =>
        this.scheduleMeetingFormData.meetingTimezone == timeZone.label
    );

    if (!this.timeZoneSelect) {
      this._dataService.notification(
        this._translate.instant('errors.invalid-timezone'),
        true
      );
      throw new Error(this._translate.instant('errors.invalid-timezone'));
    }
  }

  adjustDataPickerLang() {
    this._locale = this._translate.currentLang;
    this._adapter.setLocale(this._locale);
  }

  onLangChangeHandler() {
    this._translate.onLangChange.subscribe(() => {
      this._locale = this._translate.currentLang;
      this._adapter.setLocale(this._locale);
      this.hoursArr = generateHoursArr(this._translate.currentLang);

      this._adjustMeetingStartTime();
      this._adjustMeetingEndTime();
    });
  }

  private _adjustMeetingStartTime() {
    if (this.scheduleMeetingFormData.meetingStartTime) {
      let newStartTime = paresArabicTime(
        this.scheduleMeetingFormData.meetingStartTime,
        this._translate.currentLang
      );

      // Transform time from 0x:xx to x:xx to match time list values
      if (newStartTime.startsWith('0') || newStartTime.startsWith('٠')) {
        newStartTime = newStartTime.slice(1);
      }

      this.scheduleMeetingFormData.meetingStartTime = newStartTime;
    }
  }

  private _adjustMeetingEndTime() {
    if (this.scheduleMeetingFormData.meetingEndTime) {
      let newEndTime = paresArabicTime(
        this.scheduleMeetingFormData.meetingEndTime,
        this._translate.currentLang
      );

      // Transform time from 0x:xx to x:xx to match time list values
      if (newEndTime.startsWith('0') || newEndTime.startsWith('٠')) {
        newEndTime = newEndTime.slice(1);
      }

      this.scheduleMeetingFormData.meetingEndTime = newEndTime;
    }
  }

  setExpectedDate(startDate) {
    let dateTime = startDate.split('T');
    const time = dateTime[1].split(':');

    const hours = time[0];
    const mins = time[1];

    // -1 because we was increasing expected date by 1 for the old logic
    let newHours: any = +hours + this.scheduleMeetingFormData.hour - 1;
    let newMins = +mins + this.scheduleMeetingFormData.min;

    // set mins to fit string form

    if (newMins == 60) {
      newHours++;
      newMins = '00';
    }
    if (newMins > 60) {
      newMins = newMins - 60;
      newHours++;
    }

    if (newMins == 0) {
      newMins = '00';
    }

    // set hours to fit string form

    if (newHours > 23) {
      newHours = newHours - 24;
    }
    if (newHours < 10) {
      newHours = '0' + newHours;
    }

    // set time to the desired form
    let expected = newHours + ':' + newMins + ':' + time[2] + ':' + time[3];

    // replace old expected to new one
    dateTime[1] = expected;

    const finalTime = dateTime.join('T');

    return finalTime;
  }

  async scheduleMeeting() {
    this._checkMeetingTimeZone();

    // if (!this.scheduleMeetingFormData.excludeLink)
    //   this.scheduleMeetingFormData.meetingPassword = '';

    this.loading.schedule = true;
    const startDate = this._formatMeetingDate(
      this.scheduleMeetingFormData.meetingDate,
      this.scheduleMeetingFormData.meetingStartTime
    );
    const expectedEndDate = this._formatMeetingDate(
      this.scheduleMeetingFormData.meetingEndDate,
      this.scheduleMeetingFormData.meetingEndTime
    );

    const newExpected = this.setExpectedDate(expectedEndDate);

    let message: string;

    try {
      this._checkMeetingDate(startDate);
      // this._checkMeetingTimeZone();

      const scheduleForm = {
        title: this.scheduleMeetingFormData.meetingTitle,
        isWaiting: this.scheduleMeetingFormData.waitingRoom,
        joinBeforeHost:
          this.scheduleMeetingFormData.allowParticipantsToJoinBeforeHost,
        password: this.scheduleMeetingFormData.meetingPassword,
        excludeLink: this.scheduleMeetingFormData.excludeLink,
        startDate,
        expectedEndDate: newExpected,
        inviteEmails: this.createInvitationList(),
        timeZone: this.timeZoneSelect.tzCode,
      };

      console.log(scheduleForm);

      await this._meetingsService.generateMeeting(scheduleForm).toPromise();
      message = this._translate.instant(
        'account.meeting-scheduled-successfully'
      );
      this._dataService.notification(message);
      this.scheduleForm.resetForm();
      this.loading.schedule = false;
      this._router.navigate(['/home']);
      this.editMode = true;

      // this._navigateToScheduledMeeting();
    } catch (error) {
      this.loading.schedule = false;
      console.log(error);

      if (error.status == 401) {
        message = this._translate.instant('errors.unauthorized');
      } else if (error.status == 400 || error.status == 422) {
        message = this._translate.instant(error.error.error.message);
      } else {
        message = this._translate.instant(error.message);
      }

      this._dataService.notification(message, true);
    }
  }

  private _validateArrayNotEmpty(c: FormControl) {
    if (c.value && c.value.length === 0) {
      return {
        validateArrayNotEmpty: { valid: false },
      };
    }
    return null;
  }

  private _validateEmail(email) {
    var re =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  private _navigateToScheduledMeeting() {
    this._router.navigate(['/profile'], {
      state: { activeContent: 'scheduledMeeting' },
    });
  }
  addEmail(event): void {
    const saperateEmails = event.value.split(',');
    if (saperateEmails.hasOwnProperty(1) && saperateEmails[1]) {
      saperateEmails.forEach((each) => {
        const element = each.trim();
        if (element) {
          if (this._validateEmail(element)) {
            this.scheduleMeetingFormData.inviationList.push({
              value: element,
              invalid: false,
            });
            this.rulesForm.controls['emails'].setErrors({
              incorrectEmail: false,
            });
          } else {
            // this.scheduleMeetingFormData.inviationList.push({
            //   value: element,
            //   invalid: true,
            // });
            this.rulesForm.controls['emails'].setErrors({
              incorrectEmail: true,
            });
          }
        }
      });
    } else {
      const userEmail = event.value.trim();
      if (userEmail) {
        if (this._validateEmail(userEmail)) {
          this.scheduleMeetingFormData.inviationList.push({
            value: userEmail,
            invalid: false,
          });
          this.rulesForm.controls['emails'].setErrors({
            incorrectEmail: false,
          });
        } else {
          // this.scheduleMeetingFormData.inviationList.push({
          //   value: userEmail,
          //   invalid: true,
          // });
          this.rulesForm.controls['emails'].setErrors({ incorrectEmail: true });
        }
      }
    }
    if (event.input) {
      event.input.value = '';
    }
    this.scheduleMeetingFormData.inviationList = [
      ...new Map(
        this.scheduleMeetingFormData.inviationList.map((item) => [
          item['value'],
          item,
        ])
      ).values(),
    ];
    // this.found = this.scheduleMeetingFormData.inviationList.every(el => el.invalid == true);
    // console.log(this.found);
  }

  removeEmail(data: any): void {
    if (this.scheduleMeetingFormData.inviationList.indexOf(data) >= 0) {
      this.scheduleMeetingFormData.inviationList.splice(
        this.scheduleMeetingFormData.inviationList.indexOf(data),
        1
      );
    }

    if (!this.scheduleMeetingFormData.inviationList.length) {
      this.rulesForm.controls['emails'].setErrors({ incorrectEmail: false });
    } else {
      this.scheduleMeetingFormData.inviationList.forEach((invite) => {
        if (!invite.invalid) {
          this.rulesForm.controls['emails'].setErrors({
            incorrectEmail: false,
          });
        }
      });
    }
  }

  //cancel editing old meeting
  cancelEditMeeting() {
    // this._navigateToScheduledMeeting();
    this._router.navigate(['/home']);
  }

  //edit old scheduled meeting
  async editScheduledMeeting() {
    this._checkMeetingTimeZone();

    // if (!this.scheduleMeetingFormData.excludeLink)
    //   this.scheduleMeetingFormData.meetingPassword = '';

    this.loading.schedule = true;

    const myMomentObject = moment(
      this.scheduleMeetingFormData.meetingDate,
      'YYYY-MM-DD'
    );

    // send this fake object to the Date method and it requires only value of date event picker
    const fakeDatePickerObject: any = {
      value: myMomentObject,
    };

    this.autoSelectEndDate(fakeDatePickerObject);

    const startDate = this._formatMeetingDate(
      this.scheduleMeetingFormData.meetingDate,
      this.scheduleMeetingFormData.meetingStartTime
    );
    const expectedEndDate = this._formatMeetingDate(
      this.scheduleMeetingFormData.meetingEndDate,
      this.scheduleMeetingFormData.meetingEndTime
    );

    const newExpected = this.setExpectedDate(expectedEndDate);

    let message: string;

    try {
      this._checkMeetingDate(startDate);
      // this._checkMeetingTimeZone();

      const editScheduleForm = {
        title: this.scheduleMeetingFormData.meetingTitle,
        isWaiting: Boolean(Number(this.scheduleMeetingFormData.waitingRoom)),
        joinBeforeHost: Boolean(
          Number(this.scheduleMeetingFormData.allowParticipantsToJoinBeforeHost)
        ),
        password: this.scheduleMeetingFormData.meetingPassword,
        excludeLink: Boolean(Number(this.scheduleMeetingFormData.excludeLink)),
        startDate,
        expectedEndDate: newExpected,
        inviteEmails: this.createInvitationList(),
        timeZone: this.timeZoneSelect.tzCode,
      };
      await this._meetingsService
        .updateScheduleMeeting(this.oldMeeting.id, editScheduleForm)
        .toPromise();

      message = this._translate.instant(
        'account.scheduled-meeting-updated-successfully'
      );
      this._dataService.notification(message);

      this.loading.schedule = false;

      // return true to canDeactivate guard to route to homepage without showing check not save dialog
      this.editMode = true;
      this._router.navigate(['/home']);

      // setTimeout(() => {
      //   this.dialog.closeAll();
      // }, 1);

      // this._navigateToScheduledMeeting();
    } catch (error) {
      this.loading.schedule = false;
      console.log(error);

      if (error.status == 401) {
        message = this._translate.instant('errors.unauthorized');
      } else if (error.status == 400 || error.status == 422) {
        message = this._translate.instant(error.error.error.message);
      } else {
        message = this._translate.instant(error.message);
      }
      this._dataService.notification(message, true);
    }
  }

  // from ComponentCanDeactivate and if return true, the check if not save dialog will not open and route

  canDeactivate() {
    return this.editMode;
  }

  addTemplate(value: string) {
    this.templateName = value;
    if (value.length > 0) this.setMeetingTemplate(value);
  }

  /// remove meeting dialog
  removeMeetingDialog() {
    this.dialog.open(RemoveMeetingDialogComponent, {
      width: '26rem',
      direction: this._lang.direction,
      data: { meeting: this.oldMeeting },
    });
    this.editMode = true;
  }

  copyMeetingUrl(meetingToken: string) {
    this._meetingURL.copyMeetingUrl(meetingToken);
  }

  startScheduledMeetingNow(token, hasPass, pass, excludeLink) {
    this._router.navigate(
      ['meeting/' + token],
      hasPass && !excludeLink ? { queryParams: { p: pass } } : {}
    );
  }

  // parse arabic number to english
  toEnglishDigits(str) {
    // convert persian digits [۰۱۲۳۴۵۶۷۸۹]
    var e = '۰'.charCodeAt(0);
    str = str.replace(/[۰-۹]/g, function (t) {
      return t.charCodeAt(0) - e;
    });

    // convert arabic indic digits [٠١٢٣٤٥٦٧٨٩]
    e = '٠'.charCodeAt(0);
    str = str.replace(/[٠-٩]/g, function (t) {
      return t.charCodeAt(0) - e;
    });
    return str;
  }

  // back to home
  backToHome() {
    if (this.scheduleForm.touched) {
      this.editMode = false;
    } else {
      this.editMode = true;
    }
    this._router.navigateByUrl('home');
  }
}
