import { Directive, Input, OnInit, OnDestroy } from '@angular/core';
import {
  Validator,
  NG_VALIDATORS,
  NgModel,
  AbstractControl,
} from '@angular/forms';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[comparePassword]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: CompareDirective, multi: true },
  ],
})
export class CompareDirective implements Validator, OnInit, OnDestroy {
  @Input() comparePassword: NgModel;
  @Input() name: string;
  private _sub: Subscription;

  ngOnInit() {
    // If user change the password input after confirm password validate again
    this._sub = this.comparePassword.valueChanges.subscribe(() => {
      const confirmInput = this.comparePassword.control.root.get(this.name);
      if (confirmInput) {
        this.validate(confirmInput);
      }
    });
  }

  validate(confirmInput: AbstractControl): { [key: string]: any } {
    const passInput = confirmInput.root.get(this.comparePassword.name);

    if (
      (passInput && confirmInput.value !== passInput.value) ||
      confirmInput.value == ''
    ) {
      // Set the error manually if the password input changes
      confirmInput.setErrors({ compare: true });
      // Set the error automaticlly if the confirm password input changes
      return { compare: true };
    }

    if (passInput && confirmInput.value === passInput.value) {
      // Reset the error
      confirmInput.setErrors(null);
    }
  }

  ngOnDestroy() {
    this._sub.unsubscribe();
  }
}
