import { Injectable, EventEmitter } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';

import { DataService } from 'src/app/shared/services/data.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  event = new EventEmitter<any>();

  constructor(private _router: Router, private _dataService: DataService) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    //return true;
    if (!this._dataService.getUserData()) {
      this._router.navigate(['/login']);
      return false;
    } else {
      return true;
    }
  }
}
