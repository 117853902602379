export function formatDate(date: Date) {
  let formatedDate = new Date(date).toLocaleDateString([], {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
  });

  return formatedDate;
}

export function formatTime(date: Date | string, currentLang: string) {
  const locales = currentLang == 'ar' ? 'ar-EG' : 'en-US';

  let formatedTime = new Date(date).toLocaleTimeString(locales, {
    hour: '2-digit',
    minute: '2-digit',
    // timeZone: 'UTC',
  });
  return formatedTime;
}

export function paresArabicTime(time: string, updatedLang: string) {
  const arabicNumbers = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩'];

  for (let enNum = 0; enNum < 10; enNum++) {
    if (updatedLang == 'en')
      // Reaplace all arabic number with corresponding english
      time = time.replace(RegExp(arabicNumbers[enNum], 'g'), enNum.toString());
    if (updatedLang == 'ar')
      // Reaplace all english number with corresponding arabic
      time = time.replace(RegExp(enNum.toString(), 'g'), arabicNumbers[enNum]);
  }

  if (updatedLang == 'en') {
    time = time.replace('ص', 'AM');
    time = time.replace('م', 'PM');
  }
  if (updatedLang == 'ar') {
    time = time.replace('AM', 'ص');
    time = time.replace('PM', 'م');
  }

  return time;
}
