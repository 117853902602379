import { Pipe, PipeTransform } from '@angular/core';

import { prepareName } from 'src/app/shared/helpers/customFunctions';

@Pipe({
  name: 'splitName',
})
export class SplitNamePipe implements PipeTransform {
  transform(value: string) {
    return prepareName(value);
  }
}
