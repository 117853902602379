<!-- Start ,labtop and desktop view -->
<section class="nav-web" *ngIf="screenWidth > 960">
  <section class="nav-container" fxHide.lt-md *ngIf="!dataService.isMeeting">
    <nav class="main-nav">
      <ul>
        <li>
          <a
            [ngClass]="{
              active: activeRoute == '/home' || activeRoute == '/'
            }"
            routerLink="/home"
          >
            <figure>
              <img
                [src]="
                  activeRoute == '/home' || activeRoute == '/'
                    ? '../../../../assets/images/nav/home_active.png'
                    : '../../../../assets/images/nav/home.png'
                "
                [alt]="'navbar.home' | translate"
              />
            </figure>
            {{ "navbar.home" | translate }}
          </a>
        </li>

        <li *ngIf="dataService.getUserData()">
          <a
            [ngClass]="{
              active: activeRoute == '/profile' || activeRoute == '/login'
            }"
            [routerLink]="dataService.getUserData() ? '/profile' : '/login'"
          >
            <figure>
              <img
                [src]="
                  activeRoute == '/profile' || activeRoute == '/login'
                    ? '../../../../assets/images/nav/person_active.png'
                    : '../../../../assets/images/nav/person.png'
                "
                [alt]="'navbar.my-account' | translate"
              />
            </figure>

            {{ "navbar.my-account" | translate }}</a
          >
        </li>

        <li>
          <a
            [ngClass]="{
              active: activeRoute == '/setting'
            }"
            [routerLink]="'/setting'"
          >
            <figure>
              <img
                [src]="
                  activeRoute == '/setting'
                    ? '../../../../assets/images/nav/settings_active.png'
                    : '../../../../assets/images/nav/settings.png'
                "
                [alt]="'navbar.setting' | translate"
              />
            </figure>

            {{ "navbar.setting" | translate }}</a
          >
        </li>

        <!-- <li *ngIf="!dataService.getUserData()">
          <a
            [ngClass]="{ active: activeRoute == '/pages/about' }"
            [routerLink]="'/pages/about'"
          >
            <figure>
              <img
                [src]="
                  activeRoute == '/pages/about'
                    ? '../../../../assets/images/nav/help_active.png'
                    : '../../../../assets/images/nav/help.png'
                "
                [alt]="'navbar.about' | translate"
              />
            </figure>
            {{ "navbar.about" | translate }}
          </a>
        </li> -->

        <li class="privacy-menu">
          <a
            [ngClass]="{
              active: activeRoute?.includes('/pages/') || menu.menuOpen
            }"
            [matMenuTriggerFor]="beforeMenu"
            #menu="matMenuTrigger"
            class="more"
          >
            <mat-icon class="material-icons-outlined"> more_horiz</mat-icon>
          </a>
          <mat-menu
            class="more_menu nav_menu"
            [class]="lang.direction == 'ltr' ? 'dirLtr' : ''"
            #beforeMenu="matMenu"
            xPosition="before"
          >
            <a mat-menu-item [routerLink]="'/pages/FAQ'">
              {{ "privacy-pages.FAQ" | translate }}
            </a>
            <a mat-menu-item [routerLink]="'/pages/terms'">
              {{ "privacy-pages.TAC" | translate }}
            </a>
            <a mat-menu-item [routerLink]="'/pages/privacy'">
              {{ "privacy-pages.PP" | translate }}
            </a>
            <a mat-menu-item [routerLink]="'/pages/about'">
              {{ "privacy-pages.WAW" | translate }}
            </a>
            <a
              mat-menu-item
              [routerLink]="'/pages/technical-support'"
              target="_blank"
            >
              {{ "privacy-pages.Tech-Sup" | translate }}
            </a>
          </mat-menu>
        </li>
        <li>
          <a
            class="logout-link"
            (click)="dataService.getUserData() ? logout() : ''"
            [routerLink]="!dataService.getUserData() ? '/login' : activeRoute"
          >
            <figure>
              <img
                [src]="
                  dataService.getUserData()
                    ? '../../../../assets/images/nav/logout.png'
                    : '../../../../assets/images/nav/login.png'
                "
                [alt]="
                  (dataService.getUserData()
                    ? 'account.login'
                    : 'account.logout'
                  ) | translate
                "
              />
            </figure>
            {{ (dataService.getUserData() ? "" : "navbar.login") | translate }}
          </a>
        </li>

        <!-- <li>
          <mat-slide-toggle
            class="custom-slide-toggle"
            [(ngModel)]="isDarkTheme"
            (change)="swithTheme()"
            color="primary"
          >
            <span class="subtitle1 main-nav__btn-theme">
              {{ "navbar.dark-theme" | translate }}
            </span>
          </mat-slide-toggle>
        </li> -->

        <!-- <li *ngIf="this.lang.currentLang == 'en'">
          <a (click)="this.lang.useLanguage('ar')">
            العربية
            <mat-icon>language</mat-icon>
          </a>
        </li>
        <li *ngIf="this.lang.currentLang == 'ar'">
          <a (click)="this.lang.useLanguage('en')">
            English
            <mat-icon>language</mat-icon>
          </a>
        </li> -->
      </ul>
    </nav>
  </section>
</section>

<!-- End labtop and desktop view -->

<!-- start mobile and tablet view -->
<div class="nav-res" *ngIf="!dataService.isMeeting && screenWidth <= 960">
  <div class="drawer-icon-container">
    <span>
      <mat-icon
        class="menu-icon"
        (click)="navRes ? closeNav() : (navRes = true)"
        [ngClass]="{ 'menu-icon-res': !navRes }"
        >menu</mat-icon
      >
    </span>
    <a routerLink="/" class="menu-logo">
      <img src="assets/images/logo.png" />
    </a>
  </div>
  <nav class="main-nav" [ngClass]="{ closed: !navRes }">
    <ul>
      <li *ngIf="!navResPages">
        <a
          [ngClass]="{
            active: activeRoute == '/home' || activeRoute == '/'
          }"
          routerLink="/home"
        >
          <figure>
            <img
              [src]="
                activeRoute == '/home' || activeRoute == '/'
                  ? '../../../../assets/images/nav/home_active.png'
                  : '../../../../assets/images/nav/home.png'
              "
              [alt]="'navbar.home' | translate"
            />
          </figure>
          {{ "navbar.home" | translate }}
        </a>
      </li>

      <li *ngIf="dataService.getUserData() && !navResPages">
        <a
          [ngClass]="{
            active: activeRoute == '/profile' || activeRoute == '/login'
          }"
          [routerLink]="dataService.getUserData() ? '/profile' : '/login'"
        >
          <figure>
            <img
              [src]="
                activeRoute == '/profile' || activeRoute == '/login'
                  ? '../../../../assets/images/nav/person_active.png'
                  : '../../../../assets/images/nav/person.png'
              "
              [alt]="'navbar.my-account' | translate"
            />
          </figure>

          {{ "navbar.my-account" | translate }}</a
        >
      </li>

      <li *ngIf="!navResPages">
        <a
          [ngClass]="{
            active: activeRoute == '/setting'
          }"
          [routerLink]="'/setting'"
        >
          <figure>
            <img
              [src]="
                activeRoute == '/setting'
                  ? '../../../../assets/images/nav/settings_active.png'
                  : '../../../../assets/images/nav/settings.png'
              "
              [alt]="'navbar.setting' | translate"
            />
          </figure>

          {{ "navbar.setting" | translate }}</a
        >
      </li>
      <!--
      <li *ngIf="!dataService.getUserData() && !navResPages">
        <a
          [ngClass]="{ active: activeRoute == '/pages/about' }"
          [routerLink]="'/pages/about'"
        >
          <figure>
            <img
              [src]="
                activeRoute == '/pages/about'
                  ? '../../../../assets/images/nav/help_active.png'
                  : '../../../../assets/images/nav/help.png'
              "
              [alt]="'navbar.about' | translate"
            />
          </figure>
          {{ "navbar.about" | translate }}
        </a>
      </li> -->

      <li
        class="privacy-menu"
        [ngClass]="{
          navResHeader: navResPages
        }"
      >
        <a
          [ngClass]="{
            active: activeRoute?.includes('/pages/') && navResPages,
            dirLtr: lang.direction == 'ltr'
          }"
          class="more"
          (click)="
            navResPages ? [(navResPages = false)] : [(navResPages = true)]
          "
        >
          <mat-icon class="material-icons-outlined">{{
            navResPages ? "arrow_forward_ios" : "more_horiz"
          }}</mat-icon>
          {{ "meeting-room.more" | translate }}
          <mat-icon *ngIf="!navResPages" class="material-icons-outlined arrow"
            >arrow_back_ios</mat-icon
          >
        </a>

        <div
          *ngIf="navResPages"
          class="navResPages"
          [class]="lang.direction == 'ltr' ? 'dirLtr' : ''"
        >
          <a
            mat-menu-item
            [routerLink]="'/pages/FAQ'"
            [ngClass]="{
              active: activeRoute?.includes('/pages/FAQ') && navResPages,
              navResPages: navResPages
            }"
          >
            {{ "privacy-pages.FAQ" | translate }}
          </a>
          <a
            mat-menu-item
            [routerLink]="'/pages/terms'"
            [ngClass]="{
              active: activeRoute?.includes('/pages/terms') && navResPages,
              navResPages: navResPages
            }"
          >
            {{ "privacy-pages.TAC" | translate }}
          </a>
          <a
            mat-menu-item
            [routerLink]="'/pages/privacy'"
            [ngClass]="{
              active: activeRoute?.includes('/pages/privacy') && navResPages,
              navResPages: navResPages
            }"
          >
            {{ "privacy-pages.PP" | translate }}
          </a>
          <a
            mat-menu-item
            [routerLink]="'/pages/about'"
            [ngClass]="{
              active: activeRoute?.includes('/pages/about') && navResPages,
              navResPages: navResPages
            }"
          >
            {{ "privacy-pages.WAW" | translate }}
          </a>
          <a
            target="_blank"
            mat-menu-item
            [routerLink]="'/pages/technical-support'"
            [ngClass]="{
              active:
                activeRoute?.includes('/pages/technical-support') &&
                navResPages,
              navResPages: navResPages
            }"
          >
            {{ "privacy-pages.Tech-Sup" | translate }}
          </a>
        </div>
      </li>

      <li *ngIf="!navResPages">
        <mat-divider *ngIf="navRes"></mat-divider>
        <a
          class="logout-link"
          (click)="dataService.getUserData() ? logout() : ''"
          [routerLink]="!dataService.getUserData() ? '/login' : ''"
          [ngClass]="{ login: !dataService.getUserData() }"
        >
          <figure>
            <img
              [src]="
                dataService.getUserData()
                  ? '../../../../assets/images/nav/logout_res.svg'
                  : '../../../../assets/images/nav/login_res.svg'
              "
              [alt]="
                (dataService.getUserData() ? 'account.login' : 'account.logout')
                  | translate
              "
            />
          </figure>
          {{
            (dataService.getUserData() ? "account.logout" : "navbar.login")
              | translate
          }}
        </a>
      </li>
    </ul>
  </nav>
</div>

<!-- End mobile and tablet view-->
