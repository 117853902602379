import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { KortobaaMeetService } from '@kortobaa-front/ng-kmeet-sdk';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DataService } from 'src/app/shared/services/data.service';
import { MeetingsService } from '../services/meetings.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogData } from '../allow-media/allow-media.component';

@Component({
  selector: 'app-remove-meeting-dialog',
  templateUrl: './remove-meeting-dialog.component.html',
  styleUrls: ['./remove-meeting-dialog.component.scss'],
})
export class RemoveMeetingDialogComponent implements OnInit {
  private _loading = {
    save: false,
    info: false,
  };
  constructor(
    public kortobaaPkg: KortobaaMeetService,
    public dataService: DataService,
    private _meetingService: MeetingsService,
    private _router: Router,
    private _translate: TranslateService,
    public dialogRef: MatDialogRef<RemoveMeetingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {}

  async removeMeeting() {
    // remove meeting function for meeting host
    try {
      this._loading.save = true;
      await this._meetingService
        .cancelUpcommingMeeting(this.data['meeting'].id)
        .toPromise();

      const successMessage = this._translate.instant(
        'account.meeting-canceled-successfully'
      );
      this.dataService.notification(successMessage);
      this._loading.save = false;
      this._router.navigate(['home']);
    } catch (error) {
      this._loading.save = false;
      let message;

      if (error.status == 401) {
        message = this._translate.instant('errors.unauthorized');
      } else if (error.status == 400 || error.status == 422) {
        message = this._translate.instant(error.error.error.message);
      } else {
        message = this._translate.instant(error.message);
      }
      this.dataService.notification(message, true);
    }
    this.dialogRef.close();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
