import { Component, Inject } from '@angular/core';
import { Direction } from '@angular/cdk/bidi';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { MeetingURLService } from 'src/app/shared/services/meeting-url.service';

export interface DialogData {
  meetingToken: string;
  direction: Direction;
}

@Component({
  selector: 'app-invite-dialog',
  templateUrl: './invite-dialog.component.html',
  styleUrls: ['./invite-dialog.component.scss'],
})
export class InviteDialogComponent {
  email = '';

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<InviteDialogComponent>,
    private _meetingURL: MeetingURLService
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  sendInvitation() {
    this._meetingURL.copyMeetingUrl(this.data.meetingToken);

    this.onNoClick();
  }
}
