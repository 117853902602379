import {
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  Directive,
  Input,
  OnChanges,
  Renderer2,
  SimpleChanges,
  ViewContainerRef,
} from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatButton } from '@angular/material/button';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { SpinnerComponent } from '../components/spinner/spinner.component';

@Directive({
  selector: `button[mat-button][loading], button[mat-raised-button][loading], button[mat-icon-button][loading],
               button[mat-fab][loading], button[mat-mini-fab][loading], button[mat-stroked-button][loading],
               button[mat-flat-button][loading]`,
})
export class MatButtonLoadingDirective implements OnChanges {
  private spinnerFactory: ComponentFactory<MatProgressSpinner>;
  private spinner: ComponentRef<MatProgressSpinner>;
  @Input('askVariableAutoComplete') SpinnerComponent: SpinnerComponent;

  @Input()
  loading: boolean;

  @Input()
  disabled: boolean;

  @Input()
  loadColor: ThemePalette = 'accent';

  constructor(
    private _matButton: MatButton,
    private _componentFactoryResolver: ComponentFactoryResolver,
    private _viewContainerRef: ViewContainerRef,
    private _renderer: Renderer2
  ) {
    this.spinnerFactory =
      this._componentFactoryResolver.resolveComponentFactory(
        MatProgressSpinner
      );
      this.SpinnerComponent;

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes.loading) {
      return;
    }

    if (changes.loading.currentValue) {
      this._matButton._elementRef.nativeElement.classList.add('mat-loading');
      this._matButton.disabled = true;
      this._createSpinner();
    } else if (!changes.loading.firstChange) {
      this._matButton._elementRef.nativeElement.classList.remove('mat-loading');
      this._matButton.disabled = this.disabled;
      this._destroySpinner();
    }
  }

  private _createSpinner(): void {
    if (!this.spinner) {
      this.spinner = this._viewContainerRef.createComponent(
        this.spinnerFactory
      );
      this.spinner.instance.color = this.loadColor;
      this.spinner.instance.diameter = 20;
      this.spinner.instance.mode = 'indeterminate';
      this._renderer.appendChild(
        this._matButton._elementRef.nativeElement,
        this.spinner.instance._elementRef.nativeElement
      );
    }
  }

  private _destroySpinner(): void {
    if (this.spinner) {
      this.spinner.destroy();
      this.spinner = null;
    }
  }
}
