import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DataService } from './data.service';

@Injectable()
export class WebrtcService {
  //

  constructor(
    private dataService: DataService,
  ) {
    //
  }

  async checkRtcConnection() {
    // console.log(environment.TurnIP);
    let check;
    check = await this.checkRtcType('udp');
    if (!check) {
      check = await this.checkRtcType('tcp');
      if (!check) return false;
      return {
        type: 'TCP',
      };
    } else {
      return {
        type: 'UDP',
      };
    }
  };

  async checkRtcType(type: string) {
    let config;
    if (type.toLowerCase() === 'udp') {
      config = {
        bundlePolicy: 'balanced',
        rtcpMuxPolicy: 'require',
        sdpSemantics: 'unified-plan',
        iceServers: [
          {
            urls: ['stun:' + environment.TurnIP + ':4807'],
          },
        ],
        iceTransportPolicy: 'all',
      };
    } else if (type.toLowerCase() === 'tcp') {
      config = {
        bundlePolicy: 'balanced',
        rtcpMuxPolicy: 'require',
        sdpSemantics: 'unified-plan',
        iceServers: [
          {
            username: 'DlzBo7ulWTNu484SL0VEnQ==',
            credential:
              'b4nIDOvxyFH2fO32JMilKmTxMqjtm0rmXZTwTDK309MihtTleifKSvDxTUDNPJUugTO2wMROTJ0F7DFJhnLWuw==',
            urls: ['turn:' + environment.TurnIP + ':6251?transport=tcp'],
          },
        ],
        iceTransportPolicy: 'relay',
      };
    } else {
      return false;
    }
    let pc1: any = new RTCPeerConnection(config);
    let pc2: any = new RTCPeerConnection(config);
    this.establishConnection(pc1, pc2);
    return await new Promise((resolve, reject) => {
      pc1.addEventListener('icecandidate', (event) => {
        // console.log(event);
        if (event.candidate) {
          setTimeout(() => {
            resolve(false);
          }, 3000);
          const parsed = this.parseCandidate(event.candidate.candidate);
          if (type === 'udp') {
            if (isReflexive(parsed)) {
              pc1.close();
              pc1 = null;
              pc2.close();
              pc2 = null;
              resolve(parsed);
            }
          }
          if (type === 'tcp') {
            if (isRelay(parsed)) {
              if (pc1) pc1.close();
              pc1 = null;
              if (pc2) pc2.close();
              pc2 = null;
              resolve(parsed);
            }
          }
        } else {
          if (pc1) pc1.close();
          pc1 = null;
          if (pc2) pc2.close();
          pc2 = null;
          resolve(false);
        }
      });
    });
  };

  parseCandidate(text) {
    var candidateStr = 'candidate:';
    var pos = text.indexOf(candidateStr) + candidateStr.length;
    var fields = text.substr(pos).split(' ');
    return {
      type: fields[7],
      protocol: fields[2],
      address: fields[4],
    };
  };

  establishConnection(pc1, pc2) {
    var createOfferParams = { offerToReceiveAudio: 1 };
    pc1.createOffer(createOfferParams).then(function (offer) {
      pc1.setLocalDescription(offer);
      pc2.setRemoteDescription(offer);
      pc2.createAnswer().then(function (answer) {
        pc2.setLocalDescription(answer);
        pc1.setRemoteDescription(answer);
      }, emptyFunction);
    }, emptyFunction);

    // Empty function for callbacks requiring a function.
    function emptyFunction() { }
  }

}

function isRelay(candidate) {
  return candidate.type === 'relay';
}

function isHost(candidate) {
  return candidate.type === 'host';
}

function isIpv6(candidate) {
  return candidate.address.indexOf(':') !== -1;
}

function isReflexive(candidate) {
  return candidate.type === 'srflx';
}
