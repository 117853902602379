import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { LangService } from 'src/app/shared/services/lang.service';

@Injectable({
  providedIn: 'root',
})
export class LogoutDialogService {
  constructor(
    private _router: Router,
    private _translate: TranslateService,
    private _dialog: MatDialog,
    private _lang: LangService
  ) {}

  logout() {
    this._dialog
      .open(ConfirmDialogComponent, {
        direction: this._lang.direction,
        disableClose: true,
        width: '26rem',
        panelClass: 'confirm-dialog',
        data: {
          title: this._translate.instant('login.logout'),
          content: this._translate.instant('login.sure-you-want-leave'),
          // icon: 'logout',
        },
      })
      .afterClosed()
      .subscribe((isLogout) => {
        if (isLogout) {
          this._router.navigate(['/logout']);
        }
      });
  }
}
