<div class="settings-header">
    <h1>{{ 'meeting-room.host-options' | translate }}</h1>
    <a (click)="onNoClick()">
        <mat-icon>close</mat-icon>
    </a>
</div>

<!-- <div class="option-row">
    <label>{{"settings.who-can-share-screen" | translate}}</label>
    <mat-form-field class="host-settings-select" appearance="fill">
        <mat-select [(ngModel)]="data.meetingSettings.whoCanShareScreen">
            <mat-option value="onlyMe">
                {{"settings.only-me" | translate}}
            </mat-option>
            <mat-option value="everyone">
                {{"settings.everyone" | translate}}
            </mat-option>
            <mat-option value="specificPeople">
                {{"settings.specific-people" | translate}}
            </mat-option>
        </mat-select>
    </mat-form-field>
</div> -->

<div class="option-row">
    <label>{{"settings.enable-waiting-room" | translate}}</label>
    <mat-slide-toggle color="primary" [(ngModel)]="meetingSettings.isWaiting">
    </mat-slide-toggle>
</div>

<div class="option-row">
    <label>{{"settings.lock-the-meeting" | translate}}</label>
    <mat-slide-toggle color="primary" [(ngModel)]="meetingSettings.locked">
    </mat-slide-toggle>
</div>

<div class="dialog-footer">
    <button mat-flat-button color="primary" (click)="changeMeetingSettings()">{{'account.save' |
        translate}}</button>
</div>