export enum roles {
  admin = 'admin',
  superAdmin = 'superAdmin',
  user = 'user',
  company = 'company',
  delivery = 'delivery',
}

export interface Country {
  id: string;
  name: string;
}

export interface TimeZone {
  label: string;
  tzCode: string;
  name: string;
  utc: string;
}

export interface MeetingInfo {
  roomToken: string;
  userId: number;
  userName: string;
}
