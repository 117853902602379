<!-- Start auth actions -->
<section class="auth-card">
  <!-- start login form -->
  <div
    *ngIf="!signUpForm && !resetPassForm && !verifyPassForm && !newPassForm"
    class="form-container"
  >
    <div class="form-header">
      <img src="assets/images/logo-icon.png" alt="Halameet" />
      <h3 class="headline2">
        {{ "login.login" | translate }}
      </h3>
    </div>

    <form #loginForm="ngForm" id="registerForm">
      <p
        class="headline5"
        *ngIf="
          loginResponseData.errorNotification.length &&
          !loginResponseData.waitingTime
        "
      >
        {{ "errors." + loginResponseData.errorNotification | translate }}
        <span class="headline5" *ngIf="loginResponseData.attempts > 0">{{
          "errors." + loginResponseData.attempText
            | translate : { attempts: loginResponseData.attempts }
        }}</span>
      </p>
      <p
        class="headline5"
        *ngIf="
          loginResponseData.errorNotification.length &&
          loginResponseData.waitingTime
        "
      >
        {{
          "errors." + loginResponseData.errorNotification
            | translate : { time: loginResponseData.waitingTime }
        }}
      </p>
      <div class="form-input">
        <mat-label
          >{{ "login.email" | translate }}
          <span class="label__required">*</span>
        </mat-label>

        <mat-form-field
          class="bodytext-normal"
          block
          appearance="fill"
          color="primary"
          [ngClass]="{
            invalid: (email.touched && email.errors) || loginFailed
          }"
        >
          <input
            type="email"
            name="email"
            #email="ngModel"
            [(ngModel)]="userData.email"
            matInput
            required
            pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
            inputmode="email"
            (focus)="loginFailed = false"
          />
        </mat-form-field>
        <mat-error
          *ngIf="
            loginForm.form.controls['email']?.touched &&
            loginForm.form.controls['email'].errors
          "
          >{{ "validation.email-not-valid" | translate }}</mat-error
        >
      </div>
      <div class="form-input">
        <mat-label
          >{{ "login.password" | translate }}
          <span class="label__required">*</span>
        </mat-label>

        <mat-form-field
          class="bodytext-normal"
          block
          appearance="fill"
          color="primary"
          [ngClass]="{
            invalid: (password.touched && password.errors) || loginFailed
          }"
        >
          <input
            [type]="isloginPasswordHidden ? 'password' : 'text'"
            name="password"
            #password="ngModel"
            [(ngModel)]="userData.password"
            matInput
            required
            (capsLock)="capsOn = $event"
            (focus)="loginFailed = false"
          />

          <button
            class="toggle-pass bodytext2"
            type="button"
            mat-icon-button
            matSuffix
            (click)="toggleDisplayPassword($event, 'loginPassword')"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="isloginPasswordHidden"
          >
            <mat-icon class="material-icons-outlined">{{
              isloginPasswordHidden ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
        </mat-form-field>
        <mat-error
          *ngIf="
            loginForm.form.controls['password']?.touched &&
            loginForm.form.controls['password'].errors
          "
          >{{ "validation.pass-not-correct" | translate }}</mat-error
        >
        <mat-error *ngIf="capsOn" class="caps-on">
          <mat-icon class="material-icons-outlined">info</mat-icon>
          {{ "validation.caps-lock" | translate }}</mat-error
        >
      </div>

      <p class="resetPass">
        <a class="headline6" (click)="resetPassword()">
          {{ "login.forgot-my-pass" | translate }}
        </a>
      </p>
      <mat-checkbox
        class="subtitle2"
        color="primary"
        name="rememberMe"
        #rememberMe="ngModel"
        [(ngModel)]="userData.rememberMe"
      >
        {{ "login.remember-me" | translate }}
      </mat-checkbox>

      <small class="mat-error login-error" *ngIf="loginFailed">
        {{ "validation.email-or-pass" | translate }}
      </small>
      <!-- <div class="login-error">
        <mat-error
          *ngIf="
            loginForm.form.controls['email']?.touched &&
            loginForm.form.controls['email'].errors &&
            loginForm.form.controls['password']?.touched &&
            loginForm.form.controls['password'].errors
          "
          >{{ "validation.pass-email-not-valid" | translate }}</mat-error
        >
      </div> -->

      <div class="form-footer">
        <button
          type="submit"
          mat-flat-button
          color="primary"
          class="main-btn button18 block"
          loadColor="accent"
          (click)="onLoginSubmit()"
          [disabled]="!loginForm.form.valid || loading"
        >
          <app-loading-action *ngIf="loading"></app-loading-action>
          {{ "login.login" | translate }}
        </button>

        <div class="apps_container">
          <div class="apps_container-or">
            {{ "login.or" | translate }}
          </div>
          <!--<div class="apps">
            <div class="apps-item">
              <figure>
                <img src="../../../assets/images/apple-login.svg" alt="apple" />
              </figure>
            </div>
            <div class="apps-item">
              <figure>
                <img
                  src="../../../assets/images/google-login.svg"
                  alt="google"
                />
              </figure>
            </div>
          </div>-->
        </div>

        <p class="subtitle1">
          {{ "login.dont-have-account" | translate }}
          <a class="headline6" (click)="toggleSignUpForm()">
            {{ "login.sign-up" | translate }}
          </a>
        </p>

        <!-- <a class="headline6" (click)="resetPassword()">
          {{ "login.forgot-my-pass" | translate }}
        </a> -->
      </div>
    </form>
  </div>
  <!-- end login form -->

  <!-- start sign up form -->
  <div *ngIf="signUpForm" class="form-container" #registerScroll>
    <div class="form-header">
      <img src="assets//images/logo-icon.png" alt="Halameet" />
      <h3 class="headline2">
        {{ "login.new-sign-up" | translate }}
      </h3>
    </div>

    <form #registerForm="ngForm" id="registerForm">
      <p
        class="headline5"
        *ngIf="
          signUpResponseData.errorNotification.length &&
          !signUpResponseData.waitingTime
        "
      >
        {{ "errors." + signUpResponseData.errorNotification | translate }}
        <span class="headline5" *ngIf="signUpResponseData.attempts > 0">{{
          "errors." + signUpResponseData.attempText
            | translate : { attempts: signUpResponseData.attempts }
        }}</span>
      </p>
      <p
        class="headline5"
        *ngIf="
          signUpResponseData.errorNotification.length &&
          signUpResponseData.waitingTime
        "
      >
        {{
          "errors." + signUpResponseData.errorNotification
            | translate : { time: signUpResponseData.waitingTime }
        }}
      </p>

      <div
        (scroll)="changeStyle()"
        class="custom-scroll"
        [ngClass]="{ dirLtr: _lang.direction == 'ltr' }"
      >
        <!-- username -->
        <div class="form-input">
          <mat-label
            >{{ "login.user-name" | translate }}
            <span class="label__required">*</span>
          </mat-label>
          <mat-form-field
            class="bodytext-normal"
            block
            appearance="fill"
            color="primary"
            [ngClass]="{ invalid: firstName.touched && firstName.errors }"
          >
            <input
              type="text"
              name="firstName"
              #firstName="ngModel"
              [(ngModel)]="registerData.additionalData.firstName"
              [pattern]="usernamePattern"
              minlength="1"
              maxlength="30"
              matInput
              required
            />
            <!-- <mat-error>{{
              "validation.user-name-required" | translate
            }}</mat-error> -->
          </mat-form-field>
          <small
            class="mat-error"
            *ngIf="
              !firstName?.valid &&
              firstName.errors &&
              firstName.touched &&
              (firstName.errors['required'] || firstName.errors['pattern'])
            "
          >
            {{ "validation.user-name-required" | translate }}
          </small>
        </div>
        <!-- email -->

        <div class="form-input">
          <mat-label
            >{{ "login.email" | translate }}
            <span class="label__required">*</span>
          </mat-label>

          <mat-form-field
            class="bodytext-normal"
            block
            appearance="fill"
            color="primary"
            [ngClass]="{ invalid: email.touched && email.errors }"
          >
            <input
              type="email"
              name="email"
              #email="ngModel"
              [(ngModel)]="registerData.email"
              matInput
              required
              pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
              inputmode="email"
            />
            <!-- <mat-error>{{
              "validation.email-not-valid" | translate
            }}</mat-error> -->
          </mat-form-field>
          <small
            class="mat-error"
            *ngIf="
              !email?.valid &&
              email.errors &&
              email.touched &&
              (email.errors['pattern'] || email.errors['required'])
            "
          >
            {{ "validation.email-not-valid" | translate }}
          </small>
        </div>
        <!-- password -->
        <div class="form-input">
          <mat-label
            >{{ "login.password" | translate }}
            <span class="label__required">*</span>
            <div class="tooltip">
              <mat-icon
                class="material-icons-outlined info"
                (click)="wrongPassCriteria = false"
              >
                info</mat-icon
              >
              <span
                class="tooltiptext"
                [ngStyle]="{
                  visibility: wrongPassCriteria ? 'visible' : ''
                }"
              >
                <span class="custom-tooltip">
                  {{ "tooltip.password-criteria" | translate }}
                </span>
                <br />
                <mat-icon class="close-icon" *ngIf="!passwordError.length"
                  >close</mat-icon
                >
                <mat-icon class="success-icon" *ngIf="passwordError.length"
                  >check</mat-icon
                >
                {{ "validation.pass-char" | translate }}
                <br />
                <mat-icon class="close-icon" *ngIf="!passwordError.spaces"
                  >close</mat-icon
                >
                <mat-icon class="success-icon" *ngIf="passwordError.spaces"
                  >check</mat-icon
                >
                {{ "validation.pass-space" | translate }}
                <br />
                <mat-icon class="close-icon" *ngIf="!passwordError.english"
                  >close</mat-icon
                >
                <mat-icon class="success-icon" *ngIf="passwordError.english"
                  >check</mat-icon
                >
                {{ "validation.pass-eng" | translate }}
                <br />
                <mat-icon class="close-icon" *ngIf="!passwordError.number"
                  >close</mat-icon
                >
                <mat-icon class="success-icon" *ngIf="passwordError.number"
                  >check</mat-icon
                >
                {{ "validation.pass-num" | translate }}
                <br />
                <mat-icon class="close-icon" *ngIf="!passwordError.special"
                  >close</mat-icon
                >
                <mat-icon class="success-icon" *ngIf="passwordError.special"
                  >check</mat-icon
                >
                {{ "validation.pass-special" | translate }}
                <br />
                <mat-icon class="close-icon" *ngIf="!passwordError.upper"
                  >close</mat-icon
                >
                <mat-icon class="success-icon" *ngIf="passwordError.upper"
                  >check</mat-icon
                >
                {{ "validation.pass-upper" | translate }}
              </span>
            </div>
          </mat-label>
          <mat-form-field
            class="bodytext-normal"
            block
            appearance="fill"
            color="primary"
            [ngClass]="{ invalid: passwordhasErrors }"
          >
            <!-- pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_+#?!@$%^&*-]).{8,}$" -->
            <input
              [type]="isSignUpPasswordHidden ? 'password' : 'text'"
              name="password"
              #password="ngModel"
              [(ngModel)]="registerData.password"
              minlength="8"
              maxlength="50"
              matInput
              pattern="^[^\n ]*$"
              required
              autocomplete="off"
              (capsLock)="capsOn = $event"
              (blur)="passwordhasErrors ? (wrongPassCriteria = true) : false"
              (input)="checkPasswordPattern(registerData.password)"
              (blur)="$event.target.value.trim()"
            />
            <!-- (focus)="wrongPassCriteria = false" -->

            <!-- <mat-error>{{
              "validation.pass-not-correct" | translate
            }}</mat-error> -->
            <button
              class="toggle-pass bodytext2"
              type="button"
              mat-icon-button
              matSuffix
              (click)="toggleDisplayPassword($event, 'signUpPassword')"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="isSignUpPasswordHidden"
            >
              <mat-icon class="material-icons-outlined">{{
                isSignUpPasswordHidden ? "visibility_off" : "visibility"
              }}</mat-icon>
            </button>
          </mat-form-field>
          <!-- <small
            class="mat-error"
            *ngIf="
              !password?.valid &&
              password.touched &&
              password.errors &&
              password.errors['required']
            "
          >
            {{ "validation.pass-not-correct" | translate }}
          </small>
          <small
            class="mat-error"
            *ngIf="
              !password?.valid &&
              password.touched &&
              password.errors &&
              password.errors['pattern']
            "
          >
            {{ "validation.pass-char" | translate }}
            <br />
            {{ "validation.pass-eng" | translate }}
            <br />
            {{ "validation.pass-num" | translate }}
            <br />
            {{ "validation.pass-special" | translate }}
            <br />
            {{ "validation.pass-upper" | translate }}
          </small> -->
          <mat-error *ngIf="capsOn" class="caps-on">
            <mat-icon class="material-icons-outlined">info</mat-icon>
            {{ "validation.caps-lock" | translate }}</mat-error
          >
        </div>

        <div class="form-input">
          <mat-label
            >{{ "login.confirm-pass" | translate }}
            <span class="label__required">*</span>
          </mat-label>
          <mat-form-field
            class="bodytext-normal"
            block
            appearance="fill"
            color="primary"
            [ngClass]="{
              invalid: passConfirmation.touched && passConfirmation.errors
            }"
          >
            <input
              [type]="isSignUpConfrimPasswordHidden ? 'password' : 'text'"
              name="passConfirmation"
              #passConfirmation="ngModel"
              [(ngModel)]="registerData.passConfirmation"
              matInput
              [comparePassword]="password"
              minlength="8"
              maxlength="50"
              pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_+#?!@$%^&*-]).{8,}$"
              required
            />
            <!-- <mat-error>{{ "validation.pass-not-match" | translate }}</mat-error> -->
            <button
              class="toggle-pass bodytext2"
              type="button"
              mat-icon-button
              matSuffix
              (click)="toggleDisplayPassword($event, 'signUpConfrimPassword')"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="isSignUpConfrimPasswordHidden"
              [ngClass]="{ invalid: passConfirmation.errors }"
            >
              <mat-icon class="material-icons-outlined">{{
                isSignUpConfrimPasswordHidden ? "visibility_off" : "visibility"
              }}</mat-icon>
            </button>
          </mat-form-field>
          <small
            class="mat-error"
            *ngIf="
              !passConfirmation?.valid &&
              passConfirmation.touched &&
              passConfirmation.errors
            "
          >
            {{ "validation.pass-not-match" | translate }}
          </small>
        </div>

        <!-- TODO: make a real phone vaildator -->
        <div class="form-input">
          <mat-label
            >{{ "login.phone" | translate }}
            <!-- <span class="label__required">*</span> -->
          </mat-label>
          <mat-form-field
            class="bodytext-normal"
            block
            appearance="fill"
            color="primary"
            [ngClass]="{ invalid: phone.touched && phone.errors }"
          >
            <input
              type="text"
              pattern="[0-9]*"
              name="phone"
              #phone="ngModel"
              [(ngModel)]="registerData.phone"
              matInput
              inputmode="phone"
              onkeypress="return /[0-9]/i.test(event.key)"
              maxlength="10"
            />
            <!-- <mat-error>{{
              "validation.your-phone-num-required" | translate
            }}</mat-error> -->
          </mat-form-field>
          <small
            class="mat-error"
            *ngIf="!phone?.valid && phone.touched && phone.errors"
          >
            {{ "validation.your-phone-num-required" | translate }}
          </small>
        </div>

        <!-- job title -->
        <!-- <div class="form-input">
          <mat-label>{{ "login.job-title" | translate }}</mat-label>

          <mat-form-field
            class="bodytext-normal"
            block
            appearance="fill"
            color="primary"
          >
            <input
              type="text"
              name="jobTitle"
              #jobTitle="ngModel"
              [(ngModel)]="registerData.additionalData.jobTitle"
              matInput
            />
          </mat-form-field>
        </div> -->

        <!-- company -->
        <!-- <div class="form-input">
          <mat-label>{{ "login.company" | translate }}</mat-label>

          <mat-form-field
            class="bodytext-normal"
            block
            appearance="fill"
            color="primary"
          >
            <input
              type="text"
              name="company"
              #company="ngModel"
              [(ngModel)]="registerData.additionalData.company"
              matInput
            />
          </mat-form-field>
        </div> -->

        <!-- country  -->
        <!-- <div class="form-input">
          <mat-label>{{ "login.country" | translate }}</mat-label>

          <mat-form-field
            class="bodytext-normal"
            block
            appearance="fill"
            color="primary"
          >
            <mat-select
              name="country"
              #country="ngModel"
              [(ngModel)]="registerData.additionalData.country"
            >
              <mat-option
                *ngFor="let country of countries"
                [value]="country?.name"
              >
                {{ country?.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div> -->
      </div>

      <div class="form-footer">
        <button
          type="submit"
          mat-flat-button
          class="main-btn button18 block"
          loadColor="accent"
          (click)="onSignUpSubmit()"
          [disabled]="!registerForm.form.valid"
        >
          <app-loading-action *ngIf="loading"></app-loading-action>

          {{ "login.new-sign-up" | translate }}
        </button>

        <div class="apps_container">
          <div class="apps_container-or">
            {{ "login.or" | translate }}
          </div>
          <!--<div class="apps">
            <div class="apps-item">
              <figure>
                <img src="../../../assets/images/apple-login.svg" alt="apple" />
              </figure>
            </div>
            <div class="apps-item">
              <figure>
                <img
                  src="../../../assets/images/google-login.svg"
                  alt="google"
                />
              </figure>
            </div>
          </div>-->
        </div>

        <p class="subtitle1">
          {{ "login.already-have-account" | translate }}
          <a class="headline6" (click)="toggleSignUpForm()">
            {{ "login.login" | translate }}
          </a>
        </p>
      </div>
    </form>
  </div>
  <!-- end sign up form -->

  <!-- start resetPassword form -->
  <div *ngIf="resetPassForm" class="form-container reset-password-container">
    <div class="form-header">
      <a
        (click)="resetPassForm = false"
        [ngClass]="{ dirLtr: _lang.direction == 'ltr' }"
      >
        <mat-icon class="arrow-dir">arrow_back</mat-icon>
      </a>
      <div>
        <img src="assets/images/logo-icon.png" alt="Halameet" />
        <h3 class="headline2">
          {{ "login.reset-password" | translate }}
        </h3>
      </div>
    </div>

    <p class="headline7">
      {{ "login.if-you-forgot-your-password" | translate }}
    </p>

    <div class="form-input resetPassInput">
      <mat-label>
        {{ "login.email" | translate }}
        <span class="label__required">*</span>
      </mat-label>
      <mat-form-field
        class="bodytext-normal"
        block
        appearance="fill"
        color="primary"
      >
        <input
          type="email"
          name="email"
          #email="ngModel"
          [(ngModel)]="resetPassData.resetEmail"
          matInput
          required
          pattern="^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$"
          inputmode="email"
        />
      </mat-form-field>
      <mat-error
        *ngIf="
          !email?.valid &&
          email.errors &&
          (email.touched || email.dirty) &&
          (email.errors['pattern'] || email.errors['required'])
        "
        >{{ "validation.email-not-valid" | translate }}</mat-error
      >

      <p class="resend-code">
        <mat-icon class="material-icons-outlined">info</mat-icon>
        {{ "resend-code" | translate }}
      </p>
    </div>
    <div class="form-footer">
      <button
        type="submit"
        mat-flat-button
        color="primary"
        class="main-btn button18 block"
        (click)="sendVerificationCode()"
        [disabled]="!resetPassData.resetEmail || email.errors || loading"
      >
        <app-loading-action *ngIf="loading"></app-loading-action>

        {{ "login.send-code" | translate }}
      </button>
      <button
        type="submit"
        mat-flat-button
        color="primary"
        class="main-btn button18 block cancel"
        (click)="
          signUpForm = false;
          resetPassForm = false;
          verifyPassForm = false;
          newPassForm = false;
          cancel()
        "
      >
        {{ "account.cancel" | translate }}
      </button>
    </div>
  </div>
  <!-- end resetPassword form -->

  <!-- start verifyPassword form -->
  <div *ngIf="verifyPassForm" class="form-container reset-password-container">
    <div class="form-header">
      <a
        (click)="resetPassForm = true; verifyPassForm = false"
        [ngClass]="{ dirLtr: _lang.direction == 'ltr' }"
      >
        <mat-icon class="arrow-dir">arrow_back</mat-icon>
      </a>
      <div>
        <img src="assets/images/logo-icon.png" alt="Halameet" />
        <h3 class="headline2">
          {{ "login.reset-password" | translate }}
        </h3>
      </div>
    </div>

    <div class="headline7 rename">
      {{ "login.five-digit-code-has-been-sent-1" | translate }}
      <div class="email" [ngClass]="{ dirLtr: _lang.direction == 'ltr' }">
        <figure
          (click)="resetPassForm = true; verifyPassForm = false; cancel('edit')"
        >
          <img
            src="../../../assets/images/rename_icon.png"
            [alt]="'login.reset-password' | translate"
          />
        </figure>
        <span>
          {{ resetPassData.resetEmail.toLowerCase() }}
        </span>
      </div>
    </div>
    <p class="headline7">
      {{ "login.five-digit-code-has-been-sent-2" | translate }}
    </p>
    <code-input
      #codeInput
      [codeLength]="5"
      [dir]="'ltr'"
      (codeChanged)="this.resetPassData.verificationCode = $event"
    >
    </code-input>
    <!-- resend otp  -->
    <div>
      <span
        *ngIf="isRsendOTP"
        class="form__resend"
        (click)="resendOTP()"
        [class.disabled]="!isRsendOTP"
      >
        <mat-icon class="material-icons-outlined"> replay </mat-icon>
        <span> {{ "login.resend-code" | translate }} </span>
      </span>

      <span *ngIf="!isRsendOTP" class="form__resend info">
        <mat-icon class="material-icons-outlined">info</mat-icon>
        <span>
          {{ "login.will-resend-code" | translate }}
          {{ resendDuration + " : 00" }}
        </span>
      </span>
    </div>
    <div class="form-footer">
      <button
        type="submit"
        mat-flat-button
        color="primary"
        class="main-btn button18 block"
        (click)="validateResetCode()"
        [disabled]="this.resetPassData.verificationCode.length != 5 || loading"
      >
        <app-loading-action
          *ngIf="loading && this.resetPassData.verificationCode.length == 5"
        ></app-loading-action>

        {{ "login.confirm" | translate }}
      </button>

      <button
        type="submit"
        mat-flat-button
        color="primary"
        class="main-btn button18 block cancel"
        (click)="
          signUpForm = false;
          resetPassForm = false;
          verifyPassForm = false;
          newPassForm = false;
          cancel()
        "
      >
        {{ "account.cancel" | translate }}
      </button>
    </div>

    <!-- <mat-error>{{ "validation.pass-not-correct" | translate }}</mat-error> -->
  </div>
  <!-- end verifyPassword form -->

  <!-- start verifyPassword form -->
  <div *ngIf="newPassForm" class="form-container reset-password-container">
    <div class="form-header">
      <a
        (click)="verifyPassForm = true; newPassForm = false"
        [ngClass]="{ dirLtr: _lang.direction == 'ltr' }"
      >
        <mat-icon class="arrow-dir">arrow_back</mat-icon>
      </a>
      <div>
        <img src="assets/images/logo-icon.png" alt="Halameet" />
        <h3 class="headline2">
          {{ "login.reset-password" | translate }}
        </h3>
      </div>
    </div>

    <form #passwordForm="ngForm" (ngSubmit)="updateUserPassword()">
      <div class="form-input">
        <mat-label
          >{{ "account.new-pass" | translate }}
          <span class="label__required">*</span>
          <div class="tooltip">
            <mat-icon class="material-icons-outlined info"> info</mat-icon>
            <span
              class="tooltiptext"
              [ngStyle]="{
                visibility: wrongPassCriteria ? 'visible' : ''
              }"
            >
              <span class="custom-tooltip">
                {{ "tooltip.password-criteria" | translate }}
              </span>
              <br />
              <mat-icon class="close-icon" *ngIf="!passwordError.length"
                >close</mat-icon
              >
              <mat-icon class="success-icon" *ngIf="passwordError.length"
                >check</mat-icon
              >
              {{ "validation.pass-char" | translate }}
              <br />
              <mat-icon class="close-icon" *ngIf="!passwordError.english"
                >close</mat-icon
              >
              <mat-icon class="success-icon" *ngIf="passwordError.english"
                >check</mat-icon
              >
              {{ "validation.pass-eng" | translate }}
              <br />
              <mat-icon class="close-icon" *ngIf="!passwordError.number"
                >close</mat-icon
              >
              <mat-icon class="success-icon" *ngIf="passwordError.number"
                >check</mat-icon
              >
              {{ "validation.pass-num" | translate }}
              <br />
              <mat-icon class="close-icon" *ngIf="!passwordError.special"
                >close</mat-icon
              >
              <mat-icon class="success-icon" *ngIf="passwordError.special"
                >check</mat-icon
              >
              {{ "validation.pass-special" | translate }}
              <br />
              <mat-icon class="close-icon" *ngIf="!passwordError.upper"
                >close</mat-icon
              >
              <mat-icon class="success-icon" *ngIf="passwordError.upper"
                >check</mat-icon
              >
              {{ "validation.pass-upper" | translate }}
            </span>
          </div>
        </mat-label>

        <mat-form-field
          class="bodytext-normal"
          block
          appearance="fill"
          color="primary"
        >
          <input
            [type]="isNewPasswordHidden ? 'password' : 'text'"
            name="newPassword"
            #newPassword="ngModel"
            [(ngModel)]="passFormData.newPassword"
            minlength="8"
            maxlength="50"
            matInput
            pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_+#?!@$%^&*-]).{8,}$"
            required
            (capsLock)="capsOn = $event"
            (blur)="
              !newPassword?.valid && newPassword.touched
                ? (wrongPassCriteria = true)
                : (wrongPassCriteria = false)
            "
            (focus)="wrongPassCriteria = false"
            (input)="checkPasswordPattern(passFormData.newPassword)"
          />
          <!-- <mat-error>{{ "validation.pass-not-correct" | translate }}</mat-error> -->
          <button
            class="toggle-pass bodytext2"
            type="button"
            mat-icon-button
            matSuffix
            (click)="toggleDisplayPassword($event, 'newPasswordHide')"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="isNewPasswordHidden"
          >
            <mat-icon class="material-icons-outlined">{{
              isNewPasswordHidden ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
        </mat-form-field>
        <!-- <small
          class="mat-error"
          *ngIf="
            !newPassword?.valid &&
            (newPassword.touched || newPassword.dirty) &&
            newPassword.errors &&
            newPassword.errors['required']
          "
        >
          {{ "validation.pass-not-correct" | translate }}
        </small> -->
        <!-- <small
          class="mat-error"
          *ngIf="
            !newPassword?.valid &&
            (newPassword.touched || newPassword.dirty) &&
            newPassword.errors &&
            newPassword.errors['pattern']
          "
        >
          {{ "validation.pass-char" | translate }}
          <br />
          {{ "validation.pass-eng" | translate }}
          <br />
          {{ "validation.pass-num" | translate }}
          <br />
          {{ "validation.pass-special" | translate }}
          <br />
          {{ "validation.pass-upper" | translate }}
        </small> -->
        <mat-error *ngIf="capsOn" class="caps-on">
          <mat-icon class="material-icons-outlined">info</mat-icon>
          {{ "validation.caps-lock" | translate }}</mat-error
        >
      </div>

      <div class="form-input">
        <mat-label
          >{{ "login.new-confirm-pass" | translate }}
          <span class="label__required">*</span>
        </mat-label>
        <mat-form-field
          class="bodytext-normal"
          block
          appearance="fill"
          color="primary"
        >
          <input
            [type]="isConfrimPasswordHidden ? 'password' : 'text'"
            name="passConfirmation"
            #passConfirmation="ngModel"
            [(ngModel)]="passFormData.passConfirmation"
            [comparePassword]="newPassword"
            minlength="8"
            maxlength="50"
            matInput
            required
            autocomplete="off"
          />
          <!-- <mat-error>{{ "validation.pass-not-match" | translate }}</mat-error> -->
          <button
            class="toggle-pass bodytext2"
            type="button"
            mat-icon-button
            matSuffix
            (click)="toggleDisplayPassword($event, 'confrimPassword')"
            [attr.aria-label]="'Hide password'"
            [attr.aria-pressed]="isConfrimPasswordHidden"
          >
            <mat-icon class="material-icons-outlined">{{
              isConfrimPasswordHidden ? "visibility_off" : "visibility"
            }}</mat-icon>
          </button>
        </mat-form-field>
        <small
          class="mat-error"
          *ngIf="
            !passConfirmation?.valid &&
            (passConfirmation.touched || passConfirmation.dirty) &&
            passConfirmation.errors
          "
        >
          {{ "validation.pass-not-match" | translate }}
        </small>
      </div>

      <div class="form-footer">
        <button
          type="submit"
          mat-flat-button
          color="primary"
          class="main-btn button18 block"
          [disabled]="!passwordForm.form.valid || loading"
        >
          <app-loading-action *ngIf="loading"></app-loading-action>

          {{ "account.save" | translate }}
        </button>
        <button
          type="submit"
          mat-flat-button
          color="primary"
          class="main-btn button18 block cancel"
          (click)="
            signUpForm = false;
            resetPassForm = false;
            verifyPassForm = false;
            newPassForm = false;
            cancel()
          "
        >
          {{ "account.cancel" | translate }}
        </button>
      </div>
    </form>

    <!-- <mat-error>{{ "validation.pass-not-correct" | translate }}</mat-error> -->
  </div>
  <!-- end verifyPassword form -->

  <!-- <div class="card-img-container" fxHide.lt-md>
    <img src="assets/images/working.svg" />
  </div> -->
</section>
<!-- End auth actions -->

<!-- TODO: add new password sent page -->
<!-- Start auth action -->
<!-- <section *ngIf="isNewPassword" class="auth-card"></section> -->
<!-- End auth action -->
