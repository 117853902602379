import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { KortobaaMeetService } from '@kortobaa-front/ng-kmeet-sdk';

@Component({
  selector: 'app-mute-all-dialog',
  templateUrl: './mute-all-dialog.component.html',
  styleUrls: ['./mute-all-dialog.component.scss'],
})
export class MuteAllDialogComponent implements OnInit {
  constructor(
    private _router: Router,
    public kortobaaPkg: KortobaaMeetService,
    public dialogRef: MatDialogRef<MuteAllDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  isMember = false;

  ngOnInit(): void {
    if (this.data.type == 'member') {
      this.isMember = true;
    }
  }

  mute() {
    if (this.isMember) {
      this.dialogRef.close({ event: 'member' });
    } else {
      this.dialogRef.close({ event: 'muteAll' });
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
