<div
  class="scheduled-meeting-headings"
  *ngIf="scheduledMeetingsList.length > 0"
>
  <h3 class="headline7">
    {{ "scheduled-meetings" | translate }}
  </h3>
  <!-- <a
  *ngIf="scheduledMeetingCount"
  type="submit"
  mat-flat-button
  color="primary"
  class="headline7"
  routerLink="/profile"
  [state]="{ activeContent: 'scheduledMeeting' }"
>
  {{ "show-all" | translate }}
</a> -->
  <button
    cdkScrollable
    #tooltip="matTooltip"
    [matTooltip]="'schedule-ameeting' | translate"
    matTooltipPosition="above"
    class="schedule-meeting-dialog"
    routerLink="/schedule-new-meeting"
  >
    <img
      src="../../../assets/images/home/add.png"
      [alt]="'schedule-ameeting' | translate"
    />
  </button>
</div>
<aside
  class="custom-scroll scheduled-container"
  (scroll)="checkScroll($event)"
  #upcomingMeetings
  [ngClass]="{ 'home-slider': !scheduledMeetingsList?.length && !pageLoading }"
>
  <div class="loading-container" *ngIf="pageLoading">
    <mat-spinner></mat-spinner>
  </div>
  <div
    class="meeting-container"
    *ngIf="scheduledMeetingsList?.length && !pageLoading"
  >
    <ng-container *ngFor="let meeting of scheduledMeetingsList; let i = index">
      <h3
        class="meeting-title"
        *ngIf="
          i >= 0 &&
          meeting &&
          formatDate(scheduledMeetingsList[i]?.startDate) !=
            formatDate(scheduledMeetingsList[i - 1]?.startDate)
        "
      >
        {{
          formatDate(meeting?.startDate)
            | datePipe : "dddd" : lang.currentLang : true
        }}
        <span class="subtitle2">{{
          formatDate(meeting.startDate) | datePipe : "DD/MM/yyyy" : "en"
        }}</span>
      </h3>

      <div class="meeting-card" [id]="meeting.id" *ngIf="meeting">
        <div class="meeting-card-header">
          <div
            class="meeting-time"
            [ngClass]="{ dirLtr: lang.direction == 'ltr' }"
          >
            <h3>
              {{ formatTime(meeting.startDate, lang.currentLang) }}
            </h3>

            <h6>
              {{
                (
                  meeting.startDate
                  | durationTime : meeting.expectedEndDate : lang.currentLang
                )?.includes("1ساعة")
                  ? (
                      meeting.startDate
                      | durationTime
                        : meeting.expectedEndDate
                        : lang.currentLang
                    )?.replace("1ساعة", "ساعة")
                  : (
                      meeting.startDate
                      | durationTime
                        : meeting.expectedEndDate
                        : lang.currentLang
                    )?.includes("2 ساعات")
                  ? (
                      meeting.startDate
                      | durationTime
                        : meeting.expectedEndDate
                        : lang.currentLang
                    )?.replace("2 ساعات", "ساعتان")
                  : (meeting.startDate
                    | durationTime : meeting.expectedEndDate : lang.currentLang)
              }}
            </h6>
            <!-- <h6>
              {{
                meeting.expectedEndDate
                  ? (meeting.startDate
                    | durationTime : meeting.expectedEndDate : lang.currentLang)
                  : ""
              }}
            </h6> -->
          </div>

          <div class="meeting-card-info">
            <p class="meeting-card-title">{{ meeting.title }}</p>
            <!-- <p class="subtitle2">{{ formatDate(meeting.startDate) }}</p> -->
          </div>

          <!-- <p class="meeting-time">
          <mat-icon>schedule</mat-icon>

          <span>{{ "account.start-at" | translate }}</span>

          {{ formatTime(meeting.startDate, lang.currentLang) }}
        </p> -->

          <div
            class="meeting-card-action"
            [ngClass]="{ dirLtr: lang.direction == 'ltr' }"
          >
            <a
              cdkScrollable
              #tooltip="matTooltip"
              [matTooltip]="'account.edit' | translate"
              matTooltipPosition="above"
              *ngIf="meeting.userId == dataService.getUserData()['id']"
              class="subtitle1 edit"
              routerLink="/schedule-new-meeting"
              [state]="{ meeting: meeting }"
            >
              <figure>
                <img
                  src="../../../../assets/images/home/edit.png"
                  alt="'account.edit'| translate"
                />
                <img
                  class="img_res"
                  src="../../../../assets/images/home/edit_res.svg"
                  alt="'account.edit'| translate"
                />
              </figure>

              <!-- <mat-icon>edit</mat-icon> -->
              <!-- {{ "account.edit" | translate }} -->
            </a>
            <a
              cdkScrollable
              #tooltip="matTooltip"
              [matTooltip]="'account.copy-meeting-url' | translate"
              matTooltipPosition="above"
              (click)="copyMeetingUrl(meeting.token)"
            >
              <figure>
                <img
                  src="../../../../assets/images/home/link.png"
                  alt="'account.copy-meeting-url'| translate"
                />
                <img
                  class="img_res"
                  src="../../../../assets/images/home/link_res.svg"
                  alt="'account.copy-meeting-url'| translate"
                />
              </figure>
              <!-- <mat-icon>link</mat-icon> -->
              <!-- {{ "account.copy-meeting-url" | translate }} -->
            </a>

            <button
              mat-button
              class="action-btn"
              (click)="
                startScheduledMeetingNow(
                  meeting.token,
                  meeting.locked,
                  meeting.password,
                  meeting.excludeLink
                )
              "
            >
              {{ "meeting-room.join" | translate }}
            </button>
          </div>
        </div>

        <!-- <mat-divider></mat-divider> -->
        <!-- <div class="meeting-acions subtitle1">
        <p class="meeting-time">
          <mat-icon>schedule</mat-icon>

          <span>{{ "account.start-at" | translate }}</span>

          {{ formatTime(meeting.startDate, lang.currentLang) }}
        </p>

        <a (click)="copyMeetingUrl(meeting.token)">
          <mat-icon>link</mat-icon>
          {{ "account.copy-meeting-url" | translate }}
        </a>

        <a
          *ngIf="meeting.userId == dataService.getUserData()['id']"
          class="subtitle1"
          routerLink="/schedule-new-meeting"
          [state]="{ meeting: meeting }"
        >
          <mat-icon>edit</mat-icon>
          {{ "account.edit" | translate }}
        </a>

        <a
          *ngIf="meeting.userId == dataService.getUserData()['id']"
          class="cancel-meeting"
          (click)="cancelScheduledMeeting(meeting.id)"
        >
          <mat-icon>close</mat-icon>
          {{ "account.cancel-meeting" | translate }}
        </a>
      </div> -->
      </div>
    </ng-container>
  </div>
  <div
    class="no-meeting-container"
    *ngIf="!scheduledMeetingsList?.length && !pageLoading"
  >
    <!-- <img src="assets/images/no-content.png" />
<p class="headline7">
{{ "account.no-upcoming-meetings-text" | translate }}
</p> -->
    <app-home-slider></app-home-slider>
  </div>
  <a
    *ngIf="
      !isBottom && scheduledMeetingsListCount != scheduledMeetingsList.length
    "
    class="show-more"
    (click)="showMore()"
  >
    {{ "meeting-room.show-more" | translate }}
  </a>
</aside>
<div
  *ngIf="scheduledMeetingsList?.length > 0"
  [ngClass]="{ 'indicator-bottom': isBottom, 'indicator-top': isTop }"
>
  <!-- go to top to first meeting  -->
  <figure class="top" (click)="scrollToTop()" *ngIf="isTop && !isBottom">
    <img src="../../../../assets/images/home/back.svg" alt="back" />
  </figure>
  <!-- spinner loader 10 meeting  -->
  <app-spinner
    [scale]="0.3"
    class="spinner"
    *ngIf="
      isBottom && scheduledMeetingsListCount >= scheduledMeetingsList.length
    "
  ></app-spinner>
</div>
