import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '@kortobaa-front/authentication';

@Component({
  selector: 'app-logout',
  template: 'Logout Success!',
})
export class LogoutComponent {
  constructor(
    private _router: Router,
    private _kortobaaPkgUserService: UserService
  ) {
    this._kortobaaPkgUserService.logout();
    this._router.navigate(['']);
  }
}
