import { Component } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { MenuService } from 'src/app/ui/layout/menu/menu.service';
import { SidenavService } from 'src/app/ui/layout/sidenav.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  menuData: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(
    private _menuService: MenuService,
    private _sidenav: SidenavService
  ) {}

  ngOnInit() {
    this.menuData = this._menuService.menuData$;
  }

  toggleRightSidenav() {
    this._sidenav.toggle();
  }
}
