<div class="leave-meeting">
  <div class="header">
    <h2>{{ "meeting-room.end-meeting" | translate }}</h2>
    <a class="close-icon" (click)="onNoClick()">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <p>
    {{ "meeting-room.leave-question" | translate }}
  </p>
  <div class="leave-action">
    <button
      *ngIf="kortobaaPkg.isHost"
      mat-flat-button
      class="end-btn"
      block
      (click)="endMeeting()"
    >
      {{ "meeting-room.end-for-all" | translate }}
    </button>
    <button class="leave-btn" mat-flat-button block (click)="leaveMeeting()">
      {{ "meeting-room.leave" | translate }}
    </button>
  </div>
</div>
