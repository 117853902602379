<div class="record-meeting">
  <div class="header">
    <h2>{{ "record.title" | translate }}</h2>
    <a class="close-icon" (click)="onNoClick()">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <p>
    {{ "record.info" | translate }}
  </p>
  <div class="record-action">
    <button mat-flat-button class="cancel-btn" block (click)="onNoClick()">
      {{ "account.cancel" | translate }}
    </button>
    <button class="start-btn" mat-flat-button block (click)="startRecord()">
      {{ "record.action" | translate }}
    </button>
  </div>
</div>
