import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  status: string;
}

@Component({
  selector: 'app-member-removed-dialog',
  templateUrl: './member-removed-dialog.component.html',
  styleUrls: ['./member-removed-dialog.component.scss'],
})
export class MemberRemovedDialogComponent {
  header = 'host-removed-you-header';
  msg = 'host-removed-you';

  constructor(
    public dialogRef: MatDialogRef<MemberRemovedDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    console.log(data);
    if (data.status == 'ban') {
      this.header = 'kicked-header';
      this.msg = "You don't have a permissions, you are kicked from meeting";
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
