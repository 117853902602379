import { Config } from '@kortobaa-front/authentication';
import { Configuration } from '@kortobaa-front/ng-kmeet-sdk';
import { environment } from 'src/environments/environment';

class PackagesConfigClass {
  config = (window as any).appConfig;
  href = new URL(window.location.href);
  hostUrl = environment.production ? (this.config?.host + "/") : environment.url;
  apiUrl = this.hostUrl + 'api/';
  kortobaaAuthPkgConfig: Config = {
    API_URL: this.apiUrl,
  };
  kortobaaMeetingPkgConfig: Configuration = {
    encoding: {
      AES_KEY: environment.AES_KEY,
      ENC_MODE: environment.ENC_MODE,
      ENC_TYPE: environment.ENC_TYPE,
      IV_LENGTH: environment.IV_LENGTH,
    },
    TCP_iceServers: environment.TCP_iceServers,
    timeZone: environment.timeZone,
    APIHost: this.apiUrl,
    host: this.hostUrl,
  };

  loadConfig() {
    this.config = (window as any).appConfig;
    this.hostUrl = environment.production ? (this.config?.host + "/") : environment.url;
    this.apiUrl = this.hostUrl + 'api/';
    this.kortobaaMeetingPkgConfig.APIHost = this.apiUrl;
    this.kortobaaMeetingPkgConfig.host = this.hostUrl;
    this.kortobaaAuthPkgConfig.API_URL = this.apiUrl;
  }
}
const PackagesConfig = new PackagesConfigClass();
export { PackagesConfig };
/*const href = new URL(window.location.href);
export const hostUrl = environment.production ? (config.host + "/") : environment.url;
const apiUrl = hostUrl + 'api/';

export const kortobaaAuthPkgConfig: Config = {
  API_URL: apiUrl,
};

export const kortobaaMeetingPkgConfig: Configuration = {
  encoding: {
    AES_KEY: environment.AES_KEY,
    ENC_MODE: environment.ENC_MODE,
    ENC_TYPE: environment.ENC_TYPE,
    IV_LENGTH: environment.IV_LENGTH,
  },
  TCP_iceServers: environment.TCP_iceServers,
  timeZone: environment.timeZone,
  APIHost: apiUrl,
  host: hostUrl,
};*/
