import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';

import { JSEncrypt } from 'jsencrypt';

import { DataService } from 'src/app/shared/services/data.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class ServerService {
  private _headers: HttpHeaders;
  $encrypt: any;

  constructor(private _http: HttpClient, private _dataService: DataService) {
    this.$encrypt = new JSEncrypt({ default_key_size: 2048 as any });
    this.$encrypt.setPublicKey(environment.SERVER_PUBLIC_KEY);
  }

  checkUserAttempts(email: string) {
    const url = `${this._dataService.apiHost}checkUser`;
    this._headers = new HttpHeaders({
      'Content-Type': 'application/json',
      encryption: this.$encrypt.encrypt(environment.API_KEY),
    });
    return this._http.post(url, { email }, { headers: this._headers });
  }

  attempFail(email: string) {
    const url = `${this._dataService.apiHost}userFail`;
    this._headers = new HttpHeaders({
      'Content-Type': 'application/json',
      encryption: this.$encrypt.encrypt(environment.API_KEY),
    });
    return this._http.post(url, { email }, { headers: this._headers });
  }
}
