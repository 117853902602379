import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  passCode: string;
}

@Component({
  selector: 'app-meeting-pass-code',
  templateUrl: './meeting-pass-code.component.html',
  styleUrls: ['./meeting-pass-code.component.scss'],
})
export class MeetingPassCodeComponent {
  meetingPassCode = '';
  islPasswordHidden = true;

  constructor(
    public dialogRef: MatDialogRef<MeetingPassCodeComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (this.data.password) {
      this.dialogRef.close(this.data.password);
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  joinMeeting() {
    this.dialogRef.close(this.meetingPassCode);
  }

  toggleDisplayPassword(e: PointerEvent, inputName: string) {
    e.stopPropagation();
    switch (inputName) {
      case 'meetingPassword':
        return (this.islPasswordHidden = !this.islPasswordHidden);
    }
  }
}
