import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from 'src/app/meeting/allow-media/allow-media.component';

@Component({
  selector: 'app-check-not-save',
  templateUrl: './check-not-save.component.html',
  styleUrls: ['./check-not-save.component.scss'],
})
export class CheckNotSaveComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<CheckNotSaveComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}
  ngOnInit() {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  accept(boolean: boolean = true) {
    this.dialogRef.close(boolean);
  }
}
