<div
  class="nav-res"
  *ngIf="oldMeeting"
  [ngStyle]="{ display: oldMeeting && screenWidth >= 960 ? 'none' : 'flex' }"
  [ngClass]="{ english: _lang.direction == 'ltr' }"
>
  <div class="drawer-icon-container">
    <span>
      <mat-icon
        [ngClass]="{ dirLtr: _lang.direction == 'ltr' }"
        class="back-icon"
        (click)="backToHome()"
      >
        arrow_back
      </mat-icon>
    </span>
    <a routerLink="/" class="menu-logo"> </a>
  </div>
  <button
    cdkScrollable
    #tooltip="matTooltip"
    [matTooltip]="'meeting-room.more' | translate"
    matTooltipPosition="above"
    mat-button
    [matMenuTriggerFor]="belowMenu"
    class="more"
  >
    <mat-icon class="material-icons-outlined"> more_vert</mat-icon>
  </button>
  <mat-menu class="more_menu" #belowMenu="matMenu" yPosition="below">
    <button mat-menu-item (click)="removeMeetingDialog()">
      {{ "account.remove-meeting" | translate }}
    </button>
    <button mat-menu-item (click)="copyMeetingUrl(oldMeeting.token)">
      {{ "account.copy-meeting-url" | translate }}
    </button>
  </mat-menu>
</div>

<section
  class="schedule-form-container"
  [ngClass]="{ dirLtr: _lang.direction == 'ltr' }"
>
  <a class="brand">
    <figure routerLink="/">
      <img src="assets/images/logo.svg" />
    </figure>
  </a>
  <!-- start schedule a new meeting form -->
  <form
    class="schedule-form"
    #scheduleMeetingForm="ngForm"
    autocomplete="off"
    [ngClass]="{ dirLtr: _lang.direction == 'ltr' }"
  >
    <!-- start form right side -->
    <div class="flex-center">
      <div class="form-tap">
        <!-- back button  -->
        <a
          (click)="backToHome()"
          [ngClass]="{ dirLtr: _lang.direction == 'ltr' }"
        >
          <mat-icon class="arrow-dir">keyboard_backspace</mat-icon>
        </a>
        <!-- meeting template -->
        <!--<div class="form-input">
          <mat-label>{{ "account.meeting-templates" | translate }}</mat-label>
          <div class="tooltip">
            <mat-icon class="material-icons-outlined info"> info</mat-icon>
            <span class="tooltiptext">
              <span class="custom-tooltip">
                {{ "tooltip.schedule-meeting-title" | translate }}
              </span>
              {{ "tooltip.schedule-meeting-info" | translate }}
            </span>
          </div>
          <mat-form-field
            class="bodytext-normal full-width"
            block
            appearance="fill"
            color="primary"
          >
            <input
              type="text"
              [formControl]="templateControl"
              (change)="addTemplate($event.target.value)"
              matInput
              [matAutocomplete]="autoTemplate"
              [placeholder]="'meeting-side.write-select-here' | translate"
              onblur="this.value=this.value.trim()"
              maxlength="70"
            />
            <mat-icon class="expand">expand_more</mat-icon>

            <mat-autocomplete #autoTemplate="matAutocomplete">
              <mat-option
                (click)="setMeetingTemplate(template.templateName)"
                class="bodytext2"
                *ngFor="let template of filteredMeetingTemplates | async"
                [value]="template.templateName"
              >
                {{ template.templateName }}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>
        </div>-->

        <!-- meeting title -->
        <div class="form-input">
          <mat-label>{{ "meeting-side.meeting-title" | translate }}</mat-label>
          <mat-form-field
            class="bodytext-normal"
            block
            appearance="fill"
            color="primary"
          >
            <input
              type="text"
              name="meetingTitle"
              #meetingtitle="ngModel"
              [(ngModel)]="scheduleMeetingFormData.meetingTitle"
              matInput
              required
              pattern="^[^\s].+[^\s]$"
              onblur="this.value=this.value.trim()"
              maxlength="30"
              autocomplete="off"
            />
          </mat-form-field>
          <small
            class="message-error error-title"
            *ngIf="
              scheduleMeetingForm.form.controls['meetingTitle']?.touched &&
              scheduleMeetingForm.form.controls['meetingTitle'].errors
            "
            >{{ "validation.meeting-title-required" | translate }}</small
          >
        </div>
        <!--
        <div class="meeting-title">
          <input
            type="text"
            name="meetingTitle"
            #meetingtitle="ngModel"
            [placeholder]="'meeting-side.meeting-title' | translate"
            [(ngModel)]="scheduleMeetingFormData.meetingTitle"
            matInput
            required
            autocomplete="off"
          />
          <a
            [ngClass]="{ dirLtr: _lang.direction == 'ltr' }"
            *ngIf="this.scheduleMeetingFormData.meetingTitle?.length > 0"
            class="close-icon"
            (click)="this.scheduleMeetingFormData.meetingTitle = ''"
          >
            <mat-icon>close</mat-icon>
          </a>
          <small
            class="message-error"
            *ngIf="
              scheduleMeetingForm.form.controls['meetingTitle']?.touched &&
              scheduleMeetingForm.form.controls['meetingTitle'].errors
            "
          >
            {{ "validation.meeting-title-required" | translate }}</small
          >
        </div> -->

        <!-- meeting date -->
        <div class="schedule-form-date">
          <h5 class="date_res">
            {{ "start-from" | translate }}
          </h5>

          <div class="start date-form">
            <div class="time-form-field" block color="primary">
              <mat-label>{{ "meeting-date" | translate }}</mat-label>
              <div>
                <input
                  [min]="dateFrom"
                  matInput
                  matInput
                  placeholder="{{ 'meeting-format' | translate }}"
                  [matDatepicker]="datePicker1"
                  name="meetingDate"
                  #meetingDate="ngModel"
                  [(ngModel)]="scheduleMeetingFormData.meetingDate"
                  matInput
                  autocomplete="off"
                  required
                  (click)="datePicker1.open()"
                  readonly
                  (dateChange)="autoSelectEndDate($event)"
                />
                <mat-datepicker-toggle matIconSuffix [for]="datePicker1">
                  <mat-icon matDatepickerToggleIcon
                    >keyboard_arrow_down</mat-icon
                  >
                </mat-datepicker-toggle>
                <mat-datepicker #datePicker1> </mat-datepicker>
              </div>
              <small
                class="message-error"
                *ngIf="
                  scheduleMeetingForm.form.controls['meetingDate']?.touched &&
                  scheduleMeetingForm.form.controls['meetingDate'].errors
                "
                >{{ "validation.meeting-date-required" | translate }}</small
              >
            </div>

            <div class="time-form-field" block color="primary">
              <mat-label>{{ "meeting-time" | translate }}</mat-label>

              <div>
                <mat-select
                  name="meetingStartTime"
                  #meetingStartTime="ngModel"
                  required
                  [(ngModel)]="scheduleMeetingFormData.meetingStartTime"
                  (selectionChange)="checkTimeValidity()"
                >
                  <mat-option
                    *ngFor="let time of hoursArr; let i = index"
                    [value]="time"
                    [disabled]="i < disabledIndex"
                  >
                    {{ time }}
                  </mat-option>
                </mat-select>
              </div>
              <small
                class="message-error"
                *ngIf="
                  (scheduleMeetingForm.form.controls['meetingStartTime']
                    ?.touched &&
                    scheduleMeetingForm.form.controls['meetingStartTime']
                      .errors) ||
                  timeCheck
                "
              >
                {{
                  "validation.meeting-start-time-required" | translate
                }}</small
              >
            </div>
          </div>

          <!-- <p class="subtitle1">{{ "account.to" | translate }}</p> -->
          <mat-divider></mat-divider>

          <h5 class="date_res">
            {{ "meeting-duration-title" | translate }}
          </h5>

          <div class="start">
            <div class="time-form-field" block color="primary">
              <mat-label>{{ "hour" | translate }}</mat-label>
              <div>
                <mat-select
                  name="meetingHours"
                  #meetingHours="ngModel"
                  required
                  [(ngModel)]="scheduleMeetingFormData.hour"
                >
                  <mat-option
                    *ngFor="let _ of [].constructor(24); let time = index"
                    [value]="time"
                  >
                    {{ time + " " + ("hour" | translate) }}
                  </mat-option>
                </mat-select>
                <!-- <input
                  matInput
                  placeholder="{{ 'meeting-format' | translate }}"
                  name="meetingDate"
                  #meetingDate="ngModel"
                  [(ngModel)]="scheduleMeetingFormData.hour"
                  matInput
                  autocomplete="off"
                  required
                  readonly
                /> -->
              </div>
              <small
                class="message-error"
                *ngIf="
                  scheduleMeetingForm.form.controls['meetingHours']?.touched &&
                  scheduleMeetingForm.form.controls['meetingHours'].errors
                "
                >{{
                  "validation.meeting-start-time-required" | translate
                }}</small
              >
            </div>

            <div class="time-form-field" block color="primary">
              <mat-label>{{ "min" | translate }}</mat-label>

              <div>
                <mat-select
                  name="meetingMins"
                  #meetingMins="ngModel"
                  required
                  [(ngModel)]="scheduleMeetingFormData.min"
                >
                  <mat-option *ngFor="let time of minsArr" [value]="time">
                    {{ time + " " + ("min" | translate) }}
                  </mat-option>
                </mat-select>
              </div>
              <small
                class="message-error"
                *ngIf="
                  (scheduleMeetingForm.form.controls['meetingMins']?.touched &&
                    scheduleMeetingForm.form.controls['meetingMins'].errors) ||
                  timeCheck
                "
              >
                {{
                  "validation.meeting-start-time-required" | translate
                }}</small
              >

              <span
                class="current-time"
                *ngIf="
                  scheduleMeetingFormData.min !== '' &&
                  scheduleMeetingFormData.hour !== ''
                "
              >
                {{
                  ("meeting-duration" | translate) +
                    (scheduleMeetingFormData.hour +
                      " " +
                      ("hour" | translate)) +
                    ("and" | translate) +
                    (scheduleMeetingFormData.min + " " + ("min" | translate))
                }}</span
              >
            </div>
          </div>

          <mat-divider></mat-divider>

          <!-- <img
            class="arrow"
            [ngClass]="{ dirLtr: _lang.direction == 'ltr' }"
            src="../../../../assets/images/arrow-calender.png"
            [alt]="'account.to' | translate"
          />
          <h5 class="date_res">
            {{ "end-with" | translate }}
          </h5>

          <div class="end">
            <div class="time-form-field" block color="primary">
              <mat-label>{{ "meeting-time" | translate }}</mat-label>

              <div>
                <mat-select
                  name="meetingEndTime"
                  #meetingEndTime="ngModel"
                  [(ngModel)]="scheduleMeetingFormData.meetingEndTime"
                  required
                  (selectionChange)="checkTimeValidity(true)"
                >
                  <mat-option
                    *ngFor="let time of hoursArr; let i = index"
                    [value]="time"
                    [disabled]="
                      checkStartingTime() >= i &&
                      !(
                        scheduleMeetingFormData.meetingEndDate >
                        scheduleMeetingFormData.meetingDate
                      )
                    "
                  >
                    {{ time }}
                  </mat-option>
                </mat-select>
              </div>
              <small
                class="message-error"
                *ngIf="
                  (scheduleMeetingForm.form.controls['meetingEndTime']
                    ?.touched &&
                    scheduleMeetingForm.form.controls['meetingEndTime']
                      .errors) ||
                  timeCheck
                "
              >
                {{
                  "validation.meeting-start-time-required" | translate
                }}</small
              >
            </div>

            <div class="time-form-field" block color="primary">
              <mat-label>{{ "meeting-date" | translate }}</mat-label>
              <div>
                <input
                  [min]="
                    scheduleMeetingFormData.meetingDate
                      ? scheduleMeetingFormData.meetingDate
                      : dateFrom
                  "
                  placeholder="{{ 'meeting-format' | translate }}"
                  [matDatepicker]="datePicker2"
                  name="meetingEndDate"
                  #meetingEndDate="ngModel"
                  [(ngModel)]="scheduleMeetingFormData.meetingEndDate"
                  matInput
                  (click)="datePicker2.open()"
                  readonly
                  required
                  (dateChange)="checkTimeValidity(true)"
                />
                <mat-datepicker-toggle matIconSuffix [for]="datePicker2">
                  <mat-icon matDatepickerToggleIcon
                    >keyboard_arrow_down</mat-icon
                  >
                </mat-datepicker-toggle>

                <mat-datepicker #datePicker2> </mat-datepicker>
              </div>
              <small
                class="message-error"
                *ngIf="
                  scheduleMeetingForm.form.controls['meetingEndDate']
                    ?.touched &&
                  scheduleMeetingForm.form.controls['meetingEndDate'].errors
                "
                >{{ "validation.meeting-date-required" | translate }}</small
              >
            </div>
          </div> -->

          <!-- <mat-divider></mat-divider> -->

          <!-- <div class="meeting-period">
            <h5 class="date_res">
              {{ "meeting-period" | translate }}
            </h5>
            <p class="subtitle1">1 ساعة</p>
          </div> -->
        </div>

        <!-- NOTE: DONNOT remove (Postponed features) -->
        <!-- <mat-form-field
            class="bodytext-normal full-width"
            block
            appearance="fill"
            color="primary"
          >
            <mat-label>{{ "account.repeat-meeting" | translate }}</mat-label>
            <mat-select
              class="custom-scroll"
              matInput
              name="repeate"
              #repeate="ngModel"
              [(ngModel)]="scheduleMeetingFormData.repeate"
              required
            >
              <mat-option class="bodytext2" value="never">{{
                "account.never" | translate
              }}</mat-option>
              <mat-option class="bodytext2" value="every day">{{
                "account.every-day" | translate
              }}</mat-option>
              <mat-option class="bodytext2" value="every week">{{
                "account.every-week" | translate
              }}</mat-option>
              <mat-option class="bodytext2" value="every 2 weeks">{{
                "account.every-2-weeks" | translate
              }}</mat-option>
              <mat-option class="bodytext2" value="every month">{{
                "account.every-month" | translate
              }}</mat-option>
              <mat-option class="bodytext2" value="every year">{{
                "account.every-year" | translate
              }}</mat-option>
            </mat-select>
          </mat-form-field> -->

        <!-- time zone -->
        <!-- <div class="form-input">
          <mat-label>{{ "account.time-zone" | translate }}</mat-label>

          <mat-form-field
            class="bodytext-normal full-width"
            block
            appearance="fill"
            color="primary"
          >
            <input
              type="text"
              aria-label="Number"
              matInput
              name="meetingTimezone"
              #meetingTimezone="ngModel"
              [(ngModel)]="scheduleMeetingFormData.meetingTimezone"
              required
              [matAutocomplete]="auto"
              pattern="^[^\s].+[^\s]$"
              onblur="this.value=this.value.trim()"
            />
            <mat-icon class="expand">expand_more</mat-icon>

            <mat-autocomplete #auto="matAutocomplete">
              <mat-option
                class="bodytext2"
                *ngFor="let timeZone of filteredTimeZones | async"
                [value]="timeZone.label"
              >
                {{ timeZone.label }}
              </mat-option>
            </mat-autocomplete>
            <mat-error>{{
              "validation.time-zone-required" | translate
            }}</mat-error>
          </mat-form-field>
        </div> -->
      </div>
    </div>
    <!-- end form right side -->

    <!-- start form left side -->
    <div class="form-tap form-tap-secondary">
      <div
        class="editAction"
        [ngClass]="{ dirLtr: _lang.direction == 'ltr' }"
        [ngStyle]="{
          display: screenWidth > 960 && oldMeeting ? 'flex' : 'none'
        }"
      >
        <button
          mat-button
          class="joinButton"
          (click)="
            startScheduledMeetingNow(
              oldMeeting.token,
              oldMeeting.locked,
              oldMeeting.meetingPassword,
              oldMeeting.excludeLink
            )
          "
        >
          {{ "meeting-room.join" | translate }}
        </button>

        <button
          cdkScrollable
          #tooltip="matTooltip"
          [matTooltip]="'meeting-room.more' | translate"
          matTooltipPosition="above"
          mat-button
          [matMenuTriggerFor]="belowMenu"
          class="more"
        >
          <mat-icon class="material-icons-outlined"> more_horiz</mat-icon>
        </button>
        <mat-menu class="more_menu" #belowMenu="matMenu" yPosition="below">
          <button mat-menu-item (click)="removeMeetingDialog()">
            {{ "account.remove-meeting" | translate }}
          </button>
          <button mat-menu-item (click)="copyMeetingUrl(oldMeeting.token)">
            {{ "account.copy-meeting-url" | translate }}
          </button>
        </mat-menu>
      </div>

      <!-- time zone -->
      <div class="form-input">
        <mat-label>{{ "account.time-zone" | translate }}</mat-label>

        <mat-form-field
          class="bodytext-normal full-width"
          block
          appearance="fill"
          color="primary"
        >
          <input
            type="text"
            aria-label="Number"
            matInput
            name="meetingTimezone"
            #meetingTimezone="ngModel"
            [(ngModel)]="scheduleMeetingFormData.meetingTimezone"
            required
            [matAutocomplete]="auto"
            pattern="^[^\s].+[^\s]$"
            onblur="this.value=this.value.trim()"
          />
          <mat-icon class="expand">expand_more</mat-icon>

          <mat-autocomplete #auto="matAutocomplete">
            <mat-option
              class="bodytext2"
              *ngFor="let timeZone of filteredTimeZones | async"
              [value]="timeZone.label"
            >
              {{ timeZone.label }}
            </mat-option>
          </mat-autocomplete>
          <mat-error>{{
            "validation.time-zone-required" | translate
          }}</mat-error>
        </mat-form-field>
      </div>

      <form [formGroup]="rulesForm">
        <!-- emails -->
        <div class="form-input">
          <mat-label>{{ "meeting-room.add-guests" | translate }}</mat-label>

          <mat-form-field
            class="bodytext-normal full-width fisrt-form-field"
            appearance="fill"
            color="primary"
          >
            <input
              matInput
              [matChipInputFor]="chipList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="addEmail($event)"
              [matChipInputAddOnBlur]="true"
              pattern="^[^\s].+[^\s]$"
              onblur="this.value=this.value.trim()"
            />
          </mat-form-field>
          <mat-error *ngIf="rulesForm.get('emails').hasError('incorrectEmail')">
            {{ "validation.email-not-valid" | translate }}
          </mat-error>
          <mat-chip-list
            class="custom-scroll"
            #chipList
            formArrayName="emails"
            aria-label="email selection"
            [ngStyle]="{
              display: scheduleMeetingFormData.inviationList.length
                ? 'block'
                : 'none'
            }"
          >
            <ng-container
              *ngFor="let invite of scheduleMeetingFormData.inviationList"
            >
              <mat-chip
                *ngIf="!invite.invalid"
                [color]="invite.invalid ? 'warn' : ''"
                selected
                name="chips"
                [selectable]="true"
                [removable]="true"
                (removed)="removeEmail(invite)"
              >
                <div class="chips_content">
                  <img
                    src="../../../assets/images/email-img.png"
                    [alt]="invite.value"
                  />

                  {{ invite.value }}
                </div>
                <mat-icon matChipRemove>close</mat-icon>
              </mat-chip>
            </ng-container>
          </mat-chip-list>
        </div>
      </form>
      <div class="form-tap form-tap-secondary">
        <!-- NOTE: DONNOT remove (Postponed features) -->
        <!-- <mat-checkbox
            class="subtitle2"
            color="accent"
            name="muteParticipants"
            #muteParticipants="ngModel"
            [(ngModel)]="scheduleMeetingFormData.muteParticipants"
          >
            {{ "account.mute-members-upon-join" | translate }}
          </mat-checkbox>

          <mat-checkbox
            class="subtitle2"
            color="accent"
            name="recordMeeting"
            #recordMeeting="ngModel"
            [(ngModel)]="scheduleMeetingFormData.recordMeeting"
          >
            {{ "meeting-room.record-meeting" | translate }}
          </mat-checkbox> -->

        <!-- <mat-checkbox
          class="subtitle2"
          color="accent"
          name="allowParticipantsToJoinBeforeHost"
          #allowParticipantsToJoinBeforeHost="ngModel"
          [(ngModel)]="
            scheduleMeetingFormData.allowParticipantsToJoinBeforeHost
          "
        >
          {{ "account.allow-join-before-host" | translate }}
        </mat-checkbox> -->

        <mat-slide-toggle
          name="allowParticipantsToJoinBeforeHost"
          class="custom-slide-toggle"
          [(ngModel)]="
            scheduleMeetingFormData.allowParticipantsToJoinBeforeHost
          "
          color="primary"
        >
          <label class="subtitle2">
            {{ "account.allow-join-before-host" | translate }}
          </label>
        </mat-slide-toggle>
        <!-- <mat-checkbox
          class="subtitle2"
          color="accent"
          name="excludeLink"
          #excludeLink="ngModel"
          [(ngModel)]="scheduleMeetingFormData.excludeLink"
        >
          {{ "account.exclude-the-pass-from-invite-link" | translate }}
        </mat-checkbox> -->

        <mat-slide-toggle
          name="waitingRoom"
          class="custom-slide-toggle"
          [(ngModel)]="scheduleMeetingFormData.waitingRoom"
          color="primary"
        >
          <label class="subtitle2">
            {{ "settings.enable-waiting-room" | translate }}
          </label>
        </mat-slide-toggle>

        <mat-slide-toggle
          name="excludeLink"
          class="custom-slide-toggle"
          [checked]="hasPassword"
          (toggleChange)="changeToggle()"
          color="primary"
        >
          <label class="subtitle2">
            {{ "account.exclude-the-pass-from-invite-link" | translate }}
          </label>
        </mat-slide-toggle>

        <!--
          <mat-slide-toggle
          color="primary"
          class="custom-slide-toggle"
          [(ngModel)]="personalRoom.isWaiting"
        >
          <label class="select-label">{{
            "account.enable-waiting-room" | translate
          }}</label>
        </mat-slide-toggle> -->

        <div
          class="form-input pass-form"
          [ngClass]="{ 'old-meeting': oldMeeting }"
          [ngStyle]="{
            display: hasPassword ? 'block' : 'none'
          }"
        >
          <mat-label
            >{{ "account.pass" | translate }}
            <div class="tooltip">
              <mat-icon
                (click)="wrongPassCriteria = !wrongPassCriteria"
                class="material-icons-outlined info"
              >
                info</mat-icon
              >
              <span
                class="tooltiptext"
                [ngStyle]="{
                  visibility: wrongPassCriteria ? 'visible' : ''
                }"
              >
                <span class="custom-tooltip">
                  {{ "tooltip.password-criteria" | translate }}
                </span>
                <br />
                <mat-icon
                  class="close-icon"
                  *ngIf="scheduleMeetingFormData.meetingPassword.length == 0"
                  >close</mat-icon
                >
                <mat-icon
                  class="success-icon"
                  *ngIf="scheduleMeetingFormData.meetingPassword.length > 0"
                  >check</mat-icon
                >
                {{ "validation.meeting-password" | translate }}

                <br />
                <mat-icon
                  class="close-icon"
                  *ngIf="
                    !passIncludeSpace ||
                    scheduleMeetingFormData.meetingPassword.length == 0
                  "
                  >close</mat-icon
                >
                <mat-icon
                  class="success-icon"
                  *ngIf="
                    passIncludeSpace &&
                    scheduleMeetingFormData.meetingPassword.length > 0
                  "
                  >check</mat-icon
                >
                {{ "validation.meeting-password-space" | translate }}
              </span>
            </div></mat-label
          >
          <mat-form-field
            class="bodytext-normal full-width"
            block
            appearance="fill"
            color="primary"
            [ngClass]="{
              'password-error': passwordError
            }"
          >
            <!-- scheduleMeetingFormData.excludeLink && -->
            <input
              [type]="hide ? 'password' : 'text'"
              name="meetingPassword"
              #meetingPassword
              [(ngModel)]="scheduleMeetingFormData.meetingPassword"
              matInput
              [disabled]="!hasPassword"
              minlength="1"
              maxlength="50"
              pattern="^[^\n ]*$"
              onblur="this.value=this.value.trim()"
              (capsLock)="capsLock = $event"
              (input)="passwordInput($event)"
            />

            <small *ngIf="capsLock" class="caps-on">
              <mat-icon class="material-icons-outlined">info</mat-icon>
              {{ "validation.caps-lock" | translate }}</small
            >

            <button
              class="toggle-pass bodytext2"
              type="button"
              mat-icon-button
              matSuffix
              (click)="hide = !hide"
              [attr.aria-label]="'Hide password'"
              [attr.aria-pressed]="hide"
            >
              <mat-icon class="material-icons-outlined">{{
                hide ? "visibility_off" : "visibility"
              }}</mat-icon>
            </button>
          </mat-form-field>

          <mat-checkbox
            class="subtitle2"
            color="primary"
            name="excludeLink"
            #excludeLink="ngModel"
            [(ngModel)]="scheduleMeetingFormData.excludeLink"
          >
            {{ "account.remove-exclude-the-pass-from-invite-link" | translate }}
          </mat-checkbox>
        </div>
      </div>
    </div>
    <!-- end form left side -->
  </form>
  <!-- end schedule a new meeting form -->

  <!-- start schedule a new meeting actions -->
  <div class="form-footer" *ngIf="!oldMeeting">
    <button
      type="submit"
      mat-flat-button
      color="primary"
      class="main-btn button18"
      (click)="scheduleMeeting()"
      [disabled]="
        (scheduleMeetingFormData.hour == 0 &&
          scheduleMeetingFormData.min == 0) ||
        !scheduleMeetingForm.form.valid ||
        timeCheck ||
        (hasPassword && !scheduleMeetingFormData.meetingPassword)
      "
    >
      <app-loading-action *ngIf="loading.schedule"></app-loading-action>

      {{ "schedule-ameeting" | translate }}
    </button>

    <!-- <button
      type="submit"
      mat-button
      class="main-btn button18 save-template-btn"
      (click)="saveCurrentSettings()"
      [disabled]="
        !scheduleMeetingForm.form.valid || loading.schedule || timeCheck
      "
    >
      {{ "account.save-settings-template" | translate }}
    </button> -->
  </div>

  <div class="form-footer edit-meeting" *ngIf="oldMeeting">
    <button
      type="submit"
      mat-flat-button
      color="primary"
      class="main-btn button18"
      (click)="editScheduledMeeting()"
      [disabled]="
        (scheduleMeetingFormData.hour == 0 &&
          scheduleMeetingFormData.min == 0) ||
        !scheduleMeetingForm.form.valid ||
        timeCheck ||
        (hasPassword && !scheduleMeetingFormData.meetingPassword)
      "
    >
      <app-loading-action *ngIf="loading.schedule"></app-loading-action>

      {{ "update-meeting" | translate }}
    </button>

    <!-- <button
      type="submit"
      mat-button
      class="main-btn subtitle2"
      (click)="cancelEditMeeting()"
    >
      {{ "account.save-settings-template" | translate }}
    </button> -->
  </div>
  <!-- end schedule a new meeting actions -->
</section>
