import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

import {
  Register,
  UserService,
  LoginService,
  RegisterService,
  UpdatePasswordData,
  UpdateUserData,
  Login,
} from '@kortobaa-front/authentication';

import { DataService } from 'src/app/shared/services/data.service';

@Injectable()
export class UsersService {
  private headers: HttpHeaders;
  $encrypt: any;

  constructor(
    private _http: HttpClient,
    private _kortobaaPkgRegisterService: RegisterService,
    private _kortobaaPkgUserService: UserService,
    private _kortobaaPkgloginService: LoginService,
    private _dataService: DataService
  ) {}

  async login(data: Login) {
    return await this._kortobaaPkgloginService.login(data);
  }

  async signUp(userData: Register) {
    return await this._kortobaaPkgRegisterService.register(userData);
  }

  //user data from our server
  async getUserSettings(settings = this.defaultSettings()) {
    const token = this._dataService.getUserToken();
    const userData = this._dataService.getUserData();
    this.headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Authorization', 'Bearer ' + token)
      .append('userId', userData['userId'].toString());
    try {
      const userSettings = await this._http
        .post(this._dataService.apiHost + 'user-settings', settings, {
          headers: this.headers,
        })
        .toPromise();
      this._dataService.setUserSettings(userSettings);
      return true;
    } catch (error) {
      return false;
    }
  }

  //update user info
  async updateUserInfo(userData: UpdateUserData) {
    await this._kortobaaPkgUserService.updateUser(userData);
    return this._kortobaaPkgUserService.currentUser;
  }

  async updateUserPassword(passwordData: UpdatePasswordData) {
    await this._kortobaaPkgUserService.updatePassword(passwordData);
  }

  async sendVerificationCode(userEmail: string) {
    const userData = {
      reset: userEmail,
      type: 'email',
    };
    return await this._kortobaaPkgRegisterService.resetPassword(userData);
  }

  async validateResetCode(code: string) {
    return await this._kortobaaPkgRegisterService.verifyResetPassword(code);
  }

  defaultSettings() {
    const defaultSettings = {
      videoId: '',
      audioId: '',
      videoQuality: 'medium',
      videoBandwidth: 800,
      speakerId: '',
    };
    return defaultSettings;
  }

  // verify token to reset password
  verifyResetPassword(token: string, uuid: string) {
    return this._http.post(
      `${environment.url}api/users/verify/reset-change-password`,
      { token, uuid }
    );
  }

  // reset password
  resetPassword(body: any) {
    return this._http.post(
      `${environment.url}api/users/reset-change-password`,
      body
      // this.userToken(),
    );
  }

  // delete account
  deleteAccount() {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Authorization', 'Bearer ' + this._dataService.getUserToken());
    return this._http.delete(`${environment.url}api/users/me`, {
      headers: headers,
    });
  }

  // removeImage
  removeImage() {
    const headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Authorization', 'Bearer ' + this._dataService.getUserToken());
    return this._http.delete(`${environment.url}api/users/me/remove-photo`, {
      headers: headers,
    });
  }
}
