import {
  Component,
  OnInit,
  Inject,
  OnDestroy,
  ViewChildren,
} from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { Platform } from '@angular/cdk/platform';
import { Subscription } from 'rxjs';
import { UserService } from '@kortobaa-front/authentication';
import {
  KortobaaMeetService,
  UserMediaService,
} from '@kortobaa-front/ng-kmeet-sdk';
import { DataService } from 'src/app/shared/services/data.service';
import { UsersService } from 'src/app/auth/services/users.service';
import { ServerService } from 'src/app/shared/services/server.service';
import { LangService } from 'src/app/shared/services/lang.service';
import { MeetingURLService } from 'src/app/shared/services/meeting-url.service';
import { prepareName } from 'src/app/shared/helpers/customFunctions';
import { AllowMediaComponent } from 'src/app/meeting/allow-media/allow-media.component';
import { environment } from 'src/environments/environment';
import { WebrtcService } from 'src/app/shared/services/webrtc.service';
import { MAT_SELECT_CONFIG } from '@angular/material/select';
import { MeetingsService } from '../services/meetings.service';
import { LoginComponent } from 'src/app/auth/login/login.component';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-config-dialog',
  templateUrl: './config-dialog.component.html',
  styleUrls: ['./config-dialog.component.scss'],
  providers: [
    ServerService,
    {
      provide: MAT_SELECT_CONFIG,
      useValue: { overlayPanelClass: 'customPanel' },
    },
  ],
})
export class ConfigDialogComponent implements OnInit, OnDestroy {
  confirmPattern =
    /^(?:[a-zA-Z0-9\s,\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDCF\uFDF0-\uFDFF\uFE70-\uFEFF]|(?:\uD802[\uDE60-\uDE9F]|\uD83B[\uDE00-\uDEFF])){0,25}$/;
  browserCompatibility: boolean = true;
  guestName: string = '';
  guestLoginForm: boolean = false;
  guestRestorePassForm: boolean = false;
  guestNewPassForm: boolean = false;
  otpConfirmForm: boolean = false;
  hide: boolean = true;
  hideSignup = true;
  hideConfirm = true;
  isUDPConnected = false;
  userData = {
    email: '',
    password: '',
    sentCode: '',
    newPass: '',
    otp: '',
    otpId: '',
    rememberMe: true,
  };
  //diaply errors
  errorNotification = '';
  waitingTime;
  attempts = 0;
  capsOn = false;

  attempText = '';
  //for loading buttons
  loading = {
    login: false,
    getData: true,
  };
  //to check if user in mobile
  openAppUi = false;
  allow = {
    micImg: 'assets/icons/mic_off.svg',
    micMessage: 'mic-blocked',
    camImg: 'assets/icons/videocam_off.svg',
    camMessage: 'camera-blocked',
  };
  protocolType = 'Nothing';
  private _latestUnsupportedVersion = 83;
  private _checkUserValidSub: Subscription;

  meetingUrl: any;
  meetingTitle: string;

  registerData = {
    email: '',
    password: '',
    passConfirmation: '',
    userName: '',
    phone: '',
    additionalData: {
      firstName: '',
      lastName: '',
      jobTitle: '',
      company: '',
      country: '',
    },
  };

  wrongPassCriteria = false;
  passwordError = {
    length: false,
    english: false,
    number: false,
    special: false,
    upper: false,
  };

  checkPasswordPattern(password: string) {
    const patternUpper = RegExp('[A-Z]');
    const patternNumber = RegExp('[0-9]');
    const patternSpecial = RegExp('[_+#?!@$%^&*-]');
    const patternEnglish = RegExp('[a-z]|[A-Z]');

    this.passwordError = {
      length: password.length >= 8 ? true : false,
      upper: patternUpper.test(password),
      english: patternEnglish.test(password),
      number: patternNumber.test(password),
      special: patternSpecial.test(password),
    };
  }

  constructor(
    public dialogRef: MatDialogRef<ConfigDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    public dataService: DataService,
    public userMediaService: UserMediaService,
    private platform: Platform,
    private webrtcService: WebrtcService,
    private _kortobaaPkgUserService: UserService,
    private _userService: UsersService,
    private _router: Router,
    private _meetingURL: MeetingURLService,
    public _lang: LangService,
    public kortobaaPkg: KortobaaMeetService,
    private meetingsService: MeetingsService,
    private _translate: TranslateService
  ) {}

  onNoClick(): void {
    // if (!this.userMediaService.isStreamReady) return;
    this.loading.getData = true;
    setTimeout(() => {
      // for loading state
      this.loading.getData = false;
      this.dialogRef.close();
      this._router.navigate(['/home']);
    }, 500);
  }

  async ngOnInit() {
    if (!this.userMediaService.audioStream) {
      this.userMediaService.withAudioAccept = false;
    }

    if (!this.userMediaService.videoStream) {
      this.userMediaService.withVideoAccept = false;
    }

    //clear last meeting info before join
    this.dataService.clearMeetingInfo();
    //check if user open from mobile or tablet

    this.meetingUrl = this._router.url.split('/')[2];
    this.loading.getData = true;
    // this.meetingTitle = this.data.meetingInfo.title;
    this.meetingsService
      .getMeetingDataByUrl('meetings/' + this.meetingUrl)
      .subscribe(async (res: any) => {
        this.meetingTitle = res.title;
        await this.getUserMedia();
      });

    if (this.platform.ANDROID || this.platform.IOS) {
      this.loading.getData = false;
      this.openAppUi = true;
    } else {
      //if not just get user media & combtabilty
      // await this.getUserMedia();
      // If user choose to turn off camera, skip device change events
      this.userMediaService.deviceChangeHandler(
        !this.userMediaService.withVideoAccept
      );
    }
  }

  //fn to open app in mobile with deep-linking
  openApp() {
    window.open(
      'halameet://meet.halameet.com/meeting/' + this.data.meetingToken
    );
  }

  downloadApp() {
    if (this.platform.ANDROID) {
      window.open(environment.PLAY_STORE_URL);
    } else {
      window.open(environment.APP_STORE_URL);
    }
  }

  //close open in app & open user dialog
  // async closeApp() {
  //   this.openAppUi = false;
  //   await this.getUserMedia();
  // }

  //login action for login form
  async login() {
    //start btn loading animation & disable
    this.loading.login = true;
    this.attempts = 0;
    this.waitingTime = null;
    this.errorNotification = '';
    try {
      //lets send login request
      await this._userService.login({
        login: this.userData.email,
        password: this.userData.password,
        rememberMe: this.userData.rememberMe,
      });

      this.closeGuestLoginForm();

      //stop loading btn
      this.loading.login = false;

      // this.getUserMedia();
    } catch (error) {
      this.loading.login = false;
    }
  }

  copyMeetingUrl() {
    this._meetingURL.copyMeetingUrl(this.data.meetingToken);
  }

  //detect any change in voice input
  changeInput(event) {
    //save new selected device id
    this.data.audioDeviceId = event.value;
  }

  openGuestLoginForm() {
    this.guestLoginForm = true;
    // this.dialog.open(LoginComponent, {
    //   width: '70%',
    //   height: '90%',
    //   direction: this._lang.direction,
    //   autoFocus: false,
    // });
  }

  closeGuestLoginForm() {
    this.guestLoginForm = false;
  }

  openGuestRestorePassForm() {
    this.guestLoginForm = false;
    this.guestRestorePassForm = true;
  }

  closeGuestRestorePassForm() {
    this.guestRestorePassForm = false;
    this.guestLoginForm = true;
  }

  sendRestoreCode() {
    this.guestRestorePassForm = false;
    this.guestNewPassForm = true;
    //send restore code functionality goes here
  }

  closeGuestNewPassForm() {
    this.guestNewPassForm = false;
    this.guestRestorePassForm = true;
  }

  async getUserMedia() {
    //check if user is ios or not chrom or chrome but less than version 81
    /*if (
      this.platform.IOS ||
      !(platform.name.toLowerCase() == 'chrome') ||
      (platform.name.toLowerCase() == 'chrome' &&
        Number(getBrowserVersion()) < this._latestUnsupportedVersion)
    )
      return (this.browserCompatibility = false); //if yes just pop not combtability device*/

    const checkConnection = await this.webrtcService.checkRtcConnection();
    if (checkConnection) {
      this.isUDPConnected = true;
      this.protocolType = checkConnection.type;
    }

    try {
      // if (this.userMediaService.withVideoAccept) {
      await this.userMediaService.startStream(true);
      // }
    } catch (error) {
      this.checkPermission();
    }

    // Check if user is vaild
    this._kortobaaPkgUserService.checkUserValid().then((res) => {
      this._checkUserValidSub = res.subscribe((isUserValid) => {
        // console.log(isUserValid);
        if (isUserValid) {
          const userData = this.dataService.getUserData();
          if (userData) {
            // Get name if user is logged in already
            this.data['name'] = userData.username;
            prepareName(this.data.name);
          }
        }
        this.loading.getData = false;
      });
    });
  }

  // check permission browser
  async checkPermission() {
    let dialogRef: MatDialogRef<AllowMediaComponent, any>;

    if (
      this.userMediaService.isInputDisabled ||
      this.userMediaService.isVideoDisabled
    ) {
      // Show an allow media dialog for user if his microphone or camera are blocked instead of browser alert
      dialogRef = this.dialog.open(AllowMediaComponent, {
        data: {
          title:
            this.userMediaService.isVideoDisabled &&
            this.userMediaService.isInputDisabled
              ? 'cam-voice-off'
              : this.userMediaService.isVideoDisabled
              ? 'cam-off'
              : this.userMediaService.isInputDisabled
              ? 'mic-off'
              : 'cam-voice-off',
          msg:
            this.userMediaService.isInputDisabled &&
            this.userMediaService.isVideoDisabled
              ? 'open-cam-voice'
              : this.userMediaService.isVideoDisabled
              ? 'open-cam'
              : this.userMediaService.isInputDisabled
              ? 'open-mic'
              : 'open-cam-voice',
        },
        width: '34rem',
        direction: this._lang.direction,
        disableClose: true,
        autoFocus: false,
      });
    }

    if (dialogRef) await dialogRef.afterClosed().toPromise();
  }

  //lets check if user enter with video or not
  async JoinSumbit() {
    //if user logged in take his name
    if (this.guestName.length > 0 && !this.dataService.getUserData()) {
      this.data['name'] = this.guestName;
      prepareName(this.data.name);
    }

    //emit data for meetings component
    this.dialogRef.close({
      name: this.data.name,
      protocolType: this.protocolType,
    });
  }

  // private _allowMedia(imgUrl: string, message: string) {
  //   return this.dialog.open(AllowMediaComponent, {
  //     width: '385px',
  //     direction: this._lang.direction,
  //     data: {
  //       imgUrl,
  //       message,
  //     },
  //   });
  // }

  async onSignUpSubmit() {
    // if any field required scroll to it
    // if (
    //   this.registerForm &&
    //   this.registerForm.form &&
    //   this.registerForm.form.status == 'INVALID'
    // ) {
    //   return this._scrollRequired.scrollToFirstInvalidControl(
    //     'registerForm',
    //     // this.registerForm.form.controls
    //   );
    // }
    // this.signUpResponseData.attempts = 0;
    // this.signUpResponseData.waitingTime = null;
    // this.signUpResponseData.errorFromserver = '';
    // this.signUpResponseData.errorNotification = '';
    // this.loading = true;
    if (this.registerData.password != this.registerData.passConfirmation) {
      // this.loading = false;
      const msg = this._translate.instant('validation.pass-not-match');
      this.dataService.notification(msg, true);
      return;
    }

    let message: string;
    try {
      const filteredAdditionalData = { ...this.registerData.additionalData };

      // Remove empty additional data
      for (let key in filteredAdditionalData) {
        if (
          filteredAdditionalData.hasOwnProperty(key) &&
          filteredAdditionalData[key] === ''
        ) {
          delete filteredAdditionalData[key];
        }
      }

      const signUpdata = {
        email: this.registerData.email,
        password: this.registerData.password,
        // username: `${filteredAdditionalData.firstName} ${filteredAdditionalData.lastName}`,
        username: `${filteredAdditionalData.firstName}`,
        phone: this.registerData.phone,
        additionalData: filteredAdditionalData,
      };

      // TODO: remove the workaround in the signup after change the Register interface in @kortobaa-front/authentication
      await this._userService.signUp(signUpdata as any);
      message = this._translate.instant('login.registered-successfully');
      this.dataService.notification(message);
      // this.loading = false;
      // this.registerForm.resetForm();
      // this.
      this.closeGuestRestorePassForm();
    } catch (error) {
      // this.loading = false;

      if (error.status == 401) {
        message = this._translate.instant('errors.invalid-email-or-pass');
      } else if (error.status == 400 || error.status == 422) {
        message = this._translate.instant(error.error.error.message);
      } else {
        message = this._translate.instant(error.message);
      }

      this.dataService.notification(message, true);
    }
  }

  toggle(type: 'audio' | 'video') {
    this.userMediaService.autoSaveEnabledDevices();
    if (type == 'audio') {
      if (!this.userMediaService.withAudioAccept) {
        this.userMediaService.stopStream('audio');
      } else {
        this.userMediaService.changeAudioDevice(true, true);
      }
    }
    if (type == 'video') {
      if (!this.userMediaService.withVideoAccept) {
        this.userMediaService.stopStream('video');
      } else {
        this.userMediaService.changeVideoDevice(true, true);
      }
    }
  }

  // select device and change it
  async changeDevice(value: string) {
    if (value === 'audio') {
      this.userMediaService.autoSaveMedia();
      await this.userMediaService.changeAudioDevice(false, true);
    }
    if (value === 'video') {
      this.userMediaService.autoSaveMedia();
      await this.userMediaService.changeVideoDevice(false, true);
    }
    if (value === 'output') {
      this.userMediaService.autoSaveMedia();
    }
  }

  async ngOnDestroy() {
    await this.userMediaService.stopDeviceChangeHandler();
    // close opened streams
    await this.userMediaService.stopStream();
    await this.userMediaService.stopStream('audio');
    await this.userMediaService.stopStream('video');
    this.userMediaService.stopDeviceChangeHandler();
    if (this._checkUserValidSub) this._checkUserValidSub.unsubscribe();
  }
}
