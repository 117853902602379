(function(f){if(typeof exports==="object"&&typeof module!=="undefined"){module.exports=f()}else if(typeof define==="function"&&define.amd){define([],f)}else{var g;if(typeof window!=="undefined"){g=window}else if(typeof global!=="undefined"){g=global}else if(typeof self!=="undefined"){g=self}else{g=this}g.TransactionManager = f()}})(function(){var define,module,exports;return (function e(t,n,r){function s(o,u){if(!n[o]){if(!t[o]){var a=typeof require=="function"&&require;if(!u&&a)return a(o,!0);if(i)return i(o,!0);var f=new Error("Cannot find module '"+o+"'");throw f.code="MODULE_NOT_FOUND",f}var l=n[o]={exports:{}};t[o][0].call(l.exports,function(e){var n=t[o][1][e];return s(n?n:e)},l,l.exports,e,t,n,r)}return n[o].exports}var i=typeof require=="function"&&require;for(var o=0;o<r.length;o++)s(r[o]);return s})({1:[function(require,module,exports){
  // Meter class that generates a number correlated to audio volume.
  // The meter class itself displays nothing, but it makes the
  // instantaneous and time-decaying volumes available for inspection.
  // It also reports on the fraction of samples that were at or near
  // the top of the measurement range.
  },{}],2:[function(require,module,exports){
    class SoundMeter
    {
      constructor(context)
      {
        var AudioContext = window.AudioContext || window.webkitAudioContext;
        this.context = new AudioContext();
        this.instant = 0.0;
        this.script = this.context.createScriptProcessor (2048, 1, 1);
        this.stopped = true;
        var self = this;
        this.script.onaudioprocess = function (event) {
          var input = event.inputBuffer.getChannelData (0);
          var i;
          var sum = 0.0;

          for (i = 0; i < input.length; ++i)
            sum += input[i]*input[i]*10000;
          self.instant = Math.sqrt(sum) / input.length;

        };
      }

      connectToSource(stream){
        var self = this;

        if (this.stopped)
          //Stop
          this.stop();

        return new Promise(function(resolve, reject) {
          try {
            self.mic = self.context.createMediaStreamSource(stream);
            self.mic.connect(self.script);
            // necessary to make sample run, but should not be.
            self.script.connect (self.context.destination);
            //Done
            resolve();
          } catch (e) {
            reject(e);
          }
        });
      };

      stop() {
        if(this.stopped)
          return;
        this.stopped = true;
        try{
          if(this.script){
            this.script.onaudioprocess = null;
            this.script.disconnect(this.context.destination);
          }
          this.mic && this.mic.disconnect();

        } catch (e){
        }
      };
    }

    module.exports = SoundMeter;

  },{"events":1}]},{},[2])(2)
  });
