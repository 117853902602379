import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import { PackagesConfig } from "./app/config";

if (environment.production) {
  enableProdMode();
}

/*platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err));*/

fetch('assets/config.json')
  .then((response: Response) => response.json())
  .then((config: any) => {
    //add our configuration in global window variables
    (window as any).appConfig = config;
    //load config from window configuration
    PackagesConfig.loadConfig();
    //lets start angular app
    platformBrowserDynamic()
      .bootstrapModule(AppModule, {})
      .catch(err => console.log(err));
  });
