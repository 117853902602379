<div class="confirm-container">
  <div class="header">
    <h4 class="headline7">
      {{ data.title }}
    </h4>
    <button (click)="accept(false)">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <mat-icon *ngIf="data.icon" class="dialog-icon">{{ data.icon }}</mat-icon>

  <h3 class="headline7">
    {{ data.content }}
  </h3>

  <div class="action-btns">
    <button mat-flat-button class="button18 ignore" (click)="accept(false)">
      {{ "account.cancel" | translate }}
    </button>
    <button mat-flat-button class="button18 yes" (click)="accept(true)">
      {{ "account.logout-text" | translate }}
    </button>
  </div>
</div>
