import {
  Component,
  OnInit,
  OnDestroy,
  ChangeDetectorRef,
  AfterViewInit,
  ElementRef,
  ViewChild,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import { MediaObserver } from '@angular/flex-layout';
import { Direction } from '@angular/cdk/bidi';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { LangService } from 'src/app/shared/services/lang.service';
import { DataService } from 'src/app/shared/services/data.service';
import { InviteDialogComponent } from 'src/app/meeting/invite-dialog/invite-dialog.component';
import { KickActions, KortobaaMeetService } from '@kortobaa-front/ng-kmeet-sdk';
import { MeetingURLService } from 'src/app/shared/services/meeting-url.service';
import { MatSnackBarRef } from '@angular/material/snack-bar';
import { MuteAllDialogComponent } from '../mute-all-dialog/mute-all-dialog.component';
import { RemoveUserDialogComponent } from '../remove-user-dialog/remove-user-dialog.component';

@Component({
  selector: 'app-meeting-side-control',
  templateUrl: './meeting-side-control.component.html',
  styleUrls: ['./meeting-side-control.component.scss'],
})
export class MeetingSideControlComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('chatBody') private chatBody: ElementRef;
  @Input() meetingMembers = [];
  @Input() meetingInfo = { token: '0', title: '' };
  @Input() timePassedBySeconds: number;
  @Input() host: boolean = false;
  @Input() sideCollapsed;
  @Input() showChatBox;
  @Input() showMembersList;
  @Input() chat = [];
  @Input() unreadMessages;
  @Input() chatLength;
  @Input() viewingScreen;
  @Input() roomTitle;
  @Input() userId;
  @Input() meetingSettings;
  @Input() waitingRoom = [];
  @Input() viewOptions;
  @Output() muteUser = new EventEmitter();
  @Output() muteUnAllUsers = new EventEmitter();
  @Output() sendMessageToAll = new EventEmitter();
  @Output() removeUserFromMeeting = new EventEmitter();
  @Output() changeMeetingTitle = new EventEmitter();
  @Output() waitingAreaAction = new EventEmitter();
  @Output() waitingAreaAcceptAll = new EventEmitter();
  @Output() assignNewHost = new EventEmitter();
  @Output() toggleSideControl = new EventEmitter();
  @Output() chatBoxStatus = new EventEmitter();
  @Output() showMembersStatus = new EventEmitter();

  dialogSub: Subscription;

  direction: Direction;
  mediaSub: Subscription;

  newMessage: string = '';
  sender: string = '';
  activateSendBtn = false;
  attachments = [];
  muteUnAll = false;
  href = new URL(window.location.href);
  copied: boolean = false;
  snackbar: MatSnackBarRef<any>;
  msgLast;
  waitingRoomFilter;
  meetingMembersFilter;

  isEmojiPickerVisible: boolean;

  constructor(
    public mediaObserver: MediaObserver,
    public cdRef: ChangeDetectorRef,
    public dialog: MatDialog,
    public dataService: DataService,
    public _lang: LangService,
    public kortobaaPkg: KortobaaMeetService,
    private _meetingURL: MeetingURLService
  ) {}

  ngOnInit(): void {
    this.mediaSub = this.mediaObserver.media$.subscribe();
    this.direction = this._lang.direction;
  }

  ngOnChanges(value) {
    if (value.hasOwnProperty('chatLength')) {
    }
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  ngOnDestroy() {
    if (this.mediaSub) {
      this.mediaSub.unsubscribe();
    }
    if (this.dialogSub) {
      this.dialogSub.unsubscribe();
    }
  }

  //host mute member function
  muteMember(member) {
    this.muteUser.emit({ action: 'mute', member });
    let memberIndex = this.meetingMembers.indexOf(member);
    this.meetingMembers[memberIndex].audio = false;
  }

  openMuteMemberDialog(member) {
    const dialogRef = this.dialog.open(MuteAllDialogComponent, {
      width: '26rem',
      direction: this._lang.direction,
      data: { type: 'member', user: member },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((res) => {
      if (res.event == 'member') {
        this.muteMember(member);
      }
    });
  }

  //host unmute member
  unMuteMember(member) {
    this.muteUser.emit({ action: 'unmute', member });
    let memberIndex = this.meetingMembers.indexOf(member);
    this.meetingMembers[memberIndex].audio = true;
  }

  removeUser(memberId: number, action: KickActions) {
    this.removeUserFromMeeting.emit({ memberId, action });
  }

  openRemoveUserDialog(member) {
    const dialogRef = this.dialog.open(RemoveUserDialogComponent, {
      width: '26rem',
      direction: this._lang.direction,
      data: { type: 'removeUser', user: member },
      disableClose: true,
    });

    dialogRef.afterClosed().subscribe((res) => {
      this.removeUser(member.id, res);
      // this.dataService.homeStatus.type = res;
    });
  }

  //host mute all members
  muteAll() {
    this.muteUnAllUsers.emit({ action: 'mute' });
    this.muteUnAll = !this.muteUnAll;
  }

  openMuteAllDialog() {
    const dialogRef = this.dialog.open(MuteAllDialogComponent, {
      width: '26rem',
      direction: this._lang.direction,
      data: 'muteAll',
    });

    this.dialogSub = dialogRef.afterClosed().subscribe((res) => {
      if (res.event == 'muteAll') {
        this.muteAll();
      }
    });
  }

  //host unmute all members
  unMuteAll() {
    this.muteUnAllUsers.emit({ action: 'unmute' });
    this.muteUnAll = !this.muteUnAll;
  }

  addEmoji(event) {
    this.newMessage = `${this.newMessage}${event.emoji.native}`;
    // this.isEmojiPickerVisible = false;
  }

  isMaximum(event: any) {
    if (this.newMessage.length >= 1000) {
      return false;
    } else {
      event.target.addEventListener('input', () => {
        event.target.style.height = '';
        event.target.style.height =
          Math.min(event.target.scrollHeight, 100) + 'px';
      });
      return true;
    }
  }
  //send new message
  sendMessage() {
    // console.log(this.newMessage);
    if (this.newMessage) {
      let message = {
        sender: this.sender,
        content: this.newMessage.trim(),
      };

      if (message.content != '') {
        this.sendMessageToAll.emit(this.newMessage);

        this.activateSendBtn = true;
      } else {
        this.activateSendBtn = false;
      }

      this.newMessage = '';
      this.isEmojiPickerVisible = false;
    }
  }

  onFileInput(e) {
    let filesArray = Array.from(e.target.files);
    filesArray.forEach((file) => this.attachments.push(file));
  }

  removeAttachment(file) {
    let index = this.attachments.indexOf(file);
    this.attachments.splice(index, 1);
  }

  acceptAll() {
    this.waitingAreaAcceptAll.emit({
      isAccepted: true,
    });
  }

  acceptMemeber(id, isAccepted: boolean) {
    this.waitingAreaAction.emit({
      isAccepted,
      userId: id,
    });
  }

  // TODO: implement reject waiting room member logic
  rejectMemeber(id) {
    //
  }

  assignHost(participantId: number) {
    this.assignNewHost.emit({
      participantId,
    });
  }

  toggleSide() {
    this.toggleSideControl.emit(true);
    this.showMembersStatus.emit(false);
    this.chatBoxStatus.emit(false);

    this.toggleSideControl.emit(!this.sideCollapsed);
  }

  showMembers() {
    this.showMembersStatus.emit(true);
    this.toggleSideControl.emit(false);
  }

  showChat() {
    this.chatBoxStatus.emit(true);
    this.toggleSideControl.emit(false);
  }

  openInvitationDialog() {
    this.dialog.open(InviteDialogComponent, {
      width: ' 25.5rem',
      direction: this._lang.direction,
      data: {
        meetingToken: this.meetingInfo.token,
        direction: this._lang.direction,
      },
    });
  }

  // copy url of meeting
  copyMeetingUrl(meetingToken: string) {
    this.copied = true;
    this._meetingURL.copyMeetingUrl(meetingToken);
  }

  pinParticipant(id, index) {
    this.meetingMembers[index].isPinned = true;
    console.log();

    this.kortobaaPkg.pinParticipant(id);
  }

  unpinParticipant(id, index) {
    this.meetingMembers[index].isPinned = false;
    this.kortobaaPkg.unpinParticipant(id);
  }

  //search by username in participation and waiting list
  searchUser(value) {
    value.toLowerCase();
    if (value.length > 0) {
      this.waitingRoomFilter = this.waitingRoom.filter((user) => {
        return user.name.toLowerCase().includes(value);
      });
      this.meetingMembersFilter = this.meetingMembers.filter((user) => {
        return user.name.toLowerCase().includes(value);
      });
    } else {
      this.meetingMembersFilter = undefined;
      this.waitingRoomFilter = undefined;
    }
  }
}
