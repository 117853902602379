// module
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDividerModule } from '@angular/material/divider';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from 'src/app/shared/shared.module';
import { ImageCropperModule } from 'ngx-image-cropper';

// component
import { ProfileComponent } from 'src/app/profile/profile/profile.component';
import { ScheduleNewMeetingComponent } from 'src/app/profile/schedule-new-meeting/schedule-new-meeting.component';
import { TemplateBottomSheetComponent } from './template-bottom-sheet/template-bottom-sheet.component';
import { ChangePassDialogComponent } from './dialog/change-pass-dialog/change-pass-dialog.component';
import { DeleteAccountDialogComponent } from './dialog/delete-account-dialog/delete-account-dialog.component';
import { ChangePhotoDialogComponent } from './dialog/change-photo-dialog/change-photo-dialog.component';

@NgModule({
  imports: [
    SharedModule,
    TranslateModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatDividerModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatMenuModule,
    MatRadioModule,
    MatIconModule,
    MatTabsModule,
    MatDatepickerModule,
    NgxMaterialTimepickerModule,
    MatSelectModule,
    MatCheckboxModule,
    MatBottomSheetModule,
    MatChipsModule,
    MatAutocompleteModule,
    RouterModule,
    MatTooltipModule,
    ImageCropperModule,
  ],

  declarations: [
    ProfileComponent,
    ScheduleNewMeetingComponent,
    TemplateBottomSheetComponent,
    ChangePassDialogComponent,
    DeleteAccountDialogComponent,
    ChangePhotoDialogComponent,
  ],
})
export class ProfileModule {}
