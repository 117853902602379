// module
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { SharedModule } from 'src/app/shared/shared.module';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatDividerModule } from '@angular/material/divider';

// component
import { HomeComponent } from 'src/app/home/pages/home/home.component';
import { MemberRemovedDialogComponent } from 'src/app/home/dialog/member-removed-dialog/member-removed-dialog.component';
import { JoinMeetingComponent } from 'src/app/home/components/join-meeting/join-meeting.component';

@NgModule({
  imports: [
    MatFormFieldModule,
    SharedModule,
    RouterModule,
    TranslateModule,
    MatButtonModule,
    MatIconModule,
    MatInputModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatSelectModule,
    MatAutocompleteModule,
    NgxMaterialTimepickerModule,
    MatDividerModule,
  ],
  declarations: [
    HomeComponent,
    MemberRemovedDialogComponent,
    JoinMeetingComponent,
  ],
})
export class HomeModule {}
