import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { NgForm, NgModel } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'src/app/auth/services/users.service';
import { DialogData } from 'src/app/meeting/allow-media/allow-media.component';
import { DataService } from 'src/app/shared/services/data.service';

@Component({
  selector: 'app-change-pass-dialog',
  templateUrl: './change-pass-dialog.component.html',
  styleUrls: ['./change-pass-dialog.component.scss'],
})
export class ChangePassDialogComponent implements OnInit {
  @ViewChild('passwordForm') passwordForm: NgForm;
  isCurrentPasswordHidden = true;
  isConfrimPasswordHidden = true;
  isNewPasswordHidden = true;
  showErrorMsg = false;
  capsOn;
  userEditableData = {
    name: '',
    img: null,
    imgFile: null as File,
    phone: '',
    oldPassword: '',
    newPassword: '',
    passConfirmation: '',
    email: '',
    additionalData: {
      jobTitle: '',
      country: null,
      state: null,
      company: null,
    },
  };
  loading = false;
  wrongPassCriteria = false;
  showErrorBorder = false;
  passwordError = {
    length: false,
    english: false,
    number: false,
    special: false,
    upper: false,
  };

  checkPasswordPattern(password: string, data: NgModel) {
    const patternUpper = RegExp('[A-Z]');
    const patternNumber = RegExp('[0-9]');
    const patternSpecial = RegExp('[_+#?!@$%^&*-]');
    const patternEnglish = RegExp('[a-z]|[A-Z]');

    this.passwordError = {
      length: password.length >= 8 ? true : false,
      upper: patternUpper.test(password),
      english: patternEnglish.test(password),
      number: patternNumber.test(password),
      special: patternSpecial.test(password),
    };

    this.showErrorBorder = !data.valid;
  }

  constructor(
    public userService: UsersService,
    private _translate: TranslateService,
    public dataService: DataService,
    public dialogRef: MatDialogRef<ChangePassDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  ngOnInit(): void {}

  toggleDisplayPassword(e: PointerEvent, inputName: string) {
    e.stopPropagation();
    switch (inputName) {
      case 'currentPassword':
        return (this.isCurrentPasswordHidden = !this.isCurrentPasswordHidden);

      case 'confrimPassword':
        return (this.isConfrimPasswordHidden = !this.isConfrimPasswordHidden);

      case 'newPasswordHide':
        return (this.isNewPasswordHidden = !this.isNewPasswordHidden);
    }
  }

  async updateUserPassword() {
    this.loading = true;
    let message: string;

    // Validate passord confirmation before submit data
    if (
      this.userEditableData.passConfirmation !=
      this.userEditableData.newPassword
    ) {
      this.loading = false;
      message = this._translate.instant('validation.pass-not-match');
      this.dataService.notification(message, true);
      return;
    }

    try {
      await this.userService.updateUserPassword({
        oldPassword: this.userEditableData.oldPassword,
        password: this.userEditableData.newPassword,
      });

      message = this._translate.instant('account.update-password-successfully');

      this.dataService.notification(message);
      // Reset passwords fields
      this.passwordForm.resetForm();
      this.dialogRef.close();
      this.showErrorMsg = false;
    } catch (error) {
      console.log(error);

      if (error.status == 401) {
        //message = this._translate.instant('errors.invalid-pass');
      } else if (error.status == 400 || error.status == 422) {
        message = this._translate.instant(error.error.error.message);
      } else {
        message = this._translate.instant(error.message);
      }
      if (message) this.dataService.notification(message, true);
      // this.dialogRef.close();
      this.showErrorMsg = true;
    }

    this.loading = false;
  }
}
