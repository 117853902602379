import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { MeetingsService } from 'src/app/meeting/services/meetings.service';
import { DataService } from 'src/app/shared/services/data.service';
import { validURL } from 'src/app/shared/helpers/customFunctions';
import { OpenAppDialogComponent } from 'src/app/shared/components/open-app-dialog/open-app-dialog.component';
import { Platform } from '@angular/cdk/platform';
import { LangService } from 'src/app/shared/services/lang.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-join-meeting',
  templateUrl: './join-meeting.component.html',
  styleUrls: ['./join-meeting.component.scss'],
})
export class JoinMeetingComponent {
  meetingHasPass = false;
  @Input() guest = false;

  isInputFoucsed = false;

  roomData = {
    id: '',
    password: '',
  };

  hide = true;
  loading = false;
  openAppUi = false;
  constructor(
    public meetingsService: MeetingsService,
    private _router: Router,
    private _dataService: DataService,
    private _translate: TranslateService,
    public dialog: MatDialog,
    public _lang: LangService,
    private platform: Platform
  ) {}

  ngOnInit() {
    if (this.platform.ANDROID || this.platform.IOS) {
      this.openAppUi = true;
    }
  }

  async hasPass() {
    const host = this._dataService.apiHost;
    let meetingUrl: string;

    if (
      !(
        this.roomData.id.includes(host) && this.roomData.id.includes('meeting/')
      )
    ) {
      meetingUrl = `meetings/${this.roomData.id}`;

      try {
        const meetingData = await this.meetingsService
          .getMeetingDataByUrl(meetingUrl)
          .toPromise();

        if (meetingData['hasPassword']) {
          this.meetingHasPass = true;
        }
      } catch (error) {
        let errorMsg = this._translate.instant(
          'validation.meeting-id-not-valid'
        );
        this._dataService.notification(errorMsg, true);
      }
    }
  }

  async joinNow() {
    const excludedIds = ['.', '?'];

    // TODO: change replace method to replaceAll after upgrdae ts
    this.roomData.id = this.roomData.id.replace(/ /g, ''); // To remove empty spaces on meeting id

    // If the room id is empty or starts with any excluded ids, reset the room id input
    for (let id of excludedIds) {
      if (this.roomData.id.startsWith(id) || !this.roomData.id)
        return (this.roomData.id = '');
    }

    this.loading = true;
    const host = this._dataService.apiHost;
    let meetingUrl: string;

    const url = this.roomData.id;
    const check = url.includes('meeting');
    const extractData = url.split('/meeting/');
    // const test = 'https://devdesign.halameet.com';
    const urlHost = 'https://' + window.location.host;

    if (validURL(url) && extractData[0] == urlHost) {
      this.roomData.id = extractData[1];
    } else if (validURL(url) && check && extractData[0] != urlHost) {
      let errorMsg = this._translate.instant(
        'validation.meeting-link-not-valid'
      );
      setTimeout(() => {
        this.loading = false;
        this._dataService.notification(errorMsg, true);
      }, 200);
      return;
    }

    if (
      !(
        this.roomData.id.includes(host) && this.roomData.id.includes('meeting/')
      )
    ) {
      meetingUrl = `meetings/${this.roomData.id}`;
      console.log('ID: ', meetingUrl);
      try {
        const meetingData = await this.meetingsService
          .getMeetingDataByUrl(meetingUrl)
          .toPromise();
      } catch (error) {
        return (this.loading = false);
      }
      /*if (meetingData['hasPassword']) {
          this.meetingHasPass = true;
        }*/
      if (
        this.roomData.id.includes(host) &&
        this.roomData.id.includes('meeting/')
      ) {
        if (this.openAppUi) {
          this.openAppDialog('openMeet', `${this.roomData.id}`);
          return;
        }
        this._router.navigate([this.roomData.id]);
      } else {
        if (this.meetingHasPass) {
          if (this.openAppUi) {
            this.openAppDialog(
              'openMeet',
              `meeting/${this.roomData.id}?p=${this.roomData.password}`
            );
            return;
          }
          this._router.navigate([
            `meeting/${this.roomData.id}?p=${this.roomData.password}`,
          ]);
        } else {
          if (this.openAppUi) {
            this.openAppDialog('openMeet', `meeting/${this.roomData.id}`);
            return;
          }
          this._router.navigate([`meeting/${this.roomData.id}`]);
        }
      }
      this.loading = false;
    }
  }

  openAppDialog(value: string, token?: any) {
    this.dialog.open(OpenAppDialogComponent, {
      width: 'auto',
      minHeight: 'auto',
      direction: this._lang.direction,
      autoFocus: false,
      panelClass: ['openApp'],
      data: {
        type: value,
        status: this.openAppUi,
        token: token,
      },
    });
    this.loading = false;
    this.roomData.id = '';
  }
}
