import { Component, OnInit, ViewChild } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MatSidenav } from '@angular/material/sidenav';
import { TranslateService } from '@ngx-translate/core';

import { DataService } from 'src/app/shared/services/data.service';
import { SidenavService } from 'src/app/ui/layout/sidenav.service';
import {
  ActivatedRoute,
  NavigationEnd,
  NavigationStart,
  Router,
} from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  @ViewChild('sidenav', { static: true }) public sidenav: MatSidenav;
  showProfile = false;
  panelOpenState = false;
  currentLang: string = 'ar';
  meetingRoom: boolean = true;
  routSub$!: Subscription;
  getRouteSub$!: Subscription;
  isSettings = false;
  constructor(
    public dataService: DataService,
    private _translate: TranslateService,
    private _sidenavService: SidenavService,
    breakpointObserver: BreakpointObserver,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) {
    this.routSub$ = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.meetingRoom = event.url.split('/')[1] == 'meeting' ? false : true;
      }
    });
    this.currentLang = this._translate.currentLang;

    breakpointObserver.observe([Breakpoints.Handset]).subscribe((result) => {
      if (result.matches) {
        this.panelOpenState = false;
      }
    });

    breakpointObserver.observe([Breakpoints.Web]).subscribe((result) => {
      if (result.matches) {
        this.panelOpenState = true;
      }
    });
  }
  ngOnInit(): void {
    this.getRouteSub$ = this.router.events.subscribe((res: NavigationEnd) => {
      if (res instanceof NavigationEnd) {
        const nonCenteredPages = [
          '/setting',
          '/pages/FAQ',
          '/pages/terms',
          '/pages/privacy',
          '/pages/about',
        ];
        if (nonCenteredPages.some((e) => res.url.includes(e))) {
          this.isSettings = true;
        } else {
          this.isSettings = false;
        }
      }
    });
  }

  ngAfterViewInit(): void {
    this._sidenavService.setSidenav(this.sidenav);
  }

  useLanguage(language: string) {
    localStorage.setItem('defaultLanguage', language);
    this._translate.use(language);
    this.currentLang = language;
  }

  ngOnDestroy() {
    if (this.routSub$) this.routSub$.unsubscribe();
    if (this.getRouteSub$) this.getRouteSub$.unsubscribe();
  }
}
