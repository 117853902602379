import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/shared/services/data.service';
import { CountriesService } from 'src/app/shared/services/countries.service';
import { UsersService } from 'src/app/auth/services/users.service';
import { MeetingsService } from 'src/app/meeting/services/meetings.service';
import { Country, TimeZone } from 'src/app/shared/models/interfaces';
import { MatDialog } from '@angular/material/dialog';
import { ChangePassDialogComponent } from '../dialog/change-pass-dialog/change-pass-dialog.component';
import { LangService } from 'src/app/shared/services/lang.service';
import { FormCanDeactivate } from 'src/app/shared/guard/form-can-deactivate';
import { DeleteAccountDialogComponent } from '../dialog/delete-account-dialog/delete-account-dialog.component';
import { ChangePhotoDialogComponent } from '../dialog/change-photo-dialog/change-photo-dialog.component';
import { scrollRequired } from 'src/app/shared/services/scrollRequired.service';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

type profileContent = 'editPersonalInfo' | 'roomSettings' | 'scheduledMeeting';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
})
export class ProfileComponent extends FormCanDeactivate implements OnInit {
  @ViewChild('form') form: NgForm;
  @ViewChild('passwordForm') passwordForm: NgForm;
  @ViewChild('uploadImgInput') uploadImgInput!: ElementRef;
  
  usernamePattern =
  /^(?:[a-zA-Z0-9\s,\u0600-\u06FF\u0750-\u077F\u08A0-\u08FF\uFB50-\uFDCF\uFDF0-\uFDFF\uFE70-\uFEFF]|(?:\uD802[\uDE60-\uDE9F]|\uD83B[\uDE00-\uDEFF])){1,30}$/;

  meetingLink = `${this.dataService.host}meeting/`;
  loading = {
    save: false,
    info: false,
  };
  dataLoaded = false;
  isCurrentPasswordHidden = true;
  isConfrimPasswordHidden = true;
  isNewPasswordHidden = true;
  isMeetingPasswordHide = true;
  timezones: TimeZone[] = [];
  filteredTimeZones: TimeZone[];

  activeContent: profileContent = 'editPersonalInfo';
  isChangePassword = false;

  userEditableData = {
    name: '',
    img: null,
    imgFile: null as File,
    phone: ' ',
    oldPassword: '',
    newPassword: '',
    passConfirmation: '',
    email: '',
    additionalData: {
      jobTitle: null,
      timezone: null,
      state: null,
      company: null,
    },
  };

  // userCurrentData and userEditableData has the same properties
  userCurrentData = JSON.parse(JSON.stringify(this.userEditableData));

  //profile personal room tab
  personalRoom = {
    title: '',
    isWaiting: true,
    locked: true,
    joinBeforeHost: false,
    password: '',
  };

  hasPassword: boolean = false;

  focused = '';

  private _imgFile: File | null = null;
  uploadedImg: string | ArrayBuffer | null = null;

  constructor(
    public dataService: DataService,
    public userService: UsersService,
    private _translate: TranslateService,
    private _meetingService: MeetingsService,
    private _CountriesService: CountriesService,
    public _dialog: MatDialog,
    public _lang: LangService,
    private _elemRef: ElementRef,
    private _scrollRequired: scrollRequired
  ) {
    super();
  }

  @ViewChild('userName', { static: false }) userName: ElementRef;

  async ngOnInit() {
    // focus form when start
    this.autoFocus();

    this.dataLoaded = false;

    this.setCountriesList();
    await this.getProfileData();
  }

  async getProfileData() {
    try {
      this._setUserData();
      this.dataLoaded = true;
    } catch (error) {
      // TODO: missed error handler
    }

    try {
      this.loading.info = true;
      let myRoomData = await this._meetingService.getPersonalRoom().toPromise();
      this.personalRoom.title = myRoomData.title;
      this.personalRoom.isWaiting = myRoomData.isWaiting;
      this.personalRoom.locked = myRoomData.locked;
      this.personalRoom.password = myRoomData.password;
      if (myRoomData.password && myRoomData.password.length > 0)
        this.hasPassword = true;
      this.loading.info = false;
    } catch (error) {
      this.loading.info = false;
      console.log('fail to get personal info', error);
    }
  }

  async setCountriesList() {
    this.timezones = await this._CountriesService
      .getTimeZones()
      .toPromise();
    this.filteredTimeZones = this.timezones;
    // Delay assignment because form controls will not be registered on init of the component
    /*setTimeout(() => {
      console.log(this.userTimezone.nativeElement)
      console.log(this.userTimezone.nativeElement)
      this.filteredTimeZones =
        this.scheduleForm.controls.meetingTimezone.valueChanges.pipe(
          startWith(''),
          map((value) => this._filterTimeZone(value || ''))
        );
    }, 0);*/
  }

  filterTimeZone(){
    this.filteredTimeZones = this._filterTimeZone(this.userEditableData.additionalData.timezone);
  }

  private _filterTimeZone(value: string): TimeZone[] {
    const filteredValue = value.toLowerCase();

    return this.timezones.filter((timeZone) =>
      timeZone.label.toLowerCase().includes(filteredValue)
    );
  }

  private _setUserData() {
    const { username, phone, additionalData } = this.dataService.getUserData();
    this.userEditableData.name = this.userCurrentData.name = username;
    this.userEditableData.phone = this.userCurrentData.phone = phone;
    if (additionalData) {
      this.userEditableData.additionalData = JSON.parse(
        JSON.stringify(additionalData)
      );
      this.userCurrentData.additionalData = JSON.parse(
        JSON.stringify(additionalData)
      );
      this.userEditableData.img = this.userCurrentData.additionalData.image;
    }
  }

  changeContent(content: profileContent) {
    this.activeContent = content;
    // this.isChangePassword = false;
  }

  togglechangePassword() {
    // this.isChangePassword = !this.isChangePassword;
    // open schedule meeting dialog
    this._dialog.open(ChangePassDialogComponent, {
      disableClose: true,
      width: '34rem',
      minHeight: '31rem',
      direction: this._lang.direction,
      autoFocus: false,
    });
  }

  // update profile data and send it to api
  async updateUserInfo(form?: NgForm, removeValue?: string) {
    if (!removeValue) this.loading.save = true;
    if (!removeValue)
      if (form.form.status == 'INVALID') {
        return this._scrollRequired.scrollToFirstInvalidControl(
          'profileForm',
          form.form.controls
        );
      }
    let message: string;

    for (let elem in this.userEditableData.additionalData) {
      typeof this.userEditableData.additionalData[elem] == 'string' &&
      this.userEditableData.additionalData[elem].trim() == ''
        ? (this.userEditableData.additionalData[elem] = null)
        : '';
    }

    const formControlsArray = Object.entries(form.controls);

    const userData = {};

    formControlsArray.forEach((e) => {
      if (e[1].value) {
        userData[e[0]] = e[1].value;
      }
    });

    const userDataObj: { [value: string]: any } = {
      username: this.userEditableData.name,
      phone: this.userEditableData.phone,
      additionalData: {
        ...this.userEditableData.additionalData,
      },
    };

    try {
      await this.userService.updateUserInfo(userDataObj);
      this._setUserData();
      message = this._translate.instant(
        'account.personal-info-updated-successfully'
      );
      if (!removeValue) this.dataService.notification(message);
      this.autoFocus();
      this.form.control.markAsPristine();
      // this._imgFile = null;
    } catch (error) {
      console.log(error);
      this.loading.save = false;
      if (error.status == 401) {
        message = this._translate.instant('errors.unauthorized');
      } else if (error.status == 400 || error.status == 422) {
        message = this._translate.instant(error.error.error.message);
      } else {
        message = this._translate.instant(error.message);
      }
      if (!removeValue) this.dataService.notification(message, true);
    }

    if (!removeValue) this.loading.save = false;
  }

  // uploading profile image
  // onImgUploaded(event) {
  //   const uploadedImg = event.target.files[0];
  //   if (!uploadedImg) return;
  //   this._imgFile = uploadedImg;
  //   const reader = new FileReader();
  //   reader.readAsDataURL(uploadedImg);
  //   reader.onload = (_event) => {
  //     this.uploadedImg = reader.result;
  //   };
  // }

  // autofocus form when open it
  autoFocus() {
    setTimeout(() => {
      this._elemRef.nativeElement.querySelectorAll('mat-form-field');
      for (let item of this._elemRef.nativeElement.querySelectorAll(
        'mat-form-field'
      )) {
        item.classList.add('mat-focused');
      }
    });
  }

  //trigger the file input by click attach icon
  // triggerUploadImage() {
  //   this.uploadImgInput.nativeElement.click();
  // }

  // delete account
  deleteAccount() {
    this._dialog.open(DeleteAccountDialogComponent, {
      direction: this._lang.direction,
      disableClose: true,
      width: '26rem',
      panelClass: 'confirm-dialog',
      autoFocus: false,
      data: {
        title: this._translate.instant('account.delete-account-dialog.title'),
        content: this._translate.instant(
          'account.delete-account-dialog.content'
        ),
      },
    });
  }

  // change profile photo
  changePhoto() {
    this._dialog
      .open(ChangePhotoDialogComponent, {
        direction: this._lang.direction,
        disableClose: true,
        width: '35rem',
        panelClass: 'confirm-dialog',
        autoFocus: false,
        data: {
          content: this.userEditableData,
          title: this._translate.instant('account.change-photo.title'),
        },
      })
      .afterClosed()
      .subscribe((x) => {
        this.updateUserInfo(undefined, 'remove');
      });
  }
}
