import { Component, Inject } from '@angular/core';
import { MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';

@Component({
  selector: 'app-template-bottom-sheet',
  templateUrl: './template-bottom-sheet.component.html',
  styleUrls: ['./template-bottom-sheet.component.scss'],
})
export class TemplateBottomSheetComponent {
  templateName: string;

  constructor(
    private _bottomSheetRef: MatBottomSheetRef<TemplateBottomSheetComponent>,
    @Inject(MAT_BOTTOM_SHEET_DATA)
    private _choosedTemplateName: string
  ) {
    this.templateName = this._choosedTemplateName || '';
  }

  saveTemplate(event: Event) {
    event.preventDefault();
    this._bottomSheetRef.dismiss(this.templateName);
  }
}
