<div class="notification-conatiner">
  <div class="header">
    <!-- <h2 *ngIf="!header">{{ "meeting-room.error-occurred" | translate }}</h2> -->
    <h2>{{ "meeting-room." + header | translate }}</h2>

    <a class="close-icon" (click)="accept(false)">
      <mat-icon>close</mat-icon>
    </a>
  </div>

  <p *ngIf="data?.status != 422" class="subtitle2">
    {{ "meeting-room." + data.msg | translate }}
  </p>
  <pre *ngIf="data?.status == 422" class="subtitle2">{{ data.msg | json }}</pre>
  <div class="remove-action">
    <button mat-flat-button color="primary" (click)="accept(true)">
      <span *ngIf="data && !data.hasOwnProperty('confirmMsg')">{{
        "meeting-room.got-it" | translate
      }}</span>
      <span *ngIf="data && data.hasOwnProperty('confirmMsg')">{{
        "meeting-room." + data.confirmMsg | translate
      }}</span>
    </button>
  </div>
</div>
