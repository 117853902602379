<div class="remove-meeting">
  <div class="header">
    <h2>{{ "account.remove-meeting" | translate }}</h2>
    <a class="close-icon" (click)="onNoClick()">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <p>
    {{ "meeting-room.remove-question" | translate }}
  </p>
  <div class="remove-action">
    <button class="remove-btn" mat-flat-button block (click)="onNoClick()">
      {{ "account.cancel-meeting" | translate }}
    </button>
    <button mat-flat-button class="end-btn" block (click)="removeMeeting()">
      {{ "account.delete" | translate }}
    </button>
  </div>
</div>
