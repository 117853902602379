import { Injectable } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';

@Injectable()
export class SidenavService {
  private _sidenav: MatSidenav;

  setSidenav(sidenav: MatSidenav) {
    this._sidenav = sidenav;
  }

  open() {
    return this._sidenav.open();
  }

  close() {
    return this._sidenav.close();
  }

  toggle(): void {
    if (this._sidenav.mode == 'over') this._sidenav.toggle();
  }
}
