<form
  #joinMeetingForm="ngForm"
  (ngSubmit)="joinNow()"
  class="join-meeting-form"
  [ngClass]="{ foucsed: isInputFoucsed }"
>
  <mat-form-field
    [ngStyle]="{
      width:
        meetingID.control.value?.length > 0 && joinMeetingForm.valid
          ? ''
          : '18rem'
    }"
    class="bodytext-normal"
    block
    appearance="filled"
    color="primary"
  >
    <input
      type="text"
      [placeholder]="
        guest === true
          ? ('enter-link-meeting' | translate)
          : ('account.meeting-id' | translate)
      "
      name="meetingID"
      #meetingID="ngModel"
      (focus)="isInputFoucsed = true"
      (blur)="isInputFoucsed = false"
      [(ngModel)]="roomData.id"
      matInput
      autocomplete="off"
    />
    <!-- <mat-icon matPrefix>keyboard</mat-icon> -->
  </mat-form-field>

  <button
    type="submit"
    mat-flat-button
    color="primary"
    [disabled]="
      !roomData.id ||
      meetingID.control.value?.length <= 0 ||
      !joinMeetingForm.valid
    "
    class="main-btn button18 block"
  >
    <app-loading-action *ngIf="loading" [scale]="1"></app-loading-action>

    <span *ngIf="!loading">
      {{ "login.join-now" | translate }}
    </span>
  </button>
</form>
