<div class="change-photo-dialog-container">
  <div class="header">
    <h4 class="headline7">
      {{ data.title }}
    </h4>
    <button (click)="this.dialogRef.close()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="user-img-container">
    <image-cropper
      [imageChangedEvent]="imageChangedEvent"
      [maintainAspectRatio]="true"
      [aspectRatio]="2 / 2"
      [cropperMinWidth]="120"
      [cropperMinHeight]="120"
      (imageCropped)="imageCropped($event)"
      [roundCropper]="true"
      [backgroundColor]="'transparent'"
      [imageQuality]="100"
    ></image-cropper>
    <!-- default photo -->
    <img
      class="default-img"
      [src]="
        dataService.getUserData()?.photoUrl
          ? dataService.getUserData()?.photoUrl
          : '../../../assets/images/Profile pic.png'
      "
      *ngIf="!croppedImage"
    />
    <input
      class="upload-img"
      type="file"
      accept="image/*"
      (change)="fileChangeEvent($event)"
      style="display: none"
      #uploadImgInput
    />
  </div>

  <div class="action-btns">
    <button
      mat-flat-button
      class="button18 ignore"
      (click)="removeImage()"
      [ngStyle]="{
        visibility: dataService.getUserData()?.photoUrl ? 'show' : 'hidden'
      }"
    >
      <img
        src="../../../../assets/images/icons/delete.svg"
        [alt]="'account.change-photo.remove' | translate"
      />
      <span>{{ "account.change-photo.remove" | translate }}</span>
    </button>
    <div>
      <button mat-flat-button class="cancel" (click)="triggerUploadImage()">
        {{ "account.change-photo.choose-picture" | translate }}
      </button>
      <button
        (click)="saveImage()"
        mat-flat-button
        class="main-btn button18"
        [disabled]="!croppedImage"
      >
        <app-loading-action *ngIf="loading.save"></app-loading-action>
        {{ "account.change-photo.save" | translate }}
      </button>
    </div>
  </div>
</div>
