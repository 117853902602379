<div
  class="snackbar-container"
  [ngClass]="{
    dirLtr: _lang.direction == 'ltr'
  }"
>
  <div
    class="inner-container"
    [ngStyle]="{
      width: data?.type == 'host' || data?.type == 'user' ? '100%' : 'auto'
    }"
  >
    <ng-container *ngFor="let member of kortobaaPkg.participants">
      <!-- <span
        *ngIf="data?.type == 'host' && member.host && !data.content.isMe"
        [ngStyle]="{ 'text-align': data?.type == 'host' ? 'center' : 'right' }"
        >{{ "snackbar.you-are-host" | translate }}
      </span> -->

      <span
        *ngIf="
          data?.type == 'host' &&
          member.host &&
          data.content.isMe &&
          member.id == kortobaaPkg.userId
        "
        [ngStyle]="{ 'text-align': data?.type == 'host' ? 'center' : 'right' }"
        >{{ "snackbar.you-are-host" | translate }}
      </span>
      <span
        *ngIf="
          data?.type == 'host' &&
          !member.host &&
          !data.content.isMe &&
          data.content.hostId == member.id
        "
        [ngStyle]="{ 'text-align': data?.type == 'host' ? 'center' : 'right' }"
        >{{ message }}</span
      >
    </ng-container>

    <div
      class="new-message new-users"
      *ngIf="data?.type == 'user' && !data.content?.isMe"
    >
      <span
        >{{ "meeting-room.join-new-users" | translate }}
        <span
          class="user-name"
          [ngClass]="{
            dirLtr: _lang.direction == 'ltr'
          }"
        >
          {{ data.content }}
        </span>

        {{ "meeting-room.join-new-users-meeting" | translate }}
      </span>
    </div>

    <div class="new-message" *ngIf="data?.type == 'msg'">
      <!-- <mat-icon class="unread-messages">chat</mat-icon> -->
      <!-- <span>{{ "snackbar.unreaded-mesages" | translate }}</span> -->
    </div>
    <span *ngIf="data?.type == 'msg'">
      {{ "snackbar.have" | translate }}
      {{ data.number }}
      {{ (_lang.direction == "ltr" ? "snackbar.new" : "") | translate }}
      {{
        (data.number > 1 ? "snackbar.messages" : "snackbar.message") | translate
      }}
      {{ (_lang.direction == "rtl" ? "snackbar.new" : "") | translate }}
    </span>
  </div>
  <!--View for all snackbar action-->
  <div class="action" *ngIf="data?.type == 'msg'">
    <button
      *ngIf="data?.type == 'msg'"
      mat-flat-button
      class="view-btn"
      (click)="closeSnackBar(true)"
    >
      {{ "show-all" | translate }}
    </button>

    <a class="close-icon" (click)="closeSnackBar()">
      <mat-icon class="close-icon">close</mat-icon>
    </a>
  </div>

  <div class="inner-container center" *ngIf="data?.type == 'muteUser'">
    <span
      class="flex"
      [ngStyle]="{
        'text-align': true ? 'center' : 'right'
      }"
      >{{ "meeting-room.host-mute" | translate }}</span
    >
  </div>
</div>
