import {
  Component,
  ElementRef,
  Inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from 'src/app/shared/services/data.service';
import { ImageCroppedEvent, LoadedImage } from 'ngx-image-cropper';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { UsersService } from 'src/app/auth/services/users.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-change-photo-dialog',
  templateUrl: './change-photo-dialog.component.html',
  styleUrls: ['./change-photo-dialog.component.scss'],
})
export class ChangePhotoDialogComponent implements OnInit {
  @ViewChild('uploadImgInput') uploadImgInput!: ElementRef;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  loading = {
    save: false,
    remove: false,
  };
  deleteSub$!: Subscription;

  constructor(
    public dialogRef: MatDialogRef<ChangePhotoDialogComponent>,
    public dataService: DataService,
    private _translate: TranslateService,
    public userService: UsersService,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {}

  //trigger the file input by click attach icon
  triggerUploadImage() {
    this.uploadImgInput.nativeElement.click();
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: any) {
    // event.blob can be used to upload the cropped image
    if (this.imageChangedEvent) {
      this.croppedImage = event.base64;
    }
  }

  imageLoaded(image: LoadedImage) {
    // show cropper
    // console.log(image);
  }

  // remove image
  async removeImage() {
    this.deleteSub$ = this.userService.removeImage().subscribe({
      next: (data) => {
        let successMessage = this._translate.instant(
          'account.change-photo.removed-message'
        );
        this.dataService.notification(successMessage);
        this.dataService.getUserData().photoUrl = '';
        this.dialogRef.close();
      },
      error: (error) => {
        let message: string;
        this.dialogRef.close();
        if (error.status == 401) {
          message = this._translate.instant('errors.unauthorized');
        } else if (error.status == 400 || error.status == 422) {
          message = this._translate.instant(error.error.error.message);
        } else {
          message = this._translate.instant(error.message);
        }
        this.dataService.notification(message, true);
      },
    });
  }

  // save image
  async saveImage() {
    this.loading.save = true;
    let message: string;

    const userData: { [value: string]: any } = {};
    userData['image'] = this.dataURLtoFile(
      this.croppedImage,
      this.data.content.name
    );

    try {
      await this.userService.updateUserInfo(userData);
      let message = this._translate.instant('account.change-photo.success');
      this.dataService.notification(message);
    } catch (error) {
      console.log(error);
      if (error.status == 401) {
        message = this._translate.instant('errors.unauthorized');
      } else if (error.status == 400 || error.status == 422) {
        message = this._translate.instant(error.error.error.message);
      } else {
        message = this._translate.instant(error.message);
      }
      this.dataService.notification(message, true);
    }
    this.loading.save = false;
    this.dialogRef.close();
  }

  // transform base64 to file reader
  dataURLtoFile(dataurl, filename) {
    if (dataurl != '') {
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
    }

    return new File([u8arr], filename, { type: mime });
  }

  ngOnDestroy() {
    if (this.deleteSub$) this.deleteSub$.unsubscribe();
  }
}
