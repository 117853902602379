// module
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { PlatformModule } from '@angular/cdk/platform';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import {
  MatProgressSpinnerModule,
  MatProgressSpinner,
} from '@angular/material/progress-spinner';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TranslateModule } from '@ngx-translate/core';
import { MatTabsModule } from '@angular/material/tabs';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatTooltipModule } from '@angular/material/tooltip';

// service
import { AirLogsService } from 'src/app/shared/services/airLogs.service';
import { WebrtcService } from './services/webrtc.service';

// pipe
import { SecurePipe } from 'src/app/shared/pipes/imageAuth.pipe';
import { MatchUrlPipe } from 'src/app/shared/pipes/match-url.pipe';
import { SplitNamePipe } from 'src/app/shared/pipes/split-name.pipe';
import { TimeagoPipe } from 'src/app/shared/pipes/timeago.pipe';
import { DurationTimePipe } from './pipes/duration-time.pipe';
import { DatePipePipe } from './pipes/date-pipe.pipe';

// directive
import { MatButtonLoadingDirective } from 'src/app/shared/directive/mat-loading.directive';
import { ImagePreloadDirective } from 'src/app/shared/directive/image-preload.directive';
import { CompareDirective } from 'src/app/shared/directive/compare-password.directive';
import { ClickStopPropagationDirective } from 'src/app/shared/directive/click-stop-propagation.directive';
import { TrackCapsDirective } from './directive/TrackCapsDirective';

// guard
import { CanDeactivateGuard } from './guard/can-deactivate.guard';

// components
import { PagesComponent } from 'src/app/shared/components/privacy/pages.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';
import { ScheduledMeetingListComponent } from 'src/app/shared/components/scheduled-meeting-list/scheduled-meeting-list.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { SettingPageComponent } from './components/setting-page/setting-page.component';
import { LoadingActionComponent } from './components/loading-action/loading-action.component';
import { OpenAppDialogComponent } from './components/open-app-dialog/open-app-dialog.component';
import { HomeSliderComponent } from './components/home-slider/home-slider.component';
import { CheckNotSaveComponent } from './components/check-not-save/check-not-save.component';
import { AccessDialogComponent } from './components/access-dialog/access-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    MatDialogModule,
    MatButtonModule,
    ImageCropperModule,
    TranslateModule,
    MatDividerModule,
    MatIconModule,
    MatSlideToggleModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    BrowserAnimationsModule,
    RouterModule,
    MatTooltipModule,
    MatTabsModule,
    MatExpansionModule,
  ],
  declarations: [
    HomeSliderComponent,
    SecurePipe,
    MatchUrlPipe,
    SplitNamePipe,
    TimeagoPipe,
    ClickStopPropagationDirective,
    ConfirmDialogComponent,
    ImagePreloadDirective,
    ClickStopPropagationDirective,
    ScheduledMeetingListComponent,
    PagesComponent,
    MatButtonLoadingDirective,
    CompareDirective,
    SpinnerComponent,
    SettingPageComponent,
    DatePipePipe,
    LoadingActionComponent,
    TrackCapsDirective,
    OpenAppDialogComponent,
    CheckNotSaveComponent,
    DurationTimePipe,
    AccessDialogComponent,
  ],
  exports: [
    HomeSliderComponent,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    PlatformModule,
    SecurePipe,
    MatchUrlPipe,
    SplitNamePipe,
    TimeagoPipe,
    ImagePreloadDirective,
    CompareDirective,
    ConfirmDialogComponent,
    ClickStopPropagationDirective,
    ScheduledMeetingListComponent,
    MatButtonLoadingDirective,
    ConfirmDialogComponent,
    ScheduledMeetingListComponent,
    SpinnerComponent,
    DatePipePipe,
    LoadingActionComponent,
    TrackCapsDirective,
    OpenAppDialogComponent,
    CheckNotSaveComponent,
    DurationTimePipe,
    AccessDialogComponent,
  ],
  providers: [AirLogsService, WebrtcService, CanDeactivateGuard],
  entryComponents: [MatProgressSpinner],
})
export class SharedModule {}
