import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';

import {
  KortobaaMeetService,
  PreviewUserMediaService,
  UserMediaService,
} from '@kortobaa-front/ng-kmeet-sdk';
import { LangService } from 'src/app/shared/services/lang.service';
import { ThemeService } from 'src/app/shared/services/theme.service';
import { NotificationsDialogComponent } from 'src/app/meeting/notifications-dialog/notifications-dialog.component';
import * as SoundMeter from 'src/assets/js/soundmeter';
import { AllowMediaComponent } from '../allow-media/allow-media.component';

@Component({
  selector: 'app-settings-dialog',
  templateUrl: './settings-dialog.component.html',
  styleUrls: ['./settings-dialog.component.scss'],
})
export class SettingsDialogComponent implements OnInit, OnDestroy {
  tabIndex = this.data.settingsType;

  panelOpenState = true;
  outputLevel: number = 5; // Shoud be a number from 1 to 10
  outputVolume: number = 5; // Shoud be a number from 1 to 10

  // TODO: make the input level based on screen width dynamically
  inputLevels = [...Array(50).keys()];

  inputLevel: number = 3; // Shoud be a number from 1 to 10
  inputVolume: number = 3; // Shoud be a number from 1 to 10
  cameraOptions = [
    // Array of avilable cameras dependes on user device
    {
      name: 'HP camera',
      value: 'HP camera',
    },
    {
      name: 'other option',
      value: 'other option',
    },
  ];

  selectedQuality = {
    name: 'high',
    value: 'high',
  };

  speakerOptions = [
    // Array of avilable speakers dependes on user device
    {
      name: 'speaker1',
      value: 'speaker1',
    },
    {
      name: 'speaker2',
      value: 'speaker2',
    },
  ];

  micOptions = [
    // Array of avilable mics dependes on user device
    {
      name: 'mic1',
      value: 'mic1',
    },
    {
      name: 'mic2',
      value: 'mic2',
    },
  ];

  // Sound meter
  fps = 20;
  now;
  then = Date.now();
  interval = 1000 / this.fps;
  delta;
  drawTimer;
  soundMeter = new SoundMeter(window);

  btnLoading = false;

  themeSelected: string = localStorage.getItem('theme-selected');

  // supportVideo = false;

  viewOptions = {
    background: false,
    backgroundColor: false,
    backgroundColorVal: '',
    backgroundImg: false,
    backgroundImgVal: null,
    layoutType: 'gallery',
  };
  isDarkTheme = this._theme.isDarkTheme;
  isThemeChanged = false;

  meetingSettings = {
    locked: false,
    isWaiting: true,
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<SettingsDialogComponent>,
    public userMediaService: PreviewUserMediaService,
    private _dialog: MatDialog,
    private _theme: ThemeService,
    public _lang: LangService,
    public kortobaaPkg: KortobaaMeetService
  ) {}

  async ngOnInit() {
    //if the user is host set host data
    if (this.data.host) {
      this.meetingSettings = this.data.meetingSettings;
      this.meetingSettings.locked = this.data.meetingSettings.locked;
      this.meetingSettings.isWaiting = this.data.meetingSettings.isWaiting;
    }

    await this.onTabChange();
  }

  async onNoClick() {
    await this.closeStreams();
    if (this.isThemeChanged) this.switchTheme('themeSelected');
    this.isThemeChanged = false;
    this.dialogRef.close({
      audio: this.userMediaService.audioDeviceId,
      video: this.userMediaService.videoDeviceId,
      output: this.userMediaService.outputDeviceId,
      settings: this.meetingSettings,
      quality: this.selectedQuality.value,
    });
  }

  resetMeter() {
    //Stop
    cancelAnimationFrame(this.drawTimer);
    let _this = this;
    function draw() {
      _this.drawTimer = requestAnimationFrame(draw);

      _this.now = Date.now();
      _this.delta = _this.now - _this.then;

      if (_this.delta > _this.interval) {
        _this.then = _this.now;
        var tot = Math.min(100, _this.soundMeter.instant * 200);
        _this.inputLevel = Number((tot / 10) * 5);
        //Get all
        /*const voometers:any = document.querySelectorAll (".voometer-input");
        //Set new size
        for (let i=0;i<voometers.length;++i)
          voometers[i].style.width = (Math.floor(tot/5)*5) + "%";*/
      }
    }
    this.soundMeter
      .connectToSource(this.userMediaService.audioStream)
      .then(draw);
  }

  async onTabChange() {
    await this.closeStreams();
    //if opened tab is video
    //this.userMediaService.deviceChangeHandler();
    if (this.tabIndex == TabsType.video)
      await this.userMediaService.changeVideoDevice(true);
    //if opened tab is audio
    if (this.tabIndex == TabsType.audio) {
      await this.userMediaService.changeAudioDevice(true);
      this.resetMeter();
    }
  }

  async closeStreams() {
    await this.userMediaService.stopStream('audio');
    await this.userMediaService.stopStream('video');
  }

  async changed(value: string) {
    if (value == 'video') {
      await this.userMediaService.changeVideoDevice();
    }
    if (value == 'audio') {
      await this.userMediaService.changeAudioDevice();
      this.resetMeter();
    }
  }

  async saveSettings() {
    this.isThemeChanged = false;
    this.btnLoading = true;
    let bandwidth = 0;
    if (this.selectedQuality.value === 'low') bandwidth = 400;
    if (this.selectedQuality.value === 'medium') bandwidth = 800;
    if (this.selectedQuality.value === 'high') bandwidth = 1500;
    this.btnLoading = false;

    if (!this.data.viewOptions.background) {
      this.data.viewOptions.backgroundColor = false;
      this.data.viewOptions.backgroundColorVal = '';
      this.data.viewOptions.backgroundImg = false;
      this.data.viewOptions.backgroundImgVal = '';
    } else {
      this.data.viewOptions.background = this.viewOptions.background;
      this.data.viewOptions.backgroundColor = this.viewOptions.backgroundColor;
      this.data.viewOptions.backgroundColorVal =
        this.viewOptions.backgroundColorVal;
      this.data.viewOptions.backgroundImg = this.viewOptions.backgroundImg;
      this.data.viewOptions.backgroundImgVal =
        this.viewOptions.backgroundImgVal;
      this.data.viewOptions.layoutType = this.viewOptions.layoutType;
    }
  }

  onFileInput(e) {
    /* console.log(e.path[0].value); */

    const reader = new FileReader();

    if (e.target.files && e.target.files.length) {
      const [file] = e.target.files;
      reader.readAsDataURL(file);

      reader.onload = async () => {
        const base64Str = (reader.result as string).split(',');
        const bytes = (base64Str[1].length - 814) / 1.37;
        const kb = parseInt((bytes / 1000).toFixed(0));
        if (kb > 512) {
          await this._dialog
            .open(NotificationsDialogComponent, {
              data: { msg: 'Image exceeds the maximum size 500kb.' },
              width: '26rem',
              direction: this._lang.direction,
              disableClose: true,
            })
            .afterClosed()
            .toPromise();
          return false;
        }
        this.viewOptions.backgroundImgVal = reader.result as string;
        this.viewOptions.background = true;
        this.viewOptions.backgroundColor = false;
        this.viewOptions.backgroundImg = true;
      };
    }
  }

  calculateImageSize(base64String) {
    let padding;
    let inBytes;
    let base64StringLength;
    if (base64String.endsWith('==')) {
      padding = 2;
    } else if (base64String.endsWith('=')) {
      padding = 1;
    } else {
      padding = 0;
    }

    base64StringLength = base64String.length;
    inBytes = (base64StringLength / 4) * 3 - padding;
    const kbytes = inBytes / 1024;
    return kbytes.toFixed(0);
  }

  changeMeetingLayout(layout) {
    this.viewOptions.layoutType = layout;
  }

  removeBackground() {
    this.viewOptions.background = false;
    this.viewOptions.backgroundColor = false;
    this.viewOptions.backgroundImg = false;
    this.viewOptions.backgroundColorVal = null;
    this.viewOptions.backgroundImgVal = null;
  }

  changeMeetingBackgroundColor(color) {
    this.viewOptions.background = true;
    this.viewOptions.backgroundColor = true;
    this.viewOptions.backgroundImg = false;
    this.viewOptions.backgroundImgVal = null;
    this.viewOptions.backgroundColorVal = color;
  }

  restoreViewOptions() {
    //get old view options
    this.viewOptions.background = this.data.viewOptions.background;
    this.viewOptions.backgroundColor = this.data.viewOptions.backgroundColor;
    this.viewOptions.backgroundColorVal =
      this.data.viewOptions.backgroundColorVal;
    this.viewOptions.backgroundImg = this.data.viewOptions.backgroundImg;
    this.viewOptions.backgroundImgVal = this.data.viewOptions.backgroundImgVal;
    /* this.viewOptions.layoutType = this.data.viewOptions.layoutType */
  }

  switchTheme(value: string) {
    this.themeSelected = value;
    localStorage.setItem('theme-selected', value);
    if (value != 'default') {
      this._theme.isDarkSubject$.next(value == 'light' ? true : false);
      this._theme.switchTheme();
    } else {
      this._theme.getThemeByBrowser();
    }
  }

  toggleMeetingBackground() {
    if (!this.viewOptions.background) {
      this.removeBackground();
    }
  }

  async checkPermission() {
    let dialogRef: MatDialogRef<AllowMediaComponent, any>;

    if (
      !this.userMediaService.audioStream ||
      !this.userMediaService.videoStream
    ) {
      // Show an allow media dialog for user if his microphone or camera are blocked instead of browser alert
      dialogRef = this._dialog.open(AllowMediaComponent, {
        data: {
          title:
            !this.userMediaService.videoStream &&
            !this.userMediaService.audioStream
              ? 'cam-voice-off'
              : !this.userMediaService.videoStream
              ? 'cam-off'
              : !this.userMediaService.audioStream
              ? 'mic-off'
              : 'cam-voice-off',
          msg:
            !this.userMediaService.audioStream &&
            !this.userMediaService.videoStream
              ? 'open-cam-voice'
              : !this.userMediaService.videoStream
              ? 'open-cam'
              : !this.userMediaService.audioStream
              ? 'open-mic'
              : 'open-cam-voice',
        },
        width: '34rem',
        direction: this._lang.direction,
        disableClose: true,
        autoFocus: false,
      });
    }

    if (dialogRef) await dialogRef.afterClosed().toPromise();
  }

  ngOnDestroy() {
    this.userMediaService.stopStream();
    this.userMediaService.stopDeviceChangeHandler();
  }
}

enum TabsType {
  'audio' = 0,
  'video' = 1,
  'general' = 2,
  'host_options' = 3,
}
