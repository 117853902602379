import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface DialogData {
  title: string;
  msg: string;
}

@Component({
  selector: 'app-allow-media',
  templateUrl: './allow-media.component.html',
  styleUrls: ['./allow-media.component.scss'],
})
export class AllowMediaComponent {
  constructor(
    public dialogRef: MatDialogRef<AllowMediaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

// close dialog 
  close(): void {
    this.dialogRef.close();
  }

}
