// modules
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { PlatformModule } from '@angular/cdk/platform';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgKortobaaMeetModule } from '@kortobaa-front/ng-kmeet-sdk';
import { SharedModule } from 'src/app/shared/shared.module';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatAutocompleteModule } from '@angular/material/autocomplete';

// components
import { ConfigDialogComponent } from 'src/app/meeting/config-dialog/config-dialog.component';
import { MeetingComponent } from 'src/app/meeting/meeting-ui/meeting.component';
import { MeetingSideControlComponent } from 'src/app/meeting/meeting-side-control/meeting-side-control.component';
import { MeetingScreenComponent } from 'src/app/meeting/meeting-screen/meeting-screen.component';
import { SettingsDialogComponent } from 'src/app/meeting/settings-dialog/settings-dialog.component';
import { CustomSnackbarComponent } from 'src/app/meeting/custom-snackbar/custom-snackbar.component';
import { NotificationsDialogComponent } from 'src/app/meeting/notifications-dialog/notifications-dialog.component';
import { AllowMediaComponent } from 'src/app/meeting/allow-media/allow-media.component';
import { MeetingPassCodeComponent } from 'src/app/meeting/meeting-pass-code/meeting-pass-code.component';
import { HostOptionsDialogComponent } from 'src/app/meeting/host-options-dialog/host-options-dialog.component';
import { InviteDialogComponent } from 'src/app/meeting/invite-dialog/invite-dialog.component';
import { TopSnackbarComponent } from 'src/app/meeting/top-snackbar/top-snackbar.component';
import { LeaveMeetingDialogComponent } from './leave-meeting-dialog/leave-meeting-dialog.component';
import { RemoveMeetingDialogComponent } from './remove-meeting-dialog/remove-meeting-dialog.component';
import { RecordDialogComponent } from './record-dialog/record-dialog.component';

// service - package - pipe
import { MeetingsService } from 'src/app/meeting/services/meetings.service';
import { PackagesConfig } from '../config';
import { MatchUrlPipe } from 'src/app/shared/pipes/match-url.pipe';
import { MuteAllDialogComponent } from './mute-all-dialog/mute-all-dialog.component';
import { RemoveUserDialogComponent } from './remove-user-dialog/remove-user-dialog.component';
import { PickerComponent, PickerModule } from '@ctrl/ngx-emoji-mart';

@NgModule({
  declarations: [
    MeetingComponent,
    ConfigDialogComponent,
    MeetingSideControlComponent,
    MeetingScreenComponent,
    SettingsDialogComponent,
    CustomSnackbarComponent,
    NotificationsDialogComponent,
    AllowMediaComponent,
    MeetingPassCodeComponent,
    HostOptionsDialogComponent,
    InviteDialogComponent,
    TopSnackbarComponent,
    LeaveMeetingDialogComponent,
    RemoveMeetingDialogComponent,
    RecordDialogComponent,
    MuteAllDialogComponent,
    RemoveUserDialogComponent,
  ],
  imports: [
    SharedModule,
    RouterModule,
    FormsModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatDialogModule,
    MatToolbarModule,
    MatIconModule,
    TranslateModule,
    FlexLayoutModule,
    MatMenuModule,
    MatDividerModule,
    ClipboardModule,
    MatExpansionModule,
    MatRadioModule,
    MatCheckboxModule,
    MatSliderModule,
    MatProgressSpinnerModule,
    PlatformModule,
    MatSlideToggleModule,
    MatSlideToggleModule,
    NgxPaginationModule,
    MatTabsModule,
    MatTooltipModule,
    MatAutocompleteModule,
    DragDropModule,
    NgKortobaaMeetModule.forRoot(PackagesConfig.kortobaaMeetingPkgConfig),
    PickerModule,
  ],
  exports: [CustomSnackbarComponent, TopSnackbarComponent],
  providers: [MeetingsService, MatchUrlPipe],
})
export class MeetingModule {
  constructor() {
    //
  }
}
