import { Platform } from '@angular/cdk/platform';
import { Component, OnInit, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogData } from 'src/app/meeting/allow-media/allow-media.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-open-app-dialog',
  templateUrl: './open-app-dialog.component.html',
  styleUrls: ['./open-app-dialog.component.scss'],
})
export class OpenAppDialogComponent implements OnInit {
  openAppUi = false;
  content;
  constructor(
    private platform: Platform,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    public dialogRef: MatDialogRef<OpenAppDialogComponent>
  ) {
    if (this.platform.ANDROID || this.platform.IOS) {
      this.openAppUi = true;
      this.content = this.data;
    }
  }

  ngOnInit(): void {}

  //fn to open app in mobile with deep-linking
  openApp() {
    window.open('halameet://meet.halameet.com/');
    this.close();
  }

  //fn to open app in mobile with deep-linking
  openMeeting() {
    window.open('halameet://meet.halameet.com/meeting/' + this.content.token);
    this.close();
  }

  downloadApp() {
    if (this.platform.ANDROID) {
      window.open(environment.PLAY_STORE_URL);
    } else {
      window.open(environment.APP_STORE_URL);
    }
  }

  // close dialog
  close() {
    this.dialogRef.close();
  }
}
