import {
  AfterViewInit,
  Component,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ThemeService } from '../../services/theme.service';
import { LangService } from '../../services/lang.service';
import { MeetingsService } from 'src/app/meeting/services/meetings.service';
import { DataService } from '../../services/data.service';
import { TranslateService } from '@ngx-translate/core';
import {
  KortobaaMeetService,
  UserMediaService,
} from '@kortobaa-front/ng-kmeet-sdk';
import * as SoundMeter from 'src/assets/js/soundmeter';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AccessDialogComponent } from '../access-dialog/access-dialog.component';
import { hrefLocation } from '../../services/href-location.service';
import { MeetingURLService } from '../../services/meeting-url.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { ComponentCanDeactivate } from '../../guard/component-can-deactivate';
import { MatSelectChange } from '@angular/material/select';

@Component({
  selector: 'app-setting-page',
  templateUrl: './setting-page.component.html',
  styleUrls: ['./setting-page.component.scss'],
})
export class SettingPageComponent
  implements OnInit, ComponentCanDeactivate, AfterViewInit
{
  personalTitlePattern =
    /[/\n/\sa-zA-Z0-9 ٌ؛ء-ي’، ً ّ َ ُ؟‘ ِ ٍ~ْ\u0020-\u007e\u00a0-\u00ff\u0152\u0153\u0178]{1,50}$/;
  isDarkTheme = this._theme.isDarkTheme;
  hasPassword: boolean = false;
  passwordToggleChange = false;
  //profile personal room tab
  personalRoom = {
    title: '',
    isWaiting: true,
    locked: false,
    joinBeforeHost: false,
    password: '',
    token: '',
  };
  isMeetingPasswordHide = true;
  themeSelected: string = localStorage.getItem('theme-selected');

  activeLink = 'general';
  isDown = false;
  startX!: any;
  scrollLeft!: any;
  @ViewChild('tabs') tabs!: ElementRef;
  myRoomData;

  outputLevel: number = 5; // Shoud be a number from 1 to 10
  outputVolume: number = 5; // Shoud be a number from 1 to 10

  // TODO: make the input level based on screen width dynamically
  inputLevels = [...Array(50).keys()];

  inputLevel: number = 0; // Shoud be a number from 1 to 10
  inputVolume: number = 0; // Shoud be a number from 1 to 10
  // Sound meter
  fps = 20;
  now;
  then = Date.now();
  interval = 1000 / this.fps;
  delta;
  drawTimer;
  soundMeter = new SoundMeter(window);

  btnLoading = false;
  validateToken: boolean = false;
  emptyToken = false;
  lastToken = '';
  passwordWritten = false;
  showTip = false;
  wrongPassCriteria = false;
  passwordError = false;
  passIncludeSpace = true;
  loading = false;
  formChanged = false;
  mediaChanged = false;
  isVideoChanged = false;
  tokenError = {
    length: true,
    english: true,
    unique: true,
  };

  copied = false;
  capsLock = false;

  audio = this.userMediaService.audioDeviceId;
  video = this.userMediaService.videoDeviceId;
  output = this.userMediaService.outputDeviceId;
  showMediaSection = false;

  checkPattern(token: string) {
    const pattern = RegExp('^[0-9a-zA-Z\b]{1,50}$');
    this.tokenError = {
      length: token.length >= 1 ? true : false,
      english: pattern.test(token),
      unique: !this.validateToken && token.length >= 1,
    };
    this.formChanged = true;
  }

  toggleChange() {
    this.formChanged = true;
  }

  constructor(
    private _theme: ThemeService,
    public _lang: LangService,
    private _meetingService: MeetingsService,
    public dataService: DataService,
    private _translate: TranslateService,
    public userMediaService: UserMediaService,
    public kortobaaPkg: KortobaaMeetService,
    public dialog: MatDialog,
    public _hrefLocation: hrefLocation,
    private meetingUrlService: MeetingURLService
  ) {}
  unloadNotification($event: any): void {
    throw new Error('Method not implemented.');
  }

  canDeactivate() {
    return !this.formChanged;
  }

  ngOnInit() {}

  ngAfterViewInit(): void {
    //Called after ngAfterContentInit when the component's view has been initialized. Applies to components only.
    //Add 'implements AfterViewInit' to the class.
    this.getRoomData();
  }

  // check permission browser
  async checkPermission() {
    let dialogRef: MatDialogRef<AccessDialogComponent, any>;
    if (
      !this.userMediaService.audioStream ||
      !this.userMediaService.videoStream
    ) {
      dialogRef = this.dialog.open(AccessDialogComponent, {
        data: {
          title:
            !this.userMediaService.videoStream &&
            !this.userMediaService.audioStream
              ? 'cam-voice-off'
              : !this.userMediaService.videoStream
              ? 'cam-off'
              : !this.userMediaService.audioStream
              ? 'mic-off'
              : 'cam-voice-off',
          msg:
            !this.userMediaService.audioStream &&
            !this.userMediaService.videoStream
              ? 'open-cam-voice'
              : !this.userMediaService.videoStream
              ? 'open-cam'
              : !this.userMediaService.audioStream
              ? 'open-mic'
              : 'open-cam-voice',
        },
        width: '26rem',
        direction: this._lang.direction,
        disableClose: true,
        autoFocus: false,
      });
    }

    if (dialogRef) await dialogRef.afterClosed().toPromise();
  }

  // check media and allow
  async changeTab(value: string) {
    if (value == 'media') {
      await this.userMediaService.startStream();
      this.userMediaService.deviceChangeHandler(false, value);
      if (this.userMediaService.audioStream) this.resetMeter();
      this.audio = this.userMediaService.audioDeviceId;
      this.video = this.userMediaService.videoDeviceId;
      this.output = this.userMediaService.outputDeviceId;
      this.checkPermission();
      /*await this.userMediaService.changeAudioDevice().then(() => {
        this.resetMeter();
        this.userMediaService.changeVideoDevice().then(() => {
          this.checkPermission();
        });
      });*/
    } else {
      this.cancelStream();
    }
  }

  // reset meter to create mic levels
  resetMeter() {
    //Stop
    if (
      this.userMediaService.audioStream &&
      this.userMediaService.audioStream.active
    ) {
      cancelAnimationFrame(this.drawTimer);
      let _this = this;

      function draw() {
        _this.drawTimer = requestAnimationFrame(draw);

        _this.now = Date.now();
        _this.delta = _this.now - _this.then;

        if (_this.delta > _this.interval) {
          _this.then = _this.now;
          var tot = Math.min(100, _this.soundMeter.instant * 200);
          _this.inputLevel = Number((tot / 10) * 5);
          //Get all
          /*const voometers:any = document.querySelectorAll (".voometer-input");
          //Set new size
          for (let i=0;i<voometers.length;++i)
            voometers[i].style.width = (Math.floor(tot/5)*5) + "%";*/
        }
      }
      this.soundMeter
        .connectToSource(this.userMediaService.audioStream)
        .then(draw);
    }
  }

  // stop stream after change tab and close component
  cancelStream() {
    this.userMediaService.saveMedia(
      this.userMediaService.audioDeviceId,
      this.userMediaService.videoDeviceId,
      this.userMediaService.outputDeviceId
    );
    this.userMediaService.stopStream();
    this.userMediaService.stopStream('audio');
    this.userMediaService.stopStream('video');
    this.userMediaService.stopDeviceChangeHandler();
  }

  // change video or audio inputs
  async changed(value?: string) {
    //this.userMediaService.deviceChangeHandler(false, value);
    //value === 'audio' ? this.resetMeter() : '';
    // ? await this.userMediaService.changeVideoDevice()
    // : await this.userMediaService.changeAudioDevice().then(() => {
    // });

    this.userMediaService.audioDeviceId = this.audio;
    this.userMediaService.videoDeviceId = this.video;
    this.userMediaService.outputDeviceId = this.output;

    this.loading = true;

    // if (value === 'audio') {
    this.userMediaService.autoSaveMedia();
    await this.userMediaService.changeAudioDevice();
    this.resetMeter();
    // }
    // if (value === 'video') {
    if (this.isVideoChanged) {
      this.userMediaService.autoSaveMedia();
      await this.userMediaService.changeVideoDevice();
    }

    // }
    // if (value === 'output') {
    this.userMediaService.autoSaveMedia();
    // }

    this.loading = false;
    this.mediaChanged = false;

    let successMessage = this._translate.instant(
      'account.personal-room-settings-successfully-updated'
    );

    this.dataService.notification(successMessage);
  }

  // get room data
  async getRoomData() {
    try {
      this.myRoomData = await this._meetingService
        .getPersonalRoom()
        .toPromise();
      this.validateToken = false;
      this.lastToken = this.myRoomData.token;
      this.personalRoom.title = this.myRoomData.title;
      this.personalRoom.token = this.myRoomData.token;
      this.personalRoom.isWaiting = this.myRoomData.isWaiting;
      this.personalRoom.locked = this.myRoomData.locked;
      this.personalRoom.password = this.myRoomData.password;
      if (this.myRoomData.password && this.myRoomData.password.length > 0) {
        this.hasPassword = true;
      } else {
        this.hasPassword = false;
      }
    } catch (error) {
      console.log('fail to get personal info', error);
    }
  }

  // switch theme
  switchTheme(value: string) {
    this.themeSelected = value;
    localStorage.setItem('theme-selected', value);
    if (value != 'default') {
      this._theme.isDarkSubject$.next(value == 'light' ? true : false);
      this._theme.switchTheme();
    } else {
      this._theme.getThemeByBrowser();
    }
  }

  // toggle password
  toggleDisplayPassword(e: PointerEvent, inputName: string) {
    e.stopPropagation();
    switch (inputName) {
      case 'meetingPasswordHide':
        return (this.isMeetingPasswordHide = !this.isMeetingPasswordHide);
    }
  }

  // validate token and change it
  // async changeToken() {
  //   // this._meetingService
  //   // .validatePersonalToken(this.personalRoom.token)
  //   // .subscribe({
  //   //   next: () => (this.validateToken = false),
  //   //   error: () => (this.validateToken = true),
  //   // });

  //   this.validateToken = false;
  //   try {
  //     if (this.myRoomData.token == this.personalRoom.token) {
  //       return;
  //     }
  //     await this._meetingService
  //       .validatePersonalToken(this.personalRoom.token)
  //       .toPromise();
  //   } catch (error) {
  //     this.validateToken = true;
  //   }
  // }

  // fetch token
  async submitToken() {
    try {
      if (this.personalRoom.token == '') {
        this.emptyToken = true;
        return;
      }
      this.emptyToken = false;
      if (this.lastToken == this.personalRoom.token) {
        this.validateToken = false;
        return;
      }
      await this._meetingService
        .validatePersonalToken(this.personalRoom.token)
        .toPromise();
      await this._meetingService
        .changePersonalToken(this.personalRoom.token)
        .toPromise();

      this.lastToken = this.personalRoom.token;

      this.dataService.notification(
        this._translate.instant(
          'account.personal-room-settings-successfully-updated'
        )
      );
      this.validateToken = false;
      this.checkPattern(this.personalRoom.token);
    } catch (error) {
      this.lastToken = this.myRoomData.token;
      this.validateToken = true;
      this.checkPattern(this.personalRoom.token);
    }
  }

  permissionChanged(value?: MatSelectChange, type?: any) {
    if (type == 'audio') {
      this.audio = value.value;
    }

    if (type == 'video') {
      this.video = value.value;
    }

    if (type == 'output') {
      this.output = value.value;
    }
    this.mediaChanged = true;
  }

  waitingRoomChange(event: MatSlideToggleChange) {
    this.formChanged = true;
    this.personalRoom.isWaiting = event.checked;
  }

  cancelEdit() {
    this.formChanged = false;
    this.mediaChanged = false;
    this.getRoomData();
    this.audio = this.userMediaService.audioDeviceId;
    this.video = this.userMediaService.videoDeviceId;
    this.output = this.userMediaService.outputDeviceId;
  }

  enableTooltip() {
    this.showTip = true;
    this.formChanged = true;
  }

  passwordInput(event: any) {
    this.formChanged = true;
    if (event.target.value.length > 0) {
      this.passwordError = false;
      this.wrongPassCriteria = false;
    } else {
      this.passwordError = true;
      this.wrongPassCriteria = true;
    }

    if (event.target.value.includes(' ')) {
      this.passIncludeSpace = false;
    } else {
      this.passIncludeSpace = true;
    }
  }

  // change setting
  async change(value?: string) {
    if (this.hasPassword && this.personalRoom.password.length == 0) {
      this.wrongPassCriteria = true;
      if (this.personalRoom.title == '') {
        this.showTip = true;
      }
      return;
    }
    if (!this.hasPassword) this.personalRoom.password = '';
    if (this.personalRoom.title == '') {
      this.showTip = true;
      return;
      // return (this.personalRoom.title = this.myRoomData.title);
    }

    try {
      this.loading = true;
      if (
        this.tokenError.english &&
        this.tokenError.length &&
        this.tokenError.unique
      ) {
        this.submitToken();
      }

      const roomInfo = {
        title: this.personalRoom.title.trim(),
        isWaiting: this.personalRoom.isWaiting,
        joinBeforeHost: this.personalRoom.joinBeforeHost,
        locked: false,
        password: this.personalRoom.password,
      };

      await this._meetingService.savePersonalRoom(roomInfo).toPromise();
      this.loading = false;
      this.formChanged = false;
      let successMessage = this._translate.instant(
        'account.personal-room-settings-successfully-updated'
      );

      this.dataService.notification(successMessage);
    } catch (error) {
      console.log('fail to set personal info', error);

      let message;

      if (error.status == 401) {
        message = this._translate.instant(
          'account.fail-to-set-personal-settings'
        );
      } else if (error.status == 400 || error.status == 422) {
        message = this._translate.instant(error.error.error.message);
      } else {
        message = this._translate.instant(error.message);
      }

      this.dataService.notification(message, true);
    }
  }

  //scroll by mouse in responsive
  mouseDown(e: MouseEvent) {
    this.isDown = true;
    this.startX = e.pageX - this.tabs.nativeElement.offsetLeft;
    this.scrollLeft = this.tabs.nativeElement.scrollLeft;
  }

  mouseleave() {
    this.isDown = false;
  }

  mouseup() {
    this.isDown = false;
  }

  mousemove(e: MouseEvent) {
    if (!this.isDown) return;
    e.preventDefault();
    const x = e.pageX - this.tabs.nativeElement.offsetLeft;
    const walk = (x - this.startX) * 3; //scroll-fast
    this.tabs.nativeElement.scrollLeft = this.scrollLeft - walk;
  }

  copyLink() {
    this.meetingUrlService.copyMeetingUrl(this.personalRoom.token);
    this.copied = true;
    setTimeout(() => {
      this.copied = false;
    }, 3000);
  }

  ngOnDestroy() {
    this.cancelStream();
    this.userMediaService.stopDeviceChangeHandler();
    this.formChanged = false;
  }
}
