<section class="profile-container">
  <!-- logo -->
  <a class="brand">
    <figure routerLink="/">
      <img src="assets/images/logo.svg" />
    </figure>
  </a>
  <div class="profile-content-container">
    <div class="profile-content-img">
      <!-- start edit user image or upload new image -->
      <div class="user-img-container">
        <img
          class="img"
          *ngIf="dataService.getUserData()?.photoUrl && !uploadedImg"
          [src]="dataService.getUserData()?.photoUrl"
          [alt]="dataService.getUserData()?.username + 'Photo'"
        />

        <img
          *ngIf="!dataService.getUserData()?.photoUrl && !uploadedImg"
          src="../../../assets/images/Profile pic.png"
          alt="No user photo"
        />

        <!-- <img class="img" [src]="uploadedImg" *ngIf="uploadedImg" /> -->
        <!-- <input
          class="upload-img"
          type="file"
          accept="image/*"
          (change)="onImgUploaded($event)"
          style="display: none"
          #uploadImgInput
        /> -->
        <div class="upload-img" (click)="changePhoto()"></div>
      </div>
      <h5 class="user-name">{{ dataService.getUserData()?.username }}</h5>
      <h6 class="user-email">{{ dataService.getUserData()?.email }}</h6>
      <mat-divider></mat-divider>

      <!-- change password  -->
      <div class="info-password-toggler" (click)="togglechangePassword()">
        <mat-icon class="material-icons-outlined"> lock </mat-icon>
        {{
          isChangePassword
            ? ("account.my-info" | translate)
            : ("login.change-password" | translate)
        }}
      </div>
    </div>
    <div
      class="profile-content settings-personal"
      *ngIf="activeContent == 'editPersonalInfo'"
    >
      <!-- Start change user info form -->
      <form #form="ngForm" id="profileForm">
        <!-- end edit user image or upload new image -->
        <div class="form-container">
          <!-- user name -->
          <div class="form-input">
            <mat-label
              >{{ "login.user-name" | translate }}
              <span class="label__required">*</span>
            </mat-label>
            <mat-form-field
              class="bodytext-normal"
              block
              appearance="fill"
              color="primary"
              [ngClass]="{ 'active-border': focused == 'username' }"
            >
              <input
                type="text"
                name="username"
                #userName="ngModel"
                [(ngModel)]="userEditableData.name"
                matInput
                required
                [pattern]="usernamePattern"
                maxlength="50"
                onblur="this.value=this.value.trim()"
                (focus)="focused = 'username'"
                (blur)="focused = ''"
                autocomplete="off"
              />
              <mat-error>{{
                "validation.user-name-required" | translate
              }}</mat-error>
            </mat-form-field>
          </div>
          <!-- company -->
          <div class="form-input">
            <mat-label>{{ "account.company" | translate }}</mat-label>
            <mat-form-field
              class="bodytext-normal"
              block
              appearance="fill"
              color="primary"
              [ngClass]="{ 'active-border': focused == 'company' }"
            >
              <input
                type="text"
                name="company"
                #company="ngModel"
                [(ngModel)]="userEditableData.additionalData.company"
                matInput
                maxlength="50"
                onblur="this.value=this.value.trim()"
                (focus)="focused = 'company'"
                (blur)="focused = ''"
                autocomplete="off"
              />
            </mat-form-field>
          </div>
          <!-- phone -->
          <div class="form-input">
            <mat-label
              >{{ "account.phone" | translate }}
              <!-- <span class="label__required">*</span> -->
            </mat-label>
            <mat-form-field
              class="bodytext-normal"
              block
              appearance="fill"
              color="primary"
              [ngClass]="{ 'active-border': focused == 'phone' }"
            >
              <input
                type="text"
                pattern="[0-9]*"
                matInput
                inputmode="phone"
                name="phone"
                #phone="ngModel"
                maxlength="20"
                [(ngModel)]="userEditableData.phone"
                onblur="this.value=this.value.trim()"
                onkeypress="return /[0-9]/i.test(event.key)"
                (focus)="focused = 'phone'"
                (blur)="focused = ''"
                autocomplete="off"
              />
              <mat-error>{{
                "validation.your-phone-num-required" | translate
              }}</mat-error>
            </mat-form-field>
          </div>
          <!-- country -->
          <div class="form-input">
            <mat-label>{{ "account.timezone" | translate }}</mat-label>

            <mat-form-field
              class="bodytext-normal"
              block
              appearance="fill"
              [ngClass]="{ 'active-border': focused == 'select' }"
              color="primary"
            >
              <input
                type="text"
                aria-label="Number"
                matInput
                name="timezone"
                #userTimezone="ngModel"
                [(ngModel)]="userEditableData.additionalData.timezone"
                required
                [matAutocomplete]="auto"
                pattern="^[^\s].+[^\s]$"
                (keyup)="filterTimeZone()"
                onblur="this.value=this.value.trim()"
                autocomplete="off"
              />
              <mat-icon class="expand">expand_more</mat-icon>
              <!--<mat-select
                name="country"
                #country="ngModel"
                [(ngModel)]="userEditableData.additionalData.timezone"
                (focus)="focused = 'select'"
                (blur)="focused = ''"
                [disabled]="!timezones?.length"
              >-->
              <mat-autocomplete #auto="matAutocomplete">
                <mat-option
                  *ngFor="let timezone of filteredTimeZones"
                  [value]="timezone?.tzCode"
                >
                  {{ timezone?.label }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>
          <!-- job title -->
          <div class="form-input">
            <mat-label>{{ "account.job-title" | translate }}</mat-label>

            <mat-form-field
              class="bodytext-normal"
              block
              appearance="fill"
              color="primary"
              [ngClass]="{ 'active-border': focused == 'job' }"
            >
              <input
                type="text"
                name="jobTitle"
                #jobTitle="ngModel"
                [(ngModel)]="userEditableData.additionalData.jobTitle"
                matInput
                maxlength="50"
                onblur="this.value=this.value.trim()"
                (focus)="focused = 'job'"
                (blur)="focused = ''"
                autocomplete="off"
              />
            </mat-form-field>
          </div>
        </div>
        <!-- change password  -->
        <!-- <div class="info-password-toggler" (click)="togglechangePassword()">
          {{
            isChangePassword
              ? ("account.my-info" | translate)
              : ("login.change-password" | translate)
          }}
        </div> -->

        <div class="form-footer">
          <!-- save profile data -->
          <button
            type="submit"
            mat-flat-button
            color="primary"
            class="main-btn button18 action-btn"
            (click)="updateUserInfo(form)"
            [disabled]="!form.valid || loading.save || !timezones?.length"
          >
            <app-loading-action *ngIf="loading.save"></app-loading-action>
            {{ "account.save-data" | translate }}
          </button>
        </div>
      </form>

      <div
        (click)="deleteAccount()"
        class="delete-account"
        [ngClass]="{ dirLtr: _lang.direction == 'ltr' }"
      >
        {{ "account.delete-account" | translate }}
      </div>
      <!-- End change user info form -->
    </div>
  </div>
</section>
