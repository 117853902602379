<div class="dialog-header subtitle1">
  <label>
    {{ "meeting-room.meeting-passcode" | translate }}
  </label>
  <mat-icon aria-label="close dialog" (click)="onNoClick()">close</mat-icon>
</div>

<form (ngSubmit)="joinMeeting()" #passCodeForm="ngForm">
  <mat-label>
    {{ "meeting-room.enter-passcode" | translate }}
    <span class="label__required">*</span>
  </mat-label>
  <mat-form-field
    class="bodytext-normal"
    block
    appearance="fill"
    color="primary"
    required
  >
    <input
      [type]="islPasswordHidden ? 'password' : 'text'"
      class="display-none"
      matInput
    />
    <input
      [type]="islPasswordHidden ? 'password' : 'text'"
      type="text"
      name="meetingPassCode"
      [(ngModel)]="meetingPassCode"
      matInput
      required
      autocomplete="off"
    />
    <button
      class="toggle-pass bodytext2"
      type="button"
      mat-icon-button
      matSuffix
      (click)="toggleDisplayPassword($event, 'meetingPassword')"
      [attr.aria-label]="'Hide password'"
      [attr.aria-pressed]="islPasswordHidden"
    >
      <mat-icon class="material-icons-outlined">{{
        islPasswordHidden ? "visibility_off" : "visibility"
      }}</mat-icon>
    </button>
    <mat-error>{{
      "validation.meeting-passcode-required" | translate
    }}</mat-error>
  </mat-form-field>

  <div class="dialog-footer">
    <button
      (click)="onNoClick()"
      class="main-btn button18 block close"
      mat-flat-button
      color="primary"
    >
      {{ "meeting-room.close" | translate }}
    </button>
    <button
      [disabled]="meetingPassCode.length == 0"
      class="main-btn button18 block"
      mat-flat-button
      color="primary"
      type="submit"
    >
      {{ "login.join-now" | translate }}
    </button>
  </div>
</form>
