import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { UserService } from '@kortobaa-front/authentication';

import { MeetingsService } from 'src/app/meeting/services/meetings.service';
import { LangService } from 'src/app/shared/services/lang.service';
import { DataService } from 'src/app/shared/services/data.service';
import { MeetingURLService } from 'src/app/shared/services/meeting-url.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/confirm-dialog/confirm-dialog.component';

import { ScheduledMeeting } from '@kortobaa-front/ng-kmeet-sdk';
import { formatDate, formatTime } from 'src/app/shared/helpers/convertDate';
import { Platform } from '@angular/cdk/platform';
import { OpenAppDialogComponent } from '../open-app-dialog/open-app-dialog.component';

@Component({
  selector: 'app-scheduled-meeting-list',
  templateUrl: './scheduled-meeting-list.component.html',
  styleUrls: ['./scheduled-meeting-list.component.scss'],
})
export class ScheduledMeetingListComponent implements OnInit, OnDestroy {
  public pageLoading = true;
  scheduledMeetingsList: ScheduledMeeting[] = [];
  scheduledMeetingsListCount: number = 0;
  public formatDate = formatDate;
  public formatTime = formatTime;

  private _loading = {
    save: false,
    info: false,
  };
  openAppUi = false;
  isTop: boolean = false;
  isBottom: boolean = false;
  limit: number = 10;
  offset: number = 0;
  @ViewChild('upcomingMeetings') upcomingMeetings!: ElementRef;
  //@Input() scheduledMeetingsListCount: number;
  upcomingRetryTimeout;

  constructor(
    public dataService: DataService,
    public lang: LangService,
    private _translate: TranslateService,
    private _meetingService: MeetingsService,
    private _dialog: MatDialog,
    private _router: Router,
    private _kortobaaPkgUserService: UserService,
    private _meetingURL: MeetingURLService,
    private platform: Platform
  ) {}

  ngOnInit() {
    if (
      this.platform.ANDROID ||
      (this.platform.IOS &&
        (this.dataService.url == '/home' || this.dataService.url == '/'))
    ) {
      this.openAppUi = true;
    }
    this.getUpcommingMeetings(this.limit, this.offset, true);

    // this.scheduledMeetingsList.length = this.limit;
  }

  // check scroll up or down
  checkScroll(event: any) {
    // check scroll top if greather than 0 show top icon and hide spinner
    if (event.target.scrollTop > 0) {
      this.isTop = true;
      this.isBottom = false;
    }

    // check scroll top if equal 0 hide top icon and hide spinner
    if (event.target.scrollTop == 0) {
      this.isTop = false;
      this.isBottom = false;
    }

    // add count to list on meetings
    //this.scheduledMeetingsList.length = this.limit;

    // check if scroll after 10 meetings show spinner and load 10 meetings after 10 before
    //console.log(parseInt("" + (event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight)))
    if (
      parseInt(
        '' +
          (event.target.scrollHeight -
            event.target.scrollTop -
            event.target.clientHeight)
      ) <= 0 &&
      this.scheduledMeetingsListCount != this.scheduledMeetingsList.length &&
      !this.isBottom
    ) {
      this.isBottom = true;

      this.getUpcommingMeetings(this.limit, this.scheduledMeetingsList.length);
    }

    // if (window.innerHeight < event.target.scrollTop) {
    //   this.isBottom = false;
    //   this.isTop = true;
    // }
  }

  // scroll to top and load first 10 meetings
  scrollToTop() {
    if (this.platform.isBrowser) {
      // this.offset = 0;
      // this.limit = 10;
      // this.getUpcommingMeetings(this.limit, this.offset);
      this.upcomingMeetings.nativeElement.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }

  showMore() {
    this.isBottom = true;
    this.getUpcommingMeetings(this.limit, this.scheduledMeetingsList.length);
  }

  // get meeting upcoming
  async getUpcommingMeetings(limit, offset, isFirstLoad = false, reconnectCounter = 0) {
    if (this.isBottom || this.isTop) {
      this.pageLoading = false;
    } else {
      this.pageLoading = true;
    }
    try {
      if (isFirstLoad) {
        const scheduledMeetingList = await this._meetingService
          .getUpcommingMeetingsCount()
          .toPromise();
  
        this.scheduledMeetingsListCount = scheduledMeetingList.count;
      }
      const scheduledMeetingsList = await this._meetingService
        .getUpcommingMeetings(limit, offset)
        .toPromise();

      const clonedMeetings = JSON.parse(
        JSON.stringify(this.scheduledMeetingsList)
      );
      this.scheduledMeetingsList = [].concat(
        clonedMeetings,
        scheduledMeetingsList
      );

      // sort meeting list
      /*this.scheduledMeetingsList.sort((a, b) =>
        a.startDate > b.startDate ? 1 : -1
      );*/
      this.pageLoading = false;
      this.isBottom = false;
    } catch (error) {
      let message: string;
      if (error.status == 401) {
        //message = this._translate.instant('errors.unauthorized');
        this.upcomingRetryTimeout = setTimeout(async () => {
          clearTimeout(this.upcomingRetryTimeout);
          if(reconnectCounter < 3) await this.getUpcommingMeetings(limit, offset, isFirstLoad, reconnectCounter + 1);
        }, 3000);
      } else if (error.status == 400 || error.status == 422) {
        //message = this._translate.instant(error.error.error.message);
        this.pageLoading = false;
      } else {
        message = this._translate.instant(error.message);
        this.pageLoading = false;
      }
      if(message) this.dataService.notification(message, true);
    }
    // const isUserValid = await isUserValid$.toPromise();
  }

  startScheduledMeetingNow(token, hasPass, pass, excludeLink) {
    if (this.openAppUi) {
      this.openAppDialog(
        'openMeet',
        `${token}${hasPass && !excludeLink ? `?p=` + pass : ''}`
      );
      return;
    }
    this._router.navigate(
      ['meeting/' + token],
      hasPass && !excludeLink ? { queryParams: { p: pass } } : {}
    );
  }

  async cancelScheduledMeeting(id: number) {
    const check = await this._dialog
      .open(ConfirmDialogComponent, {
        direction: this.lang.direction,
        disableClose: true,
        panelClass: 'confirm-dialog',
        data: {
          title: this._translate.instant('account.cancel-modal.cancel-meeting'),
          content: this._translate.instant('account.cancel-modal.are-you-sure'),
        },
      })
      .afterClosed()
      .toPromise();
    if (check) {
      try {
        this._loading.save = true;
        await this._meetingService.cancelUpcommingMeeting(id).toPromise();

        const successMessage = this._translate.instant(
          'account.meeting-canceled-successfully'
        );

        this.getUpcommingMeetings(this.limit, this.offset);

        this.dataService.notification(successMessage);
        this._loading.save = false;
      } catch (error) {
        this._loading.save = false;
        let message;

        if (error.status == 401) {
          message = this._translate.instant('errors.unauthorized');
        } else if (error.status == 400 || error.status == 422) {
          message = this._translate.instant(error.error.error.message);
        } else {
          message = this._translate.instant(error.message);
        }

        this.dataService.notification(message, true);
      }
    }
  }

  // copy meeting
  copyMeetingUrl(meetingToken: string) {
    this._meetingURL.copyMeetingUrl(meetingToken);
  }

  //show and hide upcomming meeting actions for screen size lees than 701px
  toggleUpcommingMeetingAction(id: number) {
    const currentMeetingCard = document.getElementById(id.toString());
    currentMeetingCard.classList.toggle('opened');
  }

  openAppDialog(value: string, token?: any) {
    this._dialog.open(OpenAppDialogComponent, {
      width: 'auto',
      minHeight: 'auto',
      direction: this.lang.direction,
      autoFocus: false,
      panelClass: ['openApp'],
      data: {
        type: value,
        status: this.openAppUi,
        token: token,
      },
    });
  }

  ngOnDestroy() {
    if(this.upcomingRetryTimeout) clearTimeout(this.upcomingRetryTimeout);
  }
}
