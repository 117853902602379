import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { Observable } from 'rxjs';

import { JSEncrypt } from 'jsencrypt';

import { DataService } from 'src/app/shared/services/data.service';
import { environment } from 'src/environments/environment';

@Pipe({
  name: 'secure',
})
export class SecurePipe implements PipeTransform {
  $encrypt: any;

  constructor(
    private _http: HttpClient,
    private _dataService: DataService,
    private _sanitizer: DomSanitizer
  ) {
    this.$encrypt = new JSEncrypt({ default_key_size: 2048 as any });
    this.$encrypt.setPublicKey(environment.ENCRYPTION_PUBLIC_KEY);
  }

  transform(url: string) {
    return new Observable<string>((observer) => {
      // This is a default image
      let imageBase;
      imageBase =
        'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB3aWR0aD0iNTEiIGhlaWdodD0iNTEiIHZpZXdCb3g9IjAgMCA1MSA1MSI+PGRlZnM+PHN0eWxlPi5he2ZpbGw6IzQxNDE3YTt9LmJ7ZmlsbDpub25lO30uY3tmaWxsOiMwMDgzZjA7fS5ke2ZpbHRlcjp1cmwoI2EpO308L3N0eWxlPjxmaWx0ZXIgaWQ9ImEiIHg9IjAiIHk9IjAiIHdpZHRoPSI1MSIgaGVpZ2h0PSI1MSIgZmlsdGVyVW5pdHM9InVzZXJTcGFjZU9uVXNlIj48ZmVPZmZzZXQgaW5wdXQ9IlNvdXJjZUFscGhhIi8+PGZlR2F1c3NpYW5CbHVyIHN0ZERldmlhdGlvbj0iMi41IiByZXN1bHQ9ImIiLz48ZmVGbG9vZCBmbG9vZC1jb2xvcj0iIzQxNDE3YSIvPjxmZUNvbXBvc2l0ZSBvcGVyYXRvcj0iaW4iIGluMj0iYiIvPjxmZUNvbXBvc2l0ZSBpbj0iU291cmNlR3JhcGhpYyIvPjwvZmlsdGVyPjwvZGVmcz48ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzM4LjUgLTI0MC41KSI+PGcgY2xhc3M9ImQiIHRyYW5zZm9ybT0ibWF0cml4KDEsIDAsIDAsIDEsIDMzOC41LCAyNDAuNSkiPjxjaXJjbGUgY2xhc3M9ImEiIGN4PSIxOCIgY3k9IjE4IiByPSIxOCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNy41IDcuNSkiLz48L2c+PGcgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoMzU0IDI1NikiPjxwYXRoIGNsYXNzPSJiIiBkPSJNMCwwSDIwLjQyNlYyMC40MjZIMFoiLz48cGF0aCBjbGFzcz0iYyIgZD0iTTExLjE0OSwxMS4xNDlBMy41NzQsMy41NzQsMCwxLDAsNy41NzQsNy41NzQsMy41NzMsMy41NzMsMCwwLDAsMTEuMTQ5LDExLjE0OVptMCwxLjc4N0M4Ljc2MywxMi45MzYsNCwxNC4xMzQsNCwxNi41MTFWMTcuNGEuOS45LDAsMCwwLC44OTQuODk0SDE3LjRBLjkuOSwwLDAsMCwxOC4zLDE3LjR2LS44OTRDMTguMywxNC4xMzQsMTMuNTM1LDEyLjkzNiwxMS4xNDksMTIuOTM2WiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTAuOTM2IC0wLjkzNikiLz48L2c+PC9nPjwvc3ZnPg==';
      let sanitized: any = this._sanitizer.bypassSecurityTrustUrl(imageBase);
      observer.next(sanitized);

      // The next and error callbacks from the observer
      const userToken = this._dataService.getUserToken();
      const headers = this.setHeadersWithToken(userToken);
      this._http.get(url, { headers: headers, responseType: 'blob' }).subscribe(
        (response) => {
          const reader: any = new FileReader();
          reader.readAsDataURL(response);
          reader.onloadend = () => {
            let sanitized: any = this._sanitizer.bypassSecurityTrustUrl(
              reader.result
            );
            observer.next(sanitized);
          };
        },
        (error) => console.log('Cannot find user image ', error)
      );

      return { unsubscribe() {} };
    });
  }

  setHeadersWithToken(token: string) {
    return new HttpHeaders().append('authorization', `Bearer ${token}`);
  }
}
