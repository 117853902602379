<!-- Start reconnecting to meeting view -->
<div
  class="snack"
  [ngClass]="{
    visible: kortobaaPkg.showDisconnectedSnackbar && kortobaaPkg.reconnecting
  }"
  #reconnect
>
  <span>{{ "Try-Reconnecting" | translate }}</span>
</div>

<div class="reconnecting" *ngIf="kortobaaPkg.reconnecting">
  <div class="fixed-container">
    <img src="../../../assets/images/logo.png" />
    <p
      style="font-size: 1.25rem; color: #fff; margin: 16px; text-align: center"
    >
      {{ "Reconnecting" | translate }}
    </p>
    <button mat-flat-button color="primary" (click)="backToHome()">
      {{ "Back to home" | translate }}
    </button>
  </div>
</div>

<!-- End reconnecting to meeting view -->

<!-- Start meeting view -->
<div class="meeting-container" *ngIf="!kortobaaPkg.isMeetingScheduled">
  <!-- <ng-container *ngIf="dataService.getMeetingInfo()"> -->
  <!-- If used the line above it will not work because the problem till now I can't set the meeting info -->
  <ng-container>
    <app-meeting-screen
      [activeSpeakers]="kortobaaPkg.activeSpeakers"
      [meetingMembers]="kortobaaPkg.meetingStreams"
      [sharingScreen]="kortobaaPkg.sharingScreen"
      [viewingScreen]="kortobaaPkg.viewingScreen"
      [meetingParticipants]="kortobaaPkg.participants"
      [roomTitle]="kortobaaPkg.roomTitle"
      [meetingInfo]="meetingInfo"
      [viewOptions]="kortobaaPkg.viewOptions"
      [waitingRoom]="waitingUsers"
      [host]="kortobaaPkg.isHost"
      [waitingScreen]="kortobaaPkg.waitingScreen"
      [waitingScreenData]="kortobaaPkg.waitingScreenData"
      [waitingRoomDelivered]="kortobaaPkg.waitingRoomDelivered"
      [sideCollapsed]="sideCollapsed"
      (sharedScreenStopped)="kortobaaPkg.stopShareScreen()"
    >
    </app-meeting-screen>

    <app-meeting-side-control
      [meetingInfo]="{ token: kortobaaPkg.meetingToken, title: 'No title' }"
      [timePassedBySeconds]="kortobaaPkg.meetingTimer.timePassedBySeconds"
      [showMembersList]="showMembersList"
      [showChatBox]="showChatBox"
      [sideCollapsed]="sideCollapsed"
      [meetingMembers]="kortobaaPkg.participants"
      [host]="kortobaaPkg.isHost"
      [chat]="kortobaaPkg.chat"
      [unreadMessages]="unreadMessages"
      [roomTitle]="kortobaaPkg.roomTitle"
      [meetingSettings]="kortobaaPkg.meetingSettings"
      (muteUnAllUsers)="kortobaaPkg.muteUnAll($event)"
      (waitingAreaAction)="kortobaaPkg.acceptOneOnWaitingArea($event)"
      (waitingAreaAcceptAll)="kortobaaPkg.acceptAllOnWaitingArea()"
      (muteUser)="kortobaaPkg.muteUser($event)"
      (sendMessageToAll)="kortobaaPkg.sendMessageToAll($event)"
      (chatBoxStatus)="chatBoxStatus($event)"
      (removeUserFromMeeting)="
        kortobaaPkg.removeUserFromMeeting($event.memberId, $event.action)
      "
      (changeMeetingTitle)="kortobaaPkg.changeMeetingTitle($event)"
      (assignNewHost)="kortobaaPkg.assignNewHost($event)"
      [viewingScreen]="kortobaaPkg.viewingScreen"
      [userId]="kortobaaPkg.userId"
      [waitingRoom]="waitingUsers"
      [viewOptions]="kortobaaPkg.viewOptions"
      (toggleSideControl)="toggleSideControl($event)"
      (showMembersStatus)="showMembersStatus($event)"
    >
    </app-meeting-side-control>

    <!-- start meeting bottom layout -->

    <div class="control-drag-container">
      <div
        cdkDragBoundary=".control-drag-container"
        cdkDrag
        class="control-bar control-drag"
        [ngClass]="{
          'fit-screen': !sideCollapsed,
          'bottom-layout-control-bar':
            kortobaaPkg.viewOptions.layoutType == 'bottomThumbnail',
          dirLtr: _lang.direction == 'ltr'
        }"
      >
        <!-- mute and unmute audio buttons -->
        <div
          cdkScrollable
          matTooltipClass="tooltip-control"
          #tooltip="matTooltip"
          [matTooltip]="
            (kortobaaPkg.hasAudio
              ? 'meeting-room.mute-audio'
              : 'meeting-room.unmute-audio'
            ) | translate
          "
          matTooltipPosition="above"
          class="control-bar-btn control-bar-btn-with-options bg"
          [ngStyle]="{
            backgroundColor: userMediaService.isInputDisabled
              ? 'rgba(179, 38, 30, 0.2)'
              : ''
          }"
        >
          <!-- [loading]="kortobaaPkg.loading.audio" -->

          <button
            class="subtitle2"
            mat-button
            *ngIf="kortobaaPkg.hasAudio && !userMediaService.isInputDisabled"
            (click)="kortobaaPkg.muteMic()"
            [disabled]="kortobaaPkg.mutedByAdmin && !kortobaaPkg.isHost"
          >
            <figure>
              <img
                src="../../../assets/images/icons/mic_on.svg"
                alt="'meeting-room.mute-audio' | translate"
              />
            </figure>

            <!-- <mat-icon class="media-icon">keyboard_voice</mat-icon> -->
            <!-- <span class="btn-text">
            {{ "meeting-room.mute-audio" | translate }}
          </span> -->
          </button>

          <!-- [loadng]="kortobaaPkg.loading.audio" -->

          <button
            class="subtitle2"
            mat-button
            *ngIf="!kortobaaPkg.hasAudio && !userMediaService.isInputDisabled"
            (click)="kortobaaPkg.unmuteMic()"
            [disabled]="kortobaaPkg.mutedByAdmin && !kortobaaPkg.isHost"
          >
            <figure>
              <img
                src="../../../assets/images/icons/mic_off.svg"
                alt="'meeting-room.unmute-audio' | translate"
              />
            </figure>
            <!-- <mat-icon class="aduio-off-icon">mic_off</mat-icon> -->
            <!-- <span class="btn-text">
            {{ "meeting-room.unmute-audio" | translate }}
          </span> -->
          </button>

          <button
            class="subtitle2 disabled"
            mat-button
            *ngIf="userMediaService.isInputDisabled"
            (click)="noPermission()"
          >
            <figure>
              <img
                class="icon"
                src="../../../assets/images/icons/mic_on.svg"
                alt="'meeting-room.stop-video' | translate"
              />
              <img
                class="permission"
                *ngIf="userMediaService.isInputDisabled"
                src="../../../assets/images/icons/permission-info.svg"
              />
            </figure>
            <!-- <mat-icon class="media-icon">videocam</mat-icon> -->
            <!-- <span class="btn-text">
        {{ "meeting-room.stop-video" | translate }}
      </span> -->
          </button>

          <!-- <div
          (click)="isAudioMenuOpen = true"
          [ngClass]="isAudioMenuOpen && 'opened-menu'"
        >
          <mat-icon
            *ngIf="audioSettings"
            #audioMenuTrigger="matMenuTrigger"
            [matMenuTriggerFor]="audioSettings"
            class="menu-icon"
          >
            expand_more
          </mat-icon>
        </div> -->

          <mat-menu
            #audioSettings="matMenu"
            class="meeting-menu settings-menu"
            (closed)="isAudioMenuOpen = false"
          >
            <div class="menu-content" clickStopPropagation>
              <!-- NOTE: DONNOT remove (UI without logic) -->
              <!-- <div class="menu-label">
              <mat-icon>campaign</mat-icon>
              <span> {{ "settings.speaker" | translate }}</span>
            </div>

            <mat-checkbox color="accent" name="muteParticipants">
              {{ "settings.speaker-sound-card" | translate }}
            </mat-checkbox>

            <mat-checkbox color="accent" name="muteParticipants">
              {{ "settings.speaker-headphones" | translate }}
            </mat-checkbox>
            <mat-divider></mat-divider>

            <div class="menu-label">
              <mat-icon>mic</mat-icon>
              <span> {{ "settings.mic" | translate }}</span>
            </div>

            <mat-checkbox color="accent" name="muteParticipants">
              {{ "settings.mic-sound-card" | translate }}
            </mat-checkbox>

            <mat-checkbox color="accent" name="muteParticipants">
              {{ "settings.microphone-array" | translate }}
            </mat-checkbox>
            <mat-divider></mat-divider>

            <div class="menu-label">
              <mat-icon>settings_voice</mat-icon>
              <span> {{ "settings.smart-voice" | translate }}</span>
            </div>

            <mat-checkbox color="accent" name="muteParticipants">
              {{ "settings.noise-cancellation" | translate }}
            </mat-checkbox>
            <mat-divider></mat-divider> -->

              <button mat-menu-item (click)="openSettingsDialog('audio')">
                <mat-icon>settings</mat-icon>
                <span>{{ "settings.audio-settings" | translate }}</span>
              </button>
            </div>
          </mat-menu>
        </div>

        <!-- start and stop video buttons -->
        <div
          cdkScrollable
          matTooltipClass="tooltip-control"
          #tooltip="matTooltip"
          [matTooltip]="
            (kortobaaPkg.hasVideo
              ? 'meeting-room.stop-video'
              : 'meeting-room.start-video'
            ) | translate
          "
          matTooltipPosition="above"
          class="control-bar-btn control-bar-btn-with-options bg"
          [ngStyle]="{
            backgroundColor: userMediaService.isVideoDisabled
              ? 'rgba(179, 38, 30, 0.2)'
              : ''
          }"
        >
          <!-- [loading]="kortobaaPkg.loading.video" -->

          <button
            class="subtitle2"
            mat-button
            *ngIf="kortobaaPkg.hasVideo && !userMediaService.isVideoDisabled"
            (click)="kortobaaPkg.checkVideo(false)"
            [disabled]="kortobaaPkg.disabledBtns.video"
          >
            <figure>
              <img
                src="../../../assets/images/icons/videocam_on.svg"
                alt="'meeting-room.stop-video' | translate"
              />
              <img
                *ngIf="userMediaService.isVideoDisabled"
                src="../../../assets/images/icons/permission-info.svg"
              />
            </figure>
            <!-- <mat-icon class="media-icon">videocam</mat-icon> -->
            <!-- <span class="btn-text">
            {{ "meeting-room.stop-video" | translate }}
          </span> -->
          </button>

          <!-- [loading]="kortobaaPkg.loading.video" -->

          <button
            class="subtitle2"
            mat-button
            *ngIf="!kortobaaPkg.hasVideo && !userMediaService.isVideoDisabled"
            (click)="kortobaaPkg.checkVideo(true)"
            [disabled]="kortobaaPkg.disabledBtns.video"
          >
            <figure>
              <img
                src="../../../assets/images/icons/videocam_off.svg"
                alt="'meeting-room.start-video' | translate"
              />
            </figure>
            <!-- <mat-icon class="aduio-off-icon">videocam_off</mat-icon> -->
            <!-- <span class="btn-text">
            {{ "meeting-room.start-video" | translate }}
          </span> -->
          </button>

          <button
            class="subtitle2 disabled"
            mat-button
            *ngIf="userMediaService.isVideoDisabled"
            (click)="noPermission()"
          >
            <figure>
              <img
                class="icon"
                src="../../../assets/images/icons/videocam_on.svg"
                alt="'meeting-room.stop-video' | translate"
              />
              <img
                class="permission"
                *ngIf="userMediaService.isVideoDisabled"
                src="../../../assets/images/icons/permission-info.svg"
              />
            </figure>
            <!-- <mat-icon class="media-icon">videocam</mat-icon> -->
            <!-- <span class="btn-text">
          {{ "meeting-room.stop-video" | translate }}
        </span> -->
          </button>

          <!-- <div
          (click)="isVideoMenuOpen = true"
          [ngClass]="{
            'opened-menu': isVideoMenuOpen,
            disabled: !kortobaaPkg.hasVideo
          }"
        >
          <mat-icon
            #videoMenuTrigger="matMenuTrigger"
            [matMenuTriggerFor]="videoSettings"
            class="menu-icon"
          >
            expand_more
          </mat-icon>
        </div> -->

          <mat-menu
            #videoSettings="matMenu"
            class="meeting-menu settings-menu"
            (closed)="isVideoMenuOpen = false"
          >
            <div class="menu-content" clickStopPropagation>
              <!-- NOTE: DONNOT remove (UI without logic) -->
              <!-- <div class="menu-label">
              <mat-icon>photo_camera</mat-icon>
              <span> {{ "settings.camera" | translate }} </span>
            </div>

            <mat-checkbox color="accent" name="muteParticipants">
              {{ "settings.integrated-webcam" | translate }}
            </mat-checkbox>
            <mat-divider></mat-divider> -->

              <button mat-menu-item (click)="openSettingsDialog('video')">
                <mat-icon>settings</mat-icon>
                <span>{{ "settings.video-settings" | translate }}</span>
              </button>
              <!-- <mat-divider></mat-divider>

            <button mat-menu-item>
              <mat-icon>add_photo_alternate</mat-icon>
              <span>
                {{ "settings.change-default-background" | translate }}</span
              >
            </button> -->
            </div>
          </mat-menu>
        </div>

        <!-- start and stop share screen buttons -->
        <div
          class="control-bar-btn bg"
          [ngClass]="{ active: kortobaaPkg.shareScreen }"
          cdkScrollable
          matTooltipClass="tooltip-control"
          #tooltip="matTooltip"
          [matTooltip]="
            (!kortobaaPkg.shareScreen
              ? 'meeting-room.share-screen'
              : 'meeting-room.stop-share-screen'
            ) | translate
          "
          matTooltipPosition="above"
        >
          <button
            class="subtitle2"
            [ngClass]="{ disapled: kortobaaPkg.viewingScreen.on }"
            mat-button
            *ngIf="!kortobaaPkg.shareScreen"
            (click)="kortobaaPkg.startShareScreen()"
            [disabled]="kortobaaPkg.viewingScreen.on"
          >
            <figure>
              <img
                src="../../../assets/images/icons/share_on.svg"
                alt="'meeting-room.share-screen' | translate"
              />
            </figure>
            <!-- <mat-icon>screen_share</mat-icon> -->
            <!-- <span class="btn-text">
            {{ "meeting-room.share-screen" | translate }}
          </span> -->
          </button>

          <button
            mat-button
            class="subtitle2"
            *ngIf="kortobaaPkg.shareScreen"
            (click)="kortobaaPkg.stopShareScreen()"
          >
            <figure>
              <img
                src="../../../assets/images/icons/share_off.svg"
                alt="'meeting-room.stop-share-screen' | translate"
              />
            </figure>
            <!-- <mat-icon>stop_screen_share</mat-icon> -->
            <!-- <span class="btn-text">
            {{ "meeting-room.stop-share-screen" | translate }}
          </span> -->
          </button>
        </div>

        <!-- NOTE: DONNOT remove (UI without logic) -->
        <!-- record meeting buttons for host only -->
        <!-- <div
          [ngClass]="{ active: dataService.active }"
          class="control-bar-btn bg"
          *ngIf="kortobaaPkg.isHost"
          cdkScrollable
          matTooltipClass="tooltip-control"
          #tooltip="matTooltip"
          [matTooltip]="
            (!dataService.active
              ? 'meeting-room.record-meeting'
              : 'meeting-room.stop-recording'
            ) | translate
          "
          matTooltipPosition="above"
        >
          <button
            class="subtitle2"
            mat-button
            *ngIf="!dataService.active"
            (click)="recordVideo()"
          >
            <figure>
              <img
                src="../../../assets/images/icons/record.svg"
                alt="'meeting-room.record-meeting' | translate"
              />
            </figure>
          </button>

          <button
            class="subtitle2"
            mat-button
            *ngIf="dataService.active"
            (click)="stopRecording()"
          >
            <figure>
              <img
                src="../../../assets/images/icons/record.svg"
                alt="'meeting-room.stop-recording' | translate"
              />
            </figure>
          </button>
        </div> -->

        <!-- open and close setting buttons -->
        <div
          class="control-bar-btn"
          [ngClass]="{ active: '' }"
          cdkScrollable
          matTooltipClass="tooltip-control"
          #tooltip="matTooltip"
          [matTooltip]="'meeting-room.more' | translate"
          matTooltipPosition="above"
        >
          <button [matMenuTriggerFor]="moreMenu" class="subtitle2" mat-button>
            <mat-icon class="material-icons-outlined">more_horiz</mat-icon>
          </button>

          <mat-menu
            #moreMenu="matMenu"
            class="more_menu more_after"
            [class]="_lang.direction == 'ltr' ? 'dirLtr' : ''"
            yPosition="above"
            xPosition="after"
          >
            <button mat-menu-item (click)="openSettingsDialog(0)">
              <figure>
                <img
                  src="../../../assets/images/icons/speaker_phone.svg"
                  [alt]="'settings.audio-settings' | translate"
                />
              </figure>
              <span>{{ "settings.audio-settings" | translate }}</span>
            </button>
            <button mat-menu-item (click)="openSettingsDialog(1)">
              <figure>
                <img
                  src="../../../assets/images/icons/videocam.svg"
                  [alt]="'settings.video-settings' | translate"
                />
              </figure>
              <span>{{ "settings.video-settings" | translate }}</span>
            </button>
            <button mat-menu-item (click)="openSettingsDialog(2)">
              <figure>
                <img
                  src="../../../assets/images/icons/settings.svg"
                  [alt]="'account.general' | translate"
                />
              </figure>
              <span>{{ "account.general" | translate }}</span>
            </button>
            <button
              mat-menu-item
              (click)="openSettingsDialog(3)"
              *ngIf="kortobaaPkg.isHost"
            >
              <figure>
                <img
                  src="../../../assets/images/icons/meeting_room.svg"
                  [alt]="'settings.meeting-settings' | translate"
                />
              </figure>
              <span>{{ "settings.meeting-settings" | translate }}</span>
            </button>
          </mat-menu>
        </div>

        <!-- open and close chat screen buttons -->
        <div
          class="control-bar-btn"
          [ngClass]="{ active: showChatBox }"
          cdkScrollable
          matTooltipClass="tooltip-control"
          #tooltip="matTooltip"
          [matTooltip]="
            (showChatBox ? 'meeting-room.hide-chat' : 'meeting-room.show-chat')
              | translate
          "
          matTooltipPosition="above"
        >
          <button
            class="subtitle2"
            mat-button
            *ngIf="!showChatBox"
            (click)="chatBoxStatus(true); toggleSideControl(false)"
          >
            <figure
              [ngClass]="{
                notify: counterMsg > 0,
                dirLtr: _lang.direction == 'ltr'
              }"
            >
              <img
                src="../../../assets/images/icons/chat.svg"
                alt="'meeting-room.hide-chat' | translate"
              />
            </figure>
          </button>

          <button
            mat-button
            class="subtitle2"
            *ngIf="showChatBox"
            (click)="chatBoxStatus(false); toggleSideControl(true)"
          >
            <figure>
              <img
                src="../../../assets/images/icons/chat.svg"
                alt="'meeting-room.show-chat' | translate"
              />
            </figure>
          </button>
        </div>

        <!-- open and close participants  -->
        <div
          class="control-bar-btn"
          [ngClass]="{
            active: showMembersList
          }"
          cdkScrollable
          matTooltipClass="tooltip-control"
          #tooltip="matTooltip"
          [matTooltip]="
            (showMembersList
              ? 'meeting-room.hide-members'
              : 'meeting-room.show-members'
            ) | translate
          "
          matTooltipPosition="above"
        >
          <button
            class="subtitle2"
            mat-button
            *ngIf="!showMembersList"
            (click)="showMembersStatus(true); toggleSideControl(false)"
          >
            <figure
              [ngClass]="{
                notify: waitingUsers.length > 0,
                dirLtr: _lang.direction == 'ltr'
              }"
            >
              <img
                src="../../../assets/images/icons/group.svg"
                alt="'meeting-room.hide-members' | translate"
              />
            </figure>
          </button>

          <button
            mat-button
            class="subtitle2"
            *ngIf="showMembersList"
            (click)="showMembersStatus(false); toggleSideControl(true)"
          >
            <figure>
              <img
                src="../../../assets/images/icons/group.svg"
                alt="'meeting-room.show-members' | translate"
              />
            </figure>
          </button>
        </div>

        <!-- NOTE: DONNOT remove (UI without logic) -->
        <!-- Reactions and raise hand buttons -->
        <!-- <div class="control-bar-btn">
        <button class="subtitle2" mat-button>
          <mat-icon>sentiment_satisfied</mat-icon>
        </button>

        <mat-divider vertical></mat-divider>

        <button
          class="subtitle2"
          [class.raised-hand]="raisedHand"
          mat-button
          (click)="toggleRaiseHand()"
        >
          <mat-icon class="hand-icon">pan_tool</mat-icon>
        </button>
      </div> -->

        <!-- leave and end meeting buttons -->
        <div
          class="control-bar-btn leave-meeting-btn"
          cdkScrollable
          matTooltipClass="tooltip-control"
          #tooltip="matTooltip"
          [matTooltip]="'meeting-room.close-video' | translate"
          matTooltipPosition="above"
        >
          <button
            mat-icon-button
            aria-label="leave this meating"
            (click)="leaveMeetingDialog()"
          >
            <figure>
              <img
                src="../../../assets/images/icons/call_end.svg"
                alt="'meeting-room.close-video' | translate"
              />
            </figure>
            <!-- <mat-icon>call_end</mat-icon> -->
          </button>
        </div>
      </div>
    </div>
    <!-- end meeting bottom layout -->

    <!-- Start meeting timer and signal -->
    <div
      fxHide.lt-md
      class="timer subtitle2"
      [ngClass]="{
        'fit-screen': !sideCollapsed,
        'bottom-layout-control-bar':
          kortobaaPkg.viewOptions.layoutType == 'bottomThumbnail',
        dirLtr: _lang.direction == 'ltr'
      }"
    >
      <div class="time-counter">
        <mat-icon
          [class]="
            kortobaaPkg.networkQuality == 'loading'
              ? 'strong'
              : kortobaaPkg.networkQuality
          "
          >rss_feed</mat-icon
        >
        <span
          *ngIf="kortobaaPkg.userMeetingTimer.timer"
          [ngClass]="{ dirLtr: _lang.direction == 'ltr' }"
        >
          {{ kortobaaPkg.userMeetingTimer.timer }}</span
        >
      </div>
      <span class="h-line"></span>
      <span class="meeting-title" *ngIf="meetingTitle"
        >{{ meetingTitle | slice : 0 : 15
        }}<span *ngIf="meetingTitle.length > 15">
          ..
          <div class="tooltip">
            <mat-icon
              class="material-icons-outlined info"
              matTooltip="{{ meetingTitle }}"
              matTooltipClass="info-tooltip"
              style="transform: scale(0.6)"
            >
              info</mat-icon
            >
            <!--<span class="tooltiptext">
              {{ meetingTitle }}
            </span>-->
          </div></span
        ></span
      >
      <!-- <span>
        {{
          kortobaaPkg.meetingTimer.seconds < 0 ||
          kortobaaPkg.meetingTimer.minutes < 0 ||
          kortobaaPkg.meetingTimer.hours < 0
            ? ("meeting-room.meeting-not-start" | translate)
            : kortobaaPkg.meetingTimer.timer
        }}</span
      > -->
    </div>
    <!-- End meeting timer and signal -->

    <!-- Start waiting room notification box for host only -->
    <div
      class="waiting-room-notifications"
      *ngIf="
        showWaitingRoomNotification && kortobaaPkg.isHost && waitingUsers.length
      "
    >
      <div
        class="waiting-room-header"
        [ngClass]="{ dirLtr: _lang.direction == 'ltr' }"
      >
        <div class="member-list">
          <div class="member">
            <div style="display: flex; align-items: center">
              <div class="user-img-container">
                <img
                  [src]="waitingUsers[0].image"
                  ployfillImg
                  [alt]="waitingUsers[0].name"
                  class="user-img"
                />
              </div>
            </div>
          </div>
          <div class="member" *ngIf="waitingUsers.length > 1">
            <div style="display: flex; align-items: center">
              <div class="user-img-container">
                <img
                  [src]="waitingUsers[1].image"
                  ployfillImg
                  [alt]="waitingUsers[1].name"
                  class="user-img"
                />
              </div>
            </div>
          </div>
          <div class="member" *ngIf="waitingUsers.length > 2">
            <div style="display: flex; align-items: center">
              <div class="user-img-container">
                <span class="user-img img more-users"
                  >{{ waitingUsers.length - 2 }} +</span
                >
                <!-- <img
                  [src]="waitingUsers[3].image"
                  ployfillImg
                  [alt]="waitingUsers[3].name"
                  ployfillImg
                  class="user-img img"
                /> -->
              </div>
            </div>
          </div>
        </div>
        <div class="waiting-room-label" *ngIf="waitingUsers.length > 0">
          <label>
            {{
              (waitingUsers.length > 1
                ? "meeting-side.in-the-waiting-room-more"
                : "meeting-side.in-the-waiting-room-1"
              ) | translate
            }}
          </label>
          <div class="member-name bodytext-normal">
            <p>
              {{
                waitingUsers.length >= 2
                  ? waitingUsers[0].name +
                    " " +
                    ("meeting-side.and" | translate) +
                    " " +
                    waitingUsers[1].name
                  : waitingUsers[0].name
              }}
            </p>
            <span *ngIf="waitingUsers.length > 2">
              {{ " + " + (waitingUsers.length - 2) }}
            </span>
          </div>
        </div>

        <button
          class="close-btn"
          mat-button
          (click)="showWaitingRoomNotification = false"
        >
          <mat-icon>close</mat-icon>
        </button>
      </div>

      <div
        class="waiting-room-list custom-scroll"
        *ngIf="waitingUsers.length > 1 && showAll"
      >
        <mat-divider></mat-divider>

        <div class="member" *ngFor="let member of waitingUsers">
          <div style="display: flex; align-items: center">
            <div class="user-img-container">
              <img
                [src]="member.image"
                ployfillImg
                [alt]="member.name"
                class="user-img img"
              />
            </div>

            <p class="member-name bodytext-normal">
              {{ member.name }}
              <!-- <span class="subtitle1">
                {{ "meeting-side.guest" | translate }}
              </span> -->
            </p>
          </div>
          <div class="waiting-room-actions" *ngIf="waitingUsers.length > 1">
            <a
              (click)="acceptMemeber(member.userId, true)"
              class="accept-action"
            >
              <mat-icon>check</mat-icon>
            </a>

            <a
              (click)="acceptMemeber(member.userId, false)"
              class="reject-action"
            >
              <mat-icon>close</mat-icon>
            </a>
          </div>
        </div>

        <mat-divider></mat-divider>
      </div>

      <div class="waiting-room-all-actions">
        <span>
          <figure>
            <img src="../../../assets/images/lock.svg" alt="" />
          </figure>
          <h6>
            {{ "meeting-side.waiting-warning" | translate }}
          </h6>
        </span>

        <div>
          <button
            mat-button
            [ngClass]="{
              'accept-all': waitingUsers.length > 1 && showAll == false,
              accept: waitingUsers.length == 1 || showAll == true
            }"
            (click)="
              waitingUsers.length == 1
                ? acceptMemeber(waitingUsers[0].userId, true)
                : waitingUsers.length > 1
                ? kortobaaPkg.acceptAllOnWaitingArea()
                : ''
            "
          >
            {{
              (waitingUsers.length > 1
                ? "meeting-side.accept-all"
                : "meeting-side.accept"
              ) | translate
            }}
          </button>
          <button
            (click)="
              showAll == false && waitingUsers.length > 1
                ? (showAll = true)
                : acceptMemeber(waitingUsers[0].userId, false)
            "
            mat-button
            [ngClass]="{
              'refuse-all': waitingUsers.length > 1 && showAll == false,
              refuse: waitingUsers.length == 1 || showAll == true
            }"
          >
            {{
              (showAll
                ? "meeting-side.refuse-all"
                : waitingUsers.length > 1 && showAll == false
                ? "meeting-side.show-all"
                : "meeting-side.refuse"
              ) | translate
            }}
          </button>
        </div>
      </div>
    </div>
    <!-- End waiting room notification box for host only -->

    <!-- Start invitation box -->
    <div
      class="invite-box"
      *ngIf="showInviteBox && kortobaaPkg.participants?.length <= 1"
      [ngClass]="{ dirLtr: _lang.direction == 'ltr' }"
    >
      <div class="invite-header">
        <h3>
          {{ "invite-box.title" | translate }}
        </h3>
        <button class="close-btn" mat-button (click)="showInviteBox = false">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="invite-content">
        <p>
          {{ "invite-box.subtitle" | translate }}
        </p>
        <div class="invite-copy" [ngClass]="{ active: copied == true }">
          <h5 title="{{ meetingUrl }}">{{ meetingUrl }}</h5>
          <h5 class="text-copied">{{ "invite-box.copied" | translate }}</h5>
          <figure (click)="copyMeetingUrl(meetingToken)">
            <img
              class="copy"
              src="../../../assets/images/icons/copy-icon.svg"
              [alt]="'meeting-side.meeting-link' | translate"
            />
            <img
              class="hover"
              src="../../../assets/images/icons/copy-hover-icon.svg"
              [alt]="'meeting-side.meeting-link' | translate"
            />
            <img
              class="copied"
              src="../../../assets/images/icons/copied-icon.svg"
              [alt]="'meeting-side.meeting-link' | translate"
            />
          </figure>
        </div>
        <div class="invite-warning">
          <figure>
            <img src="../../../assets/images/lock.svg" alt="" />
          </figure>
          <h6>
            {{ "invite-box.warning" | translate }}
          </h6>
        </div>
        <h5 class="username">
          {{ "meeting-room.join-as" | translate }} {{ name }}
        </h5>
      </div>
    </div>
    <!-- End invitation box -->

    <!-- Start invitation box -->
    <div
      class="record-box"
      *ngIf="showRecordBox == true"
      [ngClass]="{ dirLtr: _lang.direction == 'ltr' }"
      (click)="stopRecording()"
    >
      <img
        src="../../../assets/images/icons/stop_record.svg"
        [alt]="'meeting-room.stop-recording' | translate"
      />
      <h3>
        {{ "meeting-room.stop-recording" | translate }}
      </h3>
      <span class="time">
        <!-- {{ dataService.timeObj?.timer }} -->
      </span>
    </div>
    <!-- End invitation box -->
  </ng-container>
</div>
<!-- End meeting view -->
