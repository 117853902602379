import { Component, OnInit } from '@angular/core';
import { LangService } from 'src/app/shared/services/lang.service';

@Component({
  selector: 'app-root',
  template: ` <app-layout></app-layout> `,
  styles: [],
})
export class AppComponent implements OnInit {
  // NOTE: don't remove LangService dencency injection, we initate langueage in its constructor
  constructor(private _lang: LangService) {}

  ngOnInit() {}
}
