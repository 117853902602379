import { NgModule } from '@angular/core';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatListModule } from '@angular/material/list';
import { MatCardModule } from '@angular/material/card';
import { MatBadgeModule } from '@angular/material/badge';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { MatDividerModule } from '@angular/material/divider';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateModule } from '@ngx-translate/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SharedModule } from 'src/app/shared/shared.module';

// service
import { DataService } from 'src/app/shared/services/data.service';
import { SidenavService } from 'src/app/ui/layout/sidenav.service';

// components
import { LayoutComponent } from 'src/app/ui/layout/layout.component';
import { HeaderComponent } from 'src/app/ui/layout/header/header.component';
import { MainComponent } from 'src/app/ui/layout/main/main.component';
import { MenuComponent } from 'src/app/ui/layout/menu/menu.component';
import { NavbarComponent } from 'src/app/ui/layout/navbar/navbar.component';
import { FooterComponent } from 'src/app/ui/layout/footer/footer.component';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    MatToolbarModule,
    MatIconModule,
    MatMenuModule,
    MatButtonModule,
    MatDialogModule,
    MatListModule,
    MatCardModule,
    MatBadgeModule,
    MatSidenavModule,
    MatExpansionModule,
    MatDividerModule,
    SharedModule,
    RouterModule,
    TranslateModule,
    FlexLayoutModule,
    MatSlideToggleModule,
  ],
  declarations: [
    LayoutComponent,
    HeaderComponent,
    MainComponent,
    MenuComponent,
    NavbarComponent,
    FooterComponent,
  ],
  exports: [LayoutComponent],
  providers: [DataService, SidenavService],
})
export class UiModule {}
