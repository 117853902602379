<div
  class="message-container"
  *ngIf="data.status == 'kick' || data.status == 'ban'"
>
  <div class="header">
    <h2 class="subtitle1">
      {{ "meeting-room." + header | translate }}
    </h2>
    <a class="close-icon" (click)="onNoClick()">
      <mat-icon>close</mat-icon>
    </a>
  </div>

  <p class="subtitle2">{{ "meeting-room." + msg | translate }}</p>

  <div class="btn-action">
    <button mat-flat-button color="primary" (click)="onNoClick()">
      {{ "meeting-room.got-it" | translate }}
    </button>

    <!-- <button class="main-btn subtitle2" mat-button (click)="onNoClick()">
            {{ "meeting-room.report" | translate }}
        </button> -->
  </div>
</div>

<div class="message-container" *ngIf="data.status == 'rejected'">
  <div class="header">
    <h2 class="subtitle1">{{ "meeting-room.rejected" | translate }}</h2>
    <a class="close-icon" (click)="onNoClick()">
      <mat-icon>close</mat-icon>
    </a>
  </div>

  <p class="subtitle2">{{ "meeting-room.someone-rejected" | translate }}</p>

  <div class="btn-action">
    <button mat-flat-button color="primary" (click)="onNoClick()">
      {{ "meeting-room.got-it" | translate }}
    </button>

    <!-- <button class="main-btn subtitle2" mat-button (click)="onNoClick()">
            {{ "meeting-room.report" | translate }}
        </button> -->
  </div>
</div>

<div class="message-container" *ngIf="data.status == 'ended'">
  <div class="header">
    <h2 class="subtitle1">
      {{ "meeting-room.meeting-ended" | translate }}
    </h2>
    <a class="close-icon" (click)="onNoClick()">
      <mat-icon>close</mat-icon>
    </a>
  </div>

  <p class="subtitle2">
    {{ "meeting-room.ended-for-all" | translate }}
  </p>

  <div class="btn-action">
    <button
      class="got-btn"
      mat-flat-button
      color="primary"
      (click)="onNoClick()"
    >
      {{ "meeting-room.got-it" | translate }}
    </button>
  </div>
</div>

<div class="message-container" *ngIf="data.status == 'connectionError'">
  <div class="header">
    <h2 class="subtitle1">
      {{ "meeting-room.connection-error" | translate }}
    </h2>
    <a class="close-icon" (click)="onNoClick()">
      <mat-icon>close</mat-icon>
    </a>
  </div>

  <!-- <img src="assets/images/connectivity-error.svg" alt="connection error" /> -->

  <p class="subtitle2">
    {{ "meeting-room.sorry-there-is-no-network-connection" | translate }}
    <br />
    {{ "meeting-room.try-again" | translate }}
  </p>

  <p class="subtitle2"></p>

  <div class="btn-action">
    <button mat-flat-button color="primary" (click)="onNoClick()">
      {{ "meeting-room.got-it" | translate }}
    </button>
  </div>
</div>
