<div class="home__wrapper">
  <!-- start guest section -->
  <section *ngIf="!kortobaaPkgUserService.currentUser" class="home guest-mode">
    <div class="guest-container">
      <div class="home__intro">
        <a class="brand">
          <figure routerLink="/">
            <img src="assets/images/logo.svg" />
          </figure>
        </a>
        <div class="home__intro-img">
          <img src="assets/images/logo_text.png" alt="Halameet" />
        </div>

        <h1 class="headline1 home__intro-title">
          {{ "intro" | translate }}
        </h1>

        <button
          class="main-btn button18 home__intro-actions"
          *ngIf="!kortobaaPkgUserService.currentUser"
          mat-flat-button
          color="primary"
          [routerLink]="'/login'"
        >
          {{ "join-us-now" | translate }}
        </button>

        <p class="home__intro-subtitle">
          {{ "login.dont-have-account" | translate }}
          <a [routerLink]="'/signup'"> {{ "login.sign-up" | translate }}</a>
        </p>

        <div class="join-meeting-wrapper">
          <h4>
            {{ "join-the-meeting" | translate }}
          </h4>

          <app-join-meeting [guest]="true"></app-join-meeting>
        </div>

        <figure class="left-wave">
          <img src="../../../../assets/images/home/left_wave.png" alt="" />
        </figure>
      </div>

      <!-- start feaures section -->
      <div class="features">
        <figure class="left-wave">
          <img src="../../../../assets/images/home/bottom-wave.png" alt="" />
        </figure>
        <h3>
          {{ "integrated-platform-intro" | translate }}
        </h3>
        <p class="headline4 features__headline">
          {{ "integrated-platform" | translate }}
        </p>

        <div class="features-cards-container">
          <div class="feature-card">
            <div class="icon-container">
              <img
                src="../../../../assets/images/home/how_to_reg.png"
                alt="communication tools"
              />
            </div>

            <label class="card-label">{{
              "full-communication-tools" | translate
            }}</label>

            <p>
              {{
                "you-can-communicate-using-video-audio-chat-and-more"
                  | translate
              }}
            </p>
          </div>

          <div class="feature-card">
            <div class="icon-container">
              <img
                src="../../../../assets/images/home/devices.png"
                alt="customizable view"
              />
            </div>

            <label class="card-label">{{
              "customize-your-view" | translate
            }}</label>

            <p>
              {{
                "you-will-be-able-to-arrange-the-metting-screen-to-meet-your-need"
                  | translate
              }}
            </p>
          </div>

          <div class="feature-card">
            <div class="icon-container">
              <img
                src="../../../../assets/images/home/connect_without_contact.png"
                alt="user friendly"
              />
            </div>

            <label class="card-label">{{ "user-friendly" | translate }}</label>

            <p>
              {{
                "easy-to-use-light-and-dark-theme-full-control-on-your-tools"
                  | translate
              }}
            </p>
          </div>
        </div>

        <div class="download-btns">
          <p class="headline3">{{ "Try Halameet app now" | translate }}</p>
          <button mat-button (click)="openPlayStore()">
            <img
              src="assets/images/play_store.svg"
              alt="download from google play"
            />
          </button>
          <button mat-button (click)="openAppStore()">
            <img
              src="assets/images/app_store.svg"
              alt="download from app store"
            />
          </button>
        </div>
        <figure class="feature-footer">
          <img src="../../../../assets/images/home/footer-waves.png" alt="" />
        </figure>
        <!-- <div class="copyRight">
          {{ "copy-right" | translate }}
          <mat-icon class="material-icons-outlined"> copyright </mat-icon>
        </div> -->
        <!-- <app-footer></app-footer> -->
      </div>
    </div>
  </section>
  <!-- </div> -->
  <!-- end feaures section -->
  <!-- </section> -->
  <!-- end guest section -->

  <!-- start user section -->
  <section class="home" *ngIf="kortobaaPkgUserService.currentUser">
    <a class="brand">
      <figure routerLink="/">
        <img src="assets/images/logo.svg" />
      </figure>
    </a>
    <div
      class="logged-in-container"
      [ngClass]="{ dirLtr: _lang.direction == 'ltr' }"
    >
      <aside class="logged-in-side">
        <!-- <div class="user-img-container">
        <img
          src="{{ kortobaaPkgUserService.currentUser?.photoUrl }}"
          ployfillImg
          class="img"
          [alt]="userData.name"
        />
      </div> -->

        <!-- <p class="headline7">{{ userData.name }}</p>
      <p class="subtitle2">
        {{ userData?.country }}
        <ng-container *ngIf="userData.state && userData.country">
          -
        </ng-container>
        {{ userData?.state }}
      </p>
      <p class="bodytext2">
        {{ userData?.jobTitle | uppercase }}
        <ng-container *ngIf="userData.company && userData.jobTitle">
          -
        </ng-container>
        {{ userData?.company | uppercase }}
      </p> -->
        <!-- [loading]="!personalRooom.meetingToken" -->
        <div class="start-now-section">
          <h2>{{ "account.start-now" | translate }} !</h2>
          <div class="user-actions">
            <button
              mat-button
              class="action action--1"
              (click)="personalMeeting()"
              [disabled]="loading.personal"
            >
              <img
                src="../../../assets/images/home/video-2.png"
                alt="{{ 'meeting-now' | translate }}"
              />
              <span class="headline7">
                {{ "meeting-now" | translate }}
              </span>
              <!--<app-loading-action *ngIf="loading.personal"></app-loading-action>-->
            </button>
            <button
              mat-button
              class="action action--2"
              routerLink="/schedule-new-meeting"
            >
              <img
                src="../../../assets/images/home/calendar_month.png"
                alt="{{ 'meeting-latter' | translate }}"
              />
              <span class="headline7">
                {{ "meeting-later" | translate }}
              </span>
            </button>
          </div>
          <div class="action action--3">
            <app-join-meeting></app-join-meeting>
          </div>
        </div>
      </aside>

      <div class="logged-in-scheduled-meeting">
        <ng-container>
          <app-scheduled-meeting-list></app-scheduled-meeting-list>
        </ng-container>
        <!-- <ng-template #emptyState>
          <app-home-slider></app-home-slider>
        </ng-template> -->
      </div>
    </div>
  </section>
  <!-- end user section -->
</div>
