import { Platform } from '@angular/cdk/platform';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  appTheme = localStorage.getItem('Halameet-theme');
  themeContainer = document.querySelector('html');
  isDarkSubject$ = new BehaviorSubject(true);

  constructor(private platform: Platform) {
    if (this.platform.isBrowser) {
      this.setInitTheme();
      this.checkTheme();
    }
  }

  // check if user select theme before if not default system theme
  setInitTheme() {
    if (this.appTheme) {
      this.themeContainer.dataset.theme = this.appTheme;
      if (localStorage.getItem('theme-selected') == 'default') {
        this.getThemeByBrowser();
      }
    } else {
      this.setTheme();
      localStorage.setItem('theme-selected', 'dark');
      // this.getThemeByBrowser();
    }
  }

  // get theme support
  getThemeByBrowser() {
    if (window.matchMedia && this.platform.isBrowser) {
      localStorage.setItem('theme-selected', 'default');
      if (window.matchMedia('(prefers-color-scheme: light)').matches) {
        this.isDarkSubject$.next(false);
      } else {
        this.isDarkSubject$.next(true);
      }
      this.setTheme();
    }
  }

  // check theme if light return false
  checkTheme() {
    if (this.appTheme == 'Halameet-light') {
      this.isDarkSubject$.next(false);
    }
  }

  // when user select favorite theme
  switchTheme() {
    this.isDarkSubject$.next(!this.isDarkTheme);
    this.setTheme();
  }

  // set theme
  setTheme() {
    if (this.platform.isBrowser) {
      const themeContainer = document.querySelector('html');
      if (this.isDarkTheme) {
        themeContainer.dataset.theme = 'Halameet-dark';
        localStorage.setItem('Halameet-theme', 'Halameet-dark');
      } else {
        themeContainer.dataset.theme = 'Halameet-light';
        localStorage.setItem('Halameet-theme', 'Halameet-light');
      }
    }
  }

  // get theme
  get isDarkTheme() {
    return this.isDarkSubject$.getValue();
  }
}
