<div class="notification-conatiner">
  <div class="header">
    <h2>{{ "setting." + data.title | translate }}</h2>

    <a class="close-icon" (click)="accept(false)">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <p class="subtitle2">{{ "setting." + data.msg | translate }}</p>
  <div class="remove-action">
    <button mat-flat-button color="primary" (click)="accept(true)">
      <span *ngIf="data && !data.hasOwnProperty('confirmMsg')">{{
        "meeting-room.got-it" | translate
      }}</span>
    </button>
  </div>
</div>
