import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { KickActions, KortobaaMeetService } from '@kortobaa-front/ng-kmeet-sdk';

@Component({
  selector: 'app-remove-user-dialog',
  templateUrl: './remove-user-dialog.component.html',
  styleUrls: ['./remove-user-dialog.component.scss'],
})
export class RemoveUserDialogComponent implements OnInit {
  selectedValue = KickActions.kick;

  constructor(
    private _router: Router,
    public kortobaaPkg: KortobaaMeetService,
    public dialogRef: MatDialogRef<RemoveUserDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  ngOnInit(): void {
    console.log(this.data.user);
  }

  select(event: any) {
    this.selectedValue = event.value;
  }

  remove() {
    this.dialogRef.close(this.selectedValue);
  }

  onNoClick(): void {
    this.dialogRef.close();
  }
}
