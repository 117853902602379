<div class="change-pass-container">
  <div class="header">
    <h4>
      {{ "login.change-password" | translate }}
    </h4>
    <a class="close-icon" (click)="this.dialogRef.close()">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <!-- start change password form  -->
  <form #passwordForm="ngForm" (ngSubmit)="updateUserPassword()">
    <!-- old password -->
    <div class="form-input">
      <mat-label
        >{{ "account.current-pass" | translate }}
        <span class="label__required">*</span>
      </mat-label>

      <mat-form-field
        class="bodytext-normal"
        block
        appearance="fill"
        color="primary"
        [ngStyle]="{ border: showErrorMsg ? '1px solid #f44336' : '' }"
      >
        <input
          [type]="isCurrentPasswordHidden ? 'password' : 'text'"
          name="oldPassword"
          #oldPassword="ngModel"
          [(ngModel)]="userEditableData.oldPassword"
          matInput
          required
          (capsLock)="capsOn = $event"
          (keyup)="showErrorMsg = false"
          autocomplete="off"
        />

        <button
          class="toggle-pass bodytext2"
          type="button"
          mat-icon-button
          matSuffix
          (click)="toggleDisplayPassword($event, 'currentPassword')"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="isCurrentPasswordHidden"
        >
          <mat-icon class="material-icons-outlined">{{
            isCurrentPasswordHidden ? "visibility_off" : "visibility"
          }}</mat-icon>
        </button>
      </mat-form-field>
      <!-- <mat-error *ngIf="capsOn" class="caps-on">
        <mat-icon class="material-icons-outlined">info</mat-icon>
        {{ "validation.caps-lock" | translate }}</mat-error
      > -->
      <mat-error *ngIf="showErrorMsg">{{
        "validation.pass-not-correct" | translate
      }}</mat-error>
    </div>
    <!-- new password -->
    <div class="form-input">
      <mat-label
        >{{ "account.new-pass" | translate }}
        <span class="label__required">*</span>
        <div class="tooltip">
          <mat-icon class="material-icons-outlined info"> info</mat-icon>
          <span
            class="tooltiptext"
            [ngStyle]="{
              visibility: wrongPassCriteria ? 'visible' : ''
            }"
          >
            <span class="custom-tooltip">
              {{ "tooltip.password-criteria" | translate }}
            </span>
            <br />
            <mat-icon class="close-icon" *ngIf="!passwordError.length"
              >close</mat-icon
            >
            <mat-icon class="success-icon" *ngIf="passwordError.length"
              >check</mat-icon
            >
            {{ "validation.pass-char" | translate }}
            <br />
            <mat-icon class="close-icon" *ngIf="!passwordError.english"
              >close</mat-icon
            >
            <mat-icon class="success-icon" *ngIf="passwordError.english"
              >check</mat-icon
            >
            {{ "validation.pass-eng" | translate }}
            <br />
            <mat-icon class="close-icon" *ngIf="!passwordError.number"
              >close</mat-icon
            >
            <mat-icon class="success-icon" *ngIf="passwordError.number"
              >check</mat-icon
            >
            {{ "validation.pass-num" | translate }}
            <br />
            <mat-icon class="close-icon" *ngIf="!passwordError.special"
              >close</mat-icon
            >
            <mat-icon class="success-icon" *ngIf="passwordError.special"
              >check</mat-icon
            >
            {{ "validation.pass-special" | translate }}
            <br />
            <mat-icon class="close-icon" *ngIf="!passwordError.upper"
              >close</mat-icon
            >
            <mat-icon class="success-icon" *ngIf="passwordError.upper"
              >check</mat-icon
            >
            {{ "validation.pass-upper" | translate }}
          </span>
        </div>
      </mat-label>

      <mat-form-field
        class="bodytext-normal"
        block
        appearance="fill"
        color="primary"
        [ngClass]="{ 'error-border': showErrorBorder }"
      >
        <input
          [type]="isNewPasswordHidden ? 'password' : 'text'"
          name="newPassword"
          #newPassword="ngModel"
          [(ngModel)]="userEditableData.newPassword"
          minlength="8"
          maxlength="50"
          matInput
          pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[_+#?!@$%^&*-]).{8,}$"
          required
          autocomplete="off"
          (capsLock)="capsOn = $event"
          (blur)="
            !newPassword?.valid && newPassword.touched
              ? (wrongPassCriteria = true)
              : (wrongPassCriteria = false)
          "
          (focus)="wrongPassCriteria = false"
          (input)="
            checkPasswordPattern(userEditableData.newPassword, newPassword)
          "
        />
        <!-- <mat-error>{{ "validation.pass-not-correct" | translate }}</mat-error> -->

        <button
          class="toggle-pass bodytext2"
          type="button"
          mat-icon-button
          matSuffix
          (click)="toggleDisplayPassword($event, 'newPasswordHide')"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="isNewPasswordHidden"
        >
          <mat-icon class="material-icons-outlined">{{
            isNewPasswordHidden ? "visibility_off" : "visibility"
          }}</mat-icon>
        </button>
      </mat-form-field>
      <small
        class="mat-error"
        *ngIf="
          !newPassword?.valid &&
          (newPassword.touched || newPassword.dirty) &&
          newPassword.errors &&
          newPassword.errors['required']
        "
      >
        {{ "validation.pass-not-correct" | translate }}
      </small>
      <!-- <small
        class="mat-error"
        *ngIf="
          !newPassword?.valid &&
          (newPassword.touched || newPassword.dirty) &&
          newPassword.errors &&
          newPassword.errors['pattern']
        "
      >
        {{ "validation.pass-char" | translate }}
        <br />
        {{ "validation.pass-eng" | translate }}
        <br />
        {{ "validation.pass-num" | translate }}
        <br />
        {{ "validation.pass-special" | translate }}
        <br />
        {{ "validation.pass-upper" | translate }}
      </small> -->
      <mat-error *ngIf="capsOn" class="caps-on">
        <mat-icon class="material-icons-outlined">info</mat-icon>
        {{ "validation.caps-lock" | translate }}</mat-error
      >
    </div>
    <!-- confirm new password -->
    <div class="form-input">
      <mat-label
        >{{ "login.new-confirm-pass" | translate }}
        <span class="label__required">*</span>
      </mat-label>
      <mat-form-field
        class="bodytext-normal"
        block
        appearance="fill"
        color="primary"
      >
        <input
          [type]="isConfrimPasswordHidden ? 'password' : 'text'"
          name="passConfirmation"
          #passConfirmation="ngModel"
          [(ngModel)]="userEditableData.passConfirmation"
          [comparePassword]="newPassword"
          minlength="8"
          maxlength="50"
          matInput
          required
          autocomplete="off"
        />
        <button
          class="toggle-pass bodytext2"
          type="button"
          mat-icon-button
          matSuffix
          (click)="toggleDisplayPassword($event, 'confrimPassword')"
          [attr.aria-label]="'Hide password'"
          [attr.aria-pressed]="isConfrimPasswordHidden"
        >
          <mat-icon class="material-icons-outlined">{{
            isConfrimPasswordHidden ? "visibility_off" : "visibility"
          }}</mat-icon>
        </button>
      </mat-form-field>
      <mat-error
        *ngIf="
          !passConfirmation?.valid &&
          (passConfirmation.touched || passConfirmation.dirty) &&
          passConfirmation.errors
        "
        >{{ "validation.pass-not-match" | translate }}</mat-error
      >
    </div>
    <div class="form-action">
      <!-- cancel change password -->
      <button
        type="button"
        mat-flat-button
        color="primary"
        class="cancel"
        (click)="this.dialogRef.close()"
      >
        {{ "account.cancel" | translate }}
      </button>
      <!-- save change password -->
      <button
        type="submit"
        mat-flat-button
        color="primary"
        class="main-btn button18"
        [disabled]="!passwordForm.form.valid || loading"
      >
        <app-loading-action *ngIf="loading"></app-loading-action>

        {{ "account.save" | translate }}
      </button>
    </div>
  </form>
  <!-- end change password form  -->
</div>
