import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Country, TimeZone } from 'src/app/shared/models/interfaces';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root',
})
export class CountriesService {
  constructor(
    private _http: HttpClient,
    private dataService: DataService,
  ) { }

  getCountriesList() {
    const url = this.dataService.host + 'api/countries';
    return this._http.get<Country[]>(url);
  }

  getTimeZones() {
    const url = this.dataService.host + 'api/time-zones';
    return this._http.get<TimeZone[]>(url);
  }
}
