import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { SocketErrors } from '@kortobaa-front/ng-kmeet-sdk';

@Component({
  selector: 'app-notifications-dialog',
  templateUrl: './notifications-dialog.component.html',
  styleUrls: ['./notifications-dialog.component.scss'],
})
export class NotificationsDialogComponent {
  header = '';
  constructor(
    public dialogRef: MatDialogRef<NotificationsDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    if (data.status == SocketErrors.Kicked) {
      this.header = 'kicked-header';
    } else {
      this.header = 'error-occurred';
    }
  }

  accept(boolean: boolean = true) {
    this.dialogRef.close(boolean);
  }
}
