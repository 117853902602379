import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { ComponentCanDeactivate } from './component-can-deactivate';
import { MatDialog } from '@angular/material/dialog';
import { LangService } from '../services/lang.service';
import { CheckNotSaveComponent } from '../components/check-not-save/check-not-save.component';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataService } from '../services/data.service';

@Injectable()
export class CanDeactivateGuard
  implements CanDeactivate<ComponentCanDeactivate>
{
  constructor(
    private _dialog: MatDialog,
    public _lang: LangService,
    private dataService: DataService
  ) {}
  canDeactivate(component: ComponentCanDeactivate): Observable<boolean> {
    if (component.canDeactivate()) {
      return of(true);
    }
    this.dataService.loading = false;
    const dialogRef = this._dialog.open(CheckNotSaveComponent, {
      width: '26rem',
      direction: this._lang.direction,
    });
    return dialogRef.afterClosed().pipe(map((result) => result === false));
  }
}
