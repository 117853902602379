import { Component, ElementRef, OnInit } from '@angular/core';
@Component({
  selector: 'app-home-slider',
  templateUrl: './home-slider.component.html',
  styleUrls: ['./home-slider.component.scss'],
})
export class HomeSliderComponent implements OnInit {
  slideIndex = 1;
  slides!: any;
  dots!: any;
  constructor(private _elemRef: ElementRef) {}

  ngOnInit(): void {
    this.slides = this._elemRef.nativeElement.querySelectorAll('.mySlides');
    this.dots = this._elemRef.nativeElement.querySelectorAll('.dot');
    this.showSlides(this.slideIndex);
  }

  plusSlides(n) {
    this.showSlides((this.slideIndex += n));
  }

  currentSlide(n) {
    this.showSlides((this.slideIndex = n));
  }

  showSlides(n) {
    let i;
    if (n > this.slides.length) {
      this.slideIndex = 1;
    }
    if (n < 1) {
      this.slideIndex = this.slides.length;
    }
    for (i = 0; i < this.slides.length; i++) {
      this.slides[i].style.display = 'none';
    }
    for (i = 0; i < this.dots.length; i++) {
      this.dots[i].className = this.dots[i].className.replace(' active', '');
    }
    this.slides[this.slideIndex - 1].style.display = 'block';
    this.dots[this.slideIndex - 1].className += ' active';
  }
}
