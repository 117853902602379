<div class="confirm-container">
  <div class="header">
    <h4 class="headline7">
      {{ "backDialog-title" | translate }}
    </h4>
    <button (click)="accept(false)">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <h3 class="headline7">
    {{ "backDialog-info" | translate }}
  </h3>

  <div class="action-btns">
    <button mat-flat-button class="button18 ignore" (click)="accept(true)">
      {{ "backDialog-complete" | translate }}
    </button>
    <button mat-flat-button class="button18 yes" (click)="accept(false)">
      {{ "backDialog-back" | translate }}
    </button>
  </div>
</div>
