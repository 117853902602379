import { registerLocaleData } from '@angular/common';
import localeEn from '@angular/common/locales/en-GB';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { TranslateModule } from '@ngx-translate/core';

import { CoreRouting } from 'src/app/core/core.routing';
import { ErrorHandlerModule } from 'src/app/core/errors/error-handler.module';
import { PageNotFoundComponent } from 'src/app/core/page-not-found/page-not-found.component';

registerLocaleData(localeEn);

@NgModule({
  imports: [CoreRouting, TranslateModule, MatButtonModule, ErrorHandlerModule],
  declarations: [PageNotFoundComponent],
})
export class CoreModule {}
