<footer>
  <div class="copyRight">
    <span>
      {{ "copy-right" | translate }}
    </span>
    <figure>
      <img src="../../../../assets/images/copyright.svg" alt="Halameet" />
    </figure>
  </div>
</footer>
