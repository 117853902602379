import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule } from '@angular/material/button';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { KAuthProvider } from '@kortobaa-front/authentication';
import { ToastrModule } from 'ngx-toastr';
import { AuthModule } from 'src/app/auth/auth.module';
import { CoreModule } from 'src/app/core/core.module';
import { HomeModule } from 'src/app/home/home.module';
import { MeetingModule } from 'src/app/meeting/meeting.module';
import { ProfileModule } from 'src/app/profile/profile.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { UiModule } from 'src/app/ui/ui.module';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import { PackagesConfig } from 'src/app/config';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    RouterModule.forRoot([], { relativeLinkResolution: 'legacy' }),
    BrowserModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader, // exported factory function needed for AoT compilation
        deps: [HttpClient],
      },
    }),
    BrowserAnimationsModule,
    CoreModule,
    AuthModule,
    UiModule,
    SharedModule,
    MeetingModule,
    HomeModule,
    ProfileModule,
    //material
    MatSnackBarModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),
    MatButtonModule,
    FlexLayoutModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    ToastrModule.forRoot({
      maxOpened: 2,
      positionClass: 'toast-top-center',
      autoDismiss: true,
      preventDuplicates: true,
      iconClasses: {
        success: 'custom-toast-success',
        error: 'custom-toast-error',
      },
    }),
  ],
  exports: [TranslateModule],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: KAuthProvider.default,
      useValue: PackagesConfig.kortobaaAuthPkgConfig,
    },
  ],
})
export class AppModule {
  constructor() {
    //
  }
}
